import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/Header/Header';
import { styles } from '../../Styles/Styles';
import userBackImage from '../../assets/images/user.svg'
import { DropdownInput, InputList } from '../../components/PostProperty/PostPropertyComp';
import TopCItiesFilter from '../../components/TopCItiesFilter';
import Footer from '../../components/Footer';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UseApi } from '../../ApiConf';
import { toast } from 'react-toastify';
import { setUserData, setuser } from '../../Redux/reducer/User';
import loader from '../../assets/Icons/loader.gif';

const passwordInputs = [
    { name: 'Old Password', placeholder: 'Enter your old password', state: 'oldpassword', star: false, dropdownData: null },
    { name: 'New Password', placeholder: 'Enter your new password', state: 'newpassword', star: false, dropdownData: null },
    { name: 'Confirm new Password', placeholder: 'Enter your confirm password', state: 'confiempassword', star: false, dropdownData: null },
]

const countries = ['India', 'Singapore', 'UAE', 'United States'];
const indianStates = [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana',
    'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur',
    'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana',
    'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh',
    'Dadra and Nagar Haveli and Daman and Diu', 'Lakshadweep', 'Delhi', 'Puducherry'
];

const Cities = ['Kolkata', 'Pune', 'Delhi']

// const personalInputs = [
//     { name: 'Good Name', placeholder: 'sandip', state: 'name', star: false, dropdownData: null },
//     { name: 'Register As', placeholder: 'Individual', state: 'registerAs', star: false, dropdownData: ['Register As', 'Individual', 'Builder', 'Agent'] },
//     { name: 'Business Title', placeholder: 'Enter your business title', state: 'business title', star: false, dropdownData: null },
//     { name: 'Country', placeholder: 'Your Country', state: 'business title', star: false, dropdownData: countries },
//     { name: 'State', placeholder: 'Your State', state: 'state', star: false, dropdownData: indianStates },
//     { name: 'City', placeholder: 'Your City', state: 'city', star: false, dropdownData: Cities },

// ]




const EditProfile = () => {

    const [profileImage, setProfileImage] = useState(null);
    // const [value, setValue] = useState('')
    const { login_status, pageRefresh, userData, filterMenus } = useSelector(state => state.User);
    const [profileImgSrc, setProfileImgSrc] = useState(null);
    const [profileData, setProfileData] = useState({
        oldPasword: '', newPassword: '', confirmPassword: '', email: '', mobile: '',
        countryCode: '', name: '', userAs: '', BusinessTitle: '', country: '', state: '', city: '',
        locality: '', interestedProperty: '', minBudget: '', maxBudget: '', InterestedIn: '', facingPref: '',
        wantToBuy: '', floorPref: ''
    });
    const [inputFields, setInputFields] = useState({ countries: [], propertyType: [] });
    const [addressData, setAddressData] = useState({ countries: [], states: [], cities: [], localities: [{ label: 'Other', value: 'Other' }], subLocalities: [], societies: [] });
    const [otpStatus, setOtpStatus] = useState({ generateBtn: false, verifyOtp: false, otp: '' });
    const [errorStatus, setErrorStatus] = useState({});
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [contactNumArr, setContactArr] = useState(['+91', '']);
    // const navigate = useNavigate();
    const { Request } = UseApi();

    // useEffect(() => {
    //     if (!login_status && !pageRefresh) {
    //         navigate('/');
    //     }
    // }, [login_status]);

    useEffect(() => {
        console.log('userdata...', userData);
        setProfileImgSrc(userData?.ProfilePhoto_url);
        let contactArr = userData?.ContactNumber ? userData.ContactNumber.split('-') : ['+91', ''];
        setContactArr(contactArr);
        setProfileData(pre => ({
            ...pre, email: userData?.Email, mobile: contactArr[1],
            countryCode: contactArr[0], name: userData?.Name, userAs: userData?.UserAs, BusinessTitle: userData?.BusinessTitle ? userData?.BusinessTitle : '',
            country: userData?.Country, state: userData?.State, city: userData?.City,
            locality: userData?.Locality, interestedProperty: userData?.InterestedProperty, minBudget: userData?.MinBudget, maxBudget: userData?.MaxBudget, InterestedIn: userData?.InterestedIn, facingPref: userData?.FacingPreference,
            wantToBuy: userData?.WantToBuy, floorPref: userData?.FloorPreference
        }))
    }, [userData]);


    useEffect(() => {
        getInputOptions('1');
    }, [userData])

    useEffect(() => {
        console.log('profileData.country...', profileData.country);
        getInputOptions('2', 'state_list', profileData.country, 'oldLocation');
    }, [profileData.country]);

    useEffect(() => {
        getInputOptions('2', 'city_list', profileData.state, 'oldLocation');
    }, [profileData.state]);

    useEffect(() => {
        getInputOptions('2', 'locality_list', profileData.city, 'oldLocation');
        // if (profileData.city != 'Other') {
        //     getInputOptions('2', 'project_society', profileData.city, 'oldLocation');
        // }
    }, [profileData.city]);

    // useEffect(() => {
    //     getInputOptions('2', 'sublocality_list', profileData.locality, 'oldLocation');
    // }, [profileData.locality]);

    const getInputOptions = async (type, get_list, currnet_val, locType) => {
        setLoading(true);
        if (!currnet_val && type == '2') return;
        // if (checkInputValidation()) return;
        let params = {
            type: type,
            token: userData?.jwt_tocken,
            get_list: get_list ? get_list : '',
            // currnet_val: (currnet_val && currnet_val !== "0" && currnet_val != 'Other') ? currnet_val : ''
            currnet_val: currnet_val
        }
        let data;
        try {
            data = await Request('form-related', 'POST', params);
        } catch (err) {
            console.log(err);
        }
        console.log('data....', data);
        if (data?.statusCode == 200) {
            if (data.success) {
                if (type == '1') {
                    if (data.data?.countries?.length > 0) {
                        // setAddressData(pre => ({ ...pre, countries: data.data?.countries }));
                        setInputFields(pre => ({ ...pre, countries: data.data?.countries, propertyType: data.data?.propertyType }));
                    }
                }
                if (type == '2') {
                    if (get_list == 'state_list') {
                        setAddressData(pre => ({ ...pre, states: data.data }));
                    }
                    if (get_list == 'city_list') {
                        setAddressData(pre => ({ ...pre, cities: data.data }));
                    }
                    if (get_list == 'locality_list') {
                        // console.log('locality data...', data.data);
                        setAddressData(pre => ({ ...pre, localities: data.data }));
                    }
                    // if (get_list == 'sublocality_list') {
                    //     setAddressData(pre => ({ ...pre, subLocalities: data.data }));
                    // }
                    // if (get_list == 'project_society') {
                    //     setAddressData(pre => ({ ...pre, societies: data.data }));
                    // }
                }
                if (data.message) {
                    toast(data.message, { type: 'success' });
                }
                // setLoading(false);
            } else {
                // toast(data.message, { type: 'error' });
            }
        }
        setLoading(false);
    }

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            let file = e.target.files[0];
            setProfileImage(file);
            const reader = new FileReader();
            reader.onload = () => {
                setProfileImgSrc(reader.result);
                uploadPhoto(file);
            }
            reader.readAsDataURL(file);
        }
    }

    const uploadPhoto = async (file) => {
        let token = localStorage.getItem('token');
        let inputdata = {
            type: '1',
            token: token,
            image: file
        }
        console.log('file...', file);
        let data;
        try {
            data = await Request('update-profile', 'POST', inputdata);
        } catch (err) {
            console.log('error...', err);
        }
        if (data?.success) {
            toast(data.message, { type: 'success' });
            console.log('data?.success...', data?.success);
        }
    }

    const updatePassword = async () => {
        if (checkPasswordValidation()) return;
        const token = localStorage.getItem('token');
        let inputdata = {
            type: '2',
            token: token,
            old_password: profileData.oldPasword,
            new_password: profileData.newPassword,
            confirm_password: profileData.confirmPassword
        }
        let data;
        try {
            data = await Request('update-profile', 'POST', inputdata);
        } catch (err) {
            console.log(err);
            // if (err?.message) {
            //     setInputErrorStatus(pre => ({ ...pre, Otp: err.message }));
            // }
        }
        if (data?.statusCode == 200) {
            if (data.success) {
                toast(data.message, { type: 'success' });
            } else {
                toast(data.message, { type: 'error' });
            }
        }
        // setInputErrorStatus(pre => ({ ...pre, Otp: '' }));
    }

    const checkPasswordValidation = () => {
        let error = false;
        if (!profileData.oldPasword) {
            setErrorStatus(pre => ({ ...pre, oldPasword: 'Old password is required !' }));
            error = true;
        }
        if (!profileData.newPassword) {
            setErrorStatus(pre => ({ ...pre, newPassword: 'New password is required !' }));
            error = true;
        }
        if(profileData.newPassword.length < 6){
            setErrorStatus(pre => ({ ...pre, newPassword: 'The Password must be at least 6 characters in length.' }));
            error = true;
        }
        if (!profileData.confirmPassword) {
            setErrorStatus(pre => ({ ...pre, confirmPassword: 'Confirm password is required !' }));
            error = true;
        }
        if (profileData.newPassword != profileData.confirmPassword) {
            setErrorStatus(pre => ({ ...pre, confirmPassword: 'Confirm password does not match to new password!' }));
            error = true;
        }
        return error;
    }

    const updateMobile = async () => {
        // if (checkPasswordValidation()) return;
        let is_otp_verification = '';
        if (otpStatus.verifyOtp) is_otp_verification = '1';
        const token = localStorage.getItem('token');
        let inputdata = {
            type: '3',
            token: token,
            is_otp_verification: is_otp_verification,
            country_code: profileData.countryCode,
            mobile: profileData.mobile,
            otp: otpStatus.otp
        }
        let data;
        try {
            data = await Request('update-profile', 'POST', inputdata);
        } catch (err) {
            console.log(err);
            // if (err?.message) {
            //     setInputErrorStatus(pre => ({ ...pre, Otp: err.message }));
            // }
        }
        console.log('data generate otp data...', data);
        if (data?.statusCode == 200) {
            if (data.success) {
                toast(data.message, { type: 'success' });
                if (is_otp_verification) {
                    setOtpStatus(pre => ({ ...pre, generateBtn: false, verifyOtp: false, otp: '' }));
                    getAutoLoginData();
                } else {
                    setOtpStatus(pre => ({ ...pre, verifyOtp: true }));
                }
            } else {
                toast(data.message, { type: 'error' });
            }
        }
        // setInputErrorStatus(pre => ({ ...pre, Otp: '' }));
    }
    const getAutoLoginData = async () => {
        const token = localStorage.getItem('token');
        let inputdata = {
            token: token
        }
        let data;
        try {
            data = await Request('autologin', 'POST', inputdata);
        } catch (err) {
            console.log('error...', err);
        }
        console.log('auto login data...', data, 'token...', token);
        if (data?.success) {
            localStorage.setItem('token', data?.token);
            if (data.data) {
                dispatch(setuser(data.data));
                localStorage.setItem('userId', data?.data?.ID);
            } else {
                dispatch(setuser({}));
            }
        } else {
            console.log('some think worng!');
        }
    }

    const updateProfile = async () => {
        if (checkProfileUpdateValidation()) return;
        setLoading(true);
        const token = localStorage.getItem('token');
        let inputdata = {
            type: '4',
            token: token,
            profile_name: profileData?.name,
            profile_business_title: profileData?.BusinessTitle,
            profile_business_address: '',
            country_id: profileData.country,
            state_id: profileData.state,
            city_id: profileData.city,
            profile_useras: profileData.userAs,
            locality_id: profileData.locality,
            interested_property: profileData.interestedProperty,
            min_budget: profileData.minBudget,
            max_budget: profileData.maxBudget,
            interested_in: profileData.maxBudget,
            facing_preference: profileData.facingPref,
            want_to_buy: profileData.wantToBuy,
            floor_preference: profileData.floorPref
        }
        let data;
        try {
            data = await Request('update-profile', 'POST', inputdata);
        } catch (err) {
            console.log(err);
            // if (err?.message) {
            //     setInputErrorStatus(pre => ({ ...pre, Otp: err.message }));
            // }
        }
        console.log('input data...', inputdata);
        console.log('update profile data....', data);
        if (data?.statusCode == 200) {
            if (data.success) {
                toast(data.message, { type: 'success' });
            } else {
                toast(data.message, { type: 'error' });
            }
        }
        setLoading(false);
        // setInputErrorStatus(pre => ({ ...pre, Otp: '' }));
    }

    const checkProfileUpdateValidation = () => {
        let error = false;
        if (!profileData.name) {
            setErrorStatus(pre => ({ ...pre, name: 'Name is required !' }));
            error = true;
        }
        if (!profileData.userAs && userData?.UserAs != 'Buyer') {
            setErrorStatus(pre => ({ ...pre, userAs: 'UserAs is required !' }));
            error = true;
        }
        if (!profileData.BusinessTitle && userData?.UserAs != 'Buyer') {
            setErrorStatus(pre => ({ ...pre, BusinessTitle: 'Business Title is required !' }));
            error = true;
        }
        if (!profileData.country || profileData.country == '0') {
            setErrorStatus(pre => ({ ...pre, country: 'Country is required !' }));
            error = true;
        }
        if (!profileData.state || profileData.state == '0') {
            setErrorStatus(pre => ({ ...pre, state: 'State is required !' }));
            error = true;
        }
        if (!profileData.city || profileData.city == '0') {
            setErrorStatus(pre => ({ ...pre, city: 'City is required !' }));
            error = true;
        }
        if (userData?.UserAs == 'Buyer') {
            if (!profileData.locality) {
                setErrorStatus(pre => ({ ...pre, locality: 'Locality is required !' }));
                error = true;
            }
            if (!profileData.interestedProperty) {
                setErrorStatus(pre => ({ ...pre, interestedProperty: 'Interested Property is required !' }));
                error = true;
            }
            if (!profileData.minBudget) {
                setErrorStatus(pre => ({ ...pre, minBudget: 'Min Budget is required !' }));
                error = true;
            }
            if (!profileData.maxBudget || profileData.maxBudget == '0') {
                setErrorStatus(pre => ({ ...pre, maxBudget: 'Max Budget is required !' }));
                error = true;
            }
        }
        return error;
    }


    return (
        <div>
            <Header />
            {/* <div className='my-16 container mx-auto px-2'>
                <p className={styles.title3}>Eidt Profile:</p>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
                    <div className='p-2 border-gray-300 mx-auto my-auto'>
                        <img alt='' src={profileImgSrc ? profileImgSrc : userBackImage} className='h-[150px] w-[150px] md:h-[220px] md:w-[220px] rounded-full' />
                        <div className='group text-gray-400 flex flex-col items-center justify-center py-2 text-cente mt-2'>
                            <input accept='image/*' onChange={handleFileChange} type='file' className='w-[300px] absolute opacity-0 py-8 cursor-pointer' />
                            <button className='border-[1px] border-gray-500 px-2 py-1 rounded-xl text-gray-700 group-hover:bg-gray-800 group-hover:text-white duration-500'>Upload Photo</button>
                        </div>
                    </div>
                    <div>
                        <p className={styles.title4}>Change Password:</p>
                        <div>
                            <InputList inputs={passwordInputs} classname={'mt-5 grid grid-cols-1 gap-3'} />
                            <button className={styles.btnBlackHover + styles.formBtn + ' mt-5'}>Change Password</button>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-8 mt-6'>
                    <div className='mt-5'>
                        <InputList inputs={personalInputs} classname={'mt-5 grid grid-cols-1 gap-6'} />
                        <button className={styles.btnBlackHover + styles.formBtn + ' mt-5'}>Update Profile</button>
                    </div>
                    <div>
                        <div className='mt-9'>
                            <p className={styles.textMedium}>Email Id</p>
                            <input readOnly placeholder='fakeemail@gmail.com' className={styles.input + ' mt-2 bg-gray-50'} />
                        </div>
                       
                        <div className='flex gap-2 mt-5'>
                            <div className='w-[20%]'>
                                <PhoneInput
                                    country={'in'}
                                    containerStyle={{ width: '100%' }}
                                    inputStyle={{ width: '100%', height: '42px', borderRadius: '0px' }}
                                    // buttonStyle={{width:'50%'}}
                                    // containerClass='w-full'
                                    // inputClass='py-2'
                                    placeholder='Phone*'
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: true
                                    }}
                                // buttonClass='w-[100px]'
                                // value={signupInputDatas.countryCode}
                                // onClick={() => {
                                //     if (signupInputDatas.countryCode == '') {
                                //         setSignUpInputDatas(pre => ({ ...pre, countryCode: '+91' }))
                                //     }
                                // }}
                                // onChange={(val) => setSignUpInputDatas(pre => ({ ...pre, countryCode: val }))}
                                />
                            </div>
                            <input type='tel' className={styles.input + 'rounded py-2 w-[80%]'} placeholder='1234567890'
                            // value={signupInputDatas.mobileNum}
                            // onChange={(e) => {
                            //     if (/^[0-9]*$/.test(e.target.value)) {
                            //         setSignUpInputDatas(pre => ({ ...pre, mobileNum: e.target.value }));
                            //         if (/^\d{7,15}$/.test(e.target.value) && signUpErrorStatus.mobileNum) {
                            //             setSignUpErrorStatus(pre => ({ ...pre, mobileNum: '' }));
                            //         }
                            //     }
                            // }}
                            />
                        </div>
                        <button className={styles.btnBlackHover + styles.formBtn + ' mt-5'}>Update Mobile</button>
                        <div className='mt-5'>
                            <p className='text-gray-500 text-sm sm:text-base'>Changing mobile number needs OTP verification.
                                After updating number, generate an OTP and verify. Once you verify OTP,
                                your mobile number will be updated to our record.</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='my-16 container mx-auto px-2'>
                <p className={styles.title3 + 'pt-8'}>Eidt Profile:</p>
                {loading && <div className="fixed top-[100px] right-1/2 flex justify-center items-center mt-16">
                    <img alt="Please wait.." title="Please wait.." src={loader} />
                </div>}
                <div className='mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10'>
                    <div className='p-2 h-full w-full flex flex-col items-center justify-center mx-auto my-auto border-[1px] border-gray-300 rounded-md'>
                        <img alt='' src={profileImgSrc ? profileImgSrc : userData?.ProfilePhoto_url ? userData?.ProfilePhoto_url : userBackImage} className='h-[150px] w-[150px] md:h-[220px] md:w-[220px] rounded-full' />
                        <div className='group text-gray-400 flex flex-col items-center justify-center py-2 text-cente mt-2'>
                            <input accept='image/*' onClick={(e) => {
                                e.target.value = ''
                            }}
                                onChange={handleFileChange} type='file' className='w-[300px] absolute opacity-0 py-8 cursor-pointer' />
                            <button className='border-[1px] border-gray-500 px-2 py-1 rounded-xl text-gray-700 group-hover:bg-gray-800 group-hover:text-white duration-500'>Upload Photo</button>
                        </div>
                    </div>
                    {/* const passwordInputs = [
                    {name: 'Old Password', placeholder: 'Enter your old password', state: 'oldpassword', star: false, dropdownData: null },
                    {name: 'New Password', placeholder: 'Enter your new password', state: 'newpassword', star: false, dropdownData: null },
                    {name: 'Confirm new Password', placeholder: 'Enter your confirm password', state: 'confiempassword', star: false, dropdownData: null },
                    ] */}

                    <div className='border-[1px] border-gray-300 rounded-md p-4 '>
                        <p className={styles.title4}>Change Password:</p>
                        <div>
                            {/* <InputList inputs={passwordInputs} classname={'mt-5 grid grid-cols-1 gap-3'} />
                            <button className={styles.btnBlackHover + styles.formBtn + ' mt-5'}>Change Password</button> */}
                            <div className='mt-5 grid grid-cols-1 gap-3'>
                                <DropdownInput title={'Old Password'} placeholder={'Enter your old password'}
                                    value={profileData.oldPasword}
                                    error={errorStatus.oldPasword}
                                    onChange={(e) => {
                                        setProfileData(pre => ({ ...pre, oldPasword: e.target.value }));
                                        if (errorStatus.oldPasword) {
                                            setErrorStatus(pre => ({ ...pre, oldPasword: '' }));
                                        }
                                    }}
                                />
                                <DropdownInput title={'New Password'} placeholder={'Enter your new password'}
                                    error={errorStatus.newPassword}
                                    value={profileData.newPassword}
                                    onChange={(e) => {
                                        setProfileData(pre => ({ ...pre, newPassword: e.target.value }));
                                        if (errorStatus.newPassword) {
                                            setErrorStatus(pre => ({ ...pre, newPassword: '' }));
                                        }
                                    }}
                                />
                                <DropdownInput title={'Confirm Password'} placeholder={'Enter your confirm password'}
                                    error={errorStatus.confirmPassword}
                                    value={profileData.confirmPassword}
                                    onChange={(e) => {
                                        setProfileData(pre => ({ ...pre, confirmPassword: e.target.value }));
                                        if (errorStatus.confirmPassword) {
                                            setErrorStatus(pre => ({ ...pre, confirmPassword: '' }));
                                        }
                                    }}
                                />
                            </div>
                            <button className={styles.btnBlackHover + styles.formBtn + ' mt-5'} onClick={updatePassword}>Change Password</button>
                        </div>
                    </div>
                    <div className='border-[1px] border-gray-300 rounded-md p-4'>
                        <p className={styles.title4}>Update Mobile:</p>
                        <div className='mt-4'>
                            <p className={styles.textMedium}>Email Id</p>
                            <input readOnly placeholder={profileData.email} className={styles.input + ' mt-2 bg-gray-50'} />
                        </div>
                        {!otpStatus.verifyOtp ?
                            <div className='mt-3'>
                                <p className={styles.textMedium}>Mobile</p>
                                <div className='flex gap-2 mt-1'>
                                    <div className='w-[30%]'>
                                        <PhoneInput
                                            country={'in'}
                                            containerStyle={{ width: '100%' }}
                                            inputStyle={{ width: '100%', height: '42px', borderRadius: '0px' }}
                                            // buttonStyle={{width:'50%'}}
                                            // containerClass='w-full'
                                            // inputClass='py-2'
                                            placeholder='Phone*'
                                            inputProps={{
                                                name: 'phone',
                                                required: true,
                                                autoFocus: true
                                            }}
                                            // buttonClass='w-[100px]'
                                            value={profileData.countryCode}
                                            // onClick={() => {
                                            //     if (signupInputDatas.countryCode == '') {
                                            //         setSignUpInputDatas(pre => ({ ...pre, countryCode: '+91' }))
                                            //     }
                                            // }}
                                            onChange={(val) => setProfileData(pre => ({ ...pre, countryCode: val }))}
                                        />
                                    </div>
                                    <input type='tel' className={styles.input + 'rounded py-2 w-[70%]'} placeholder={profileData.mobile}
                                        value={profileData.mobile}
                                        onChange={(e) => {
                                            if (/^[0-9]*$/.test(e.target.value)) {
                                                setProfileData(pre => ({ ...pre, mobile: e.target.value }));
                                                if (/^\d{7,15}$/.test(e.target.value) && errorStatus.mobile) {
                                                    setErrorStatus(pre => ({ ...pre, mobile: '' }));
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            :
                            <div className='mt-3'>
                                <p className={styles.textMedium}>OTP</p>
                                <div className='mt-1'>
                                    <input type='tel' className={styles.input + 'rounded py-2 w-full'} placeholder={'Enter OTP received on your mobile.'}
                                        value={otpStatus.otp}
                                        onChange={(e) => {
                                            setOtpStatus(pre => ({ ...pre, otp: e.target.value }));
                                        }}
                                    />
                                </div>
                            </div>
                        }
                        <div className='flex gap-3 mt-5'>
                            {(otpStatus.generateBtn || otpStatus.verifyOtp) && <button className={styles.btnBlackHover + styles.formBtn} onClick={updateMobile}>{otpStatus.verifyOtp ? 'Verify OTP' : 'Generate OTP'}</button>}
                            <button className={styles.btnBlackHover + styles.formBtn}
                                onClick={() => {
                                    if (otpStatus.generateBtn) {
                                        setOtpStatus(pre => ({ ...pre, generateBtn: !pre.generateBtn, verifyOtp: false }));
                                    } else {
                                        if ((contactNumArr[0].replace(/\+/g, '') == profileData.countryCode.replace(/\+/g, '') && (contactNumArr[1] == profileData.mobile))) {
                                            toast('For update mobile, you must need to change your mobile', { type: 'error' });
                                        } else {
                                            setOtpStatus(pre => ({ ...pre, generateBtn: !pre.generateBtn, verifyOtp: false }))
                                        }
                                    }
                                }}>
                                {otpStatus.generateBtn ? 'Edit Mobile' : 'Update Mobile'}</button>
                        </div>
                        <div className='mt-5'>
                            <p className='text-gray-500 text-sm sm:text-base'>Changing mobile number needs OTP verification.
                                After updating number, generate an OTP and verify. Once you verify OTP,
                                your mobile number will be updated to our record.</p>
                        </div>
                    </div>
                </div>
                <div className='mt-16'>
                    {/* <InputList inputs={personalInputs} classname={'mt-5 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6'} /> */}
                    {/* {const personalInputs = [
                    {name: 'Business Title', placeholder: 'Enter your business title', state: 'business title', star: false, dropdownData: null },
                    {name: 'State', placeholder: 'Your State', state: 'state', star: false, dropdownData: indianStates },
                    {name: 'City', placeholder: 'Your City', state: 'city', star: false, dropdownData: Cities },
                     ]} */}
                    <div className='mt-5 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6'>
                        <DropdownInput title={'Good Name'} placeholder={'sandip'}
                            value={profileData.name}
                            onChange={(e) => {
                                setProfileData(pre => ({ ...pre, name: e.target.value }));
                                if (errorStatus.name) {
                                    setErrorStatus(pre => ({ ...pre, name: '' }));
                                }
                            }}
                            error={errorStatus.name}
                        />
                        {userData?.UserAs != 'Buyer' && <DropdownInput title={'Register As'} placeholder={profileData.userAs} options={['Register As', 'Individual', 'Builder', 'Agent']}
                            error={errorStatus.userAs}
                            value={profileData.userAs}
                            onChange={(e) => {
                                setProfileData(pre => ({ ...pre, userAs: e.target.value }));
                                if (errorStatus.userAs) {
                                    setErrorStatus(pre => ({ ...pre, userAs: '' }));
                                }
                            }}
                        />}
                        {userData?.UserAs != 'Buyer' && <DropdownInput title={'Business Title'} placeholder={'Enter your business title'}
                            error={errorStatus.BusinessTitle}
                            value={profileData.BusinessTitle}
                            onChange={(e) => {
                                setProfileData(pre => ({ ...pre, BusinessTitle: e.target.value }));
                                if (errorStatus.BusinessTitle) {
                                    setErrorStatus(pre => ({ ...pre, BusinessTitle: '' }));
                                }
                            }}
                        />}
                        <DropdownInput title={'Country'} placeholder={'Your Country'} options={inputFields?.countries}
                            error={errorStatus.country}
                            value={profileData.country}
                            onChange={(e) => {
                                setProfileData(pre => ({ ...pre, country: e.target.value }));
                                if (errorStatus.country) {
                                    setErrorStatus(pre => ({ ...pre, country: '' }));
                                }
                            }}
                        />
                        <DropdownInput title={'State'} placeholder={'Your State'} options={addressData?.states}
                            error={errorStatus.state}
                            value={profileData.state}
                            onChange={(e) => {
                                setProfileData(pre => ({ ...pre, state: e.target.value }));
                                if (errorStatus.state) {
                                    setErrorStatus(pre => ({ ...pre, state: '' }));
                                }
                            }}
                        />
                        <DropdownInput title={'City'} placeholder={'Your City'} options={addressData?.cities}
                            error={errorStatus.city}
                            value={profileData.city}
                            onChange={(e) => {
                                setProfileData(pre => ({ ...pre, city: e.target.value }));
                                if (errorStatus.city) {
                                    setErrorStatus(pre => ({ ...pre, city: '' }));
                                }
                            }}
                        />
                        {userData?.UserAs == 'Buyer' && <>
                            <DropdownInput title={'Interested Locality'} options={addressData?.localities} placeholder={'Your Locality'} required={true}
                                error={errorStatus.locality}
                                value={profileData.locality}
                                onChange={(e) => {
                                    setProfileData(pre => ({ ...pre, locality: e.target.value }));
                                    if (errorStatus.locality) {
                                        setErrorStatus(pre => ({ ...pre, locality: '' }));
                                    }
                                }}
                            />
                            <DropdownInput title={'Interested Property'} options={inputFields?.propertyType} placeholder={'Interested Property'} required={true}
                                error={errorStatus.interestedProperty}
                                value={profileData.interestedProperty}
                                onChange={(e) => {
                                    setProfileData(pre => ({ ...pre, interestedProperty: e.target.value }));
                                    if (errorStatus.interestedProperty) {
                                        setErrorStatus(pre => ({ ...pre, interestedProperty: '' }));
                                    }
                                }}
                            />
                            <DropdownInput title={'Min Budget'} options={filterMenus?.saleBudget} placeholder={'Min Budget'} required={true}
                                error={errorStatus.minBudget}
                                value={profileData.minBudget}
                                onChange={(e) => {
                                    setProfileData(pre => ({ ...pre, minBudget: e.target.value }));
                                    if (errorStatus.minBudget) {
                                        setErrorStatus(pre => ({ ...pre, minBudget: '' }));
                                    }
                                }}
                            />
                            <DropdownInput title={'Max Budget'} options={filterMenus?.saleBudget} placeholder={'Max Budget'} required={true}
                                error={errorStatus.maxBudget}
                                value={profileData.maxBudget}
                                onChange={(e) => {
                                    setProfileData(pre => ({ ...pre, maxBudget: e.target.value }));
                                    if (errorStatus.maxBudget) {
                                        setErrorStatus(pre => ({ ...pre, maxBudget: '' }));
                                    }
                                }}
                            />
                            <DropdownInput title={'Interested In'} options={['1 BHK', '2 BHK', '3 BHK', '4 BHK+']}
                                placeholder={'Interested In'}
                                value={profileData.InterestedIn}
                                onChange={(e) => {
                                    setProfileData(pre => ({ ...pre, InterestedIn: e.target.value }));
                                    if (errorStatus.InterestedIn) {
                                        setErrorStatus(pre => ({ ...pre, InterestedIn: '' }));
                                    }
                                }}
                            />

                            <DropdownInput title={'Facing Preference'} options={['North', 'East', 'South', 'West', 'North East', 'North West', 'South West', 'South East']}
                                placeholder={'Facing Preference'}
                                value={profileData.facingPref}
                                onChange={(e) => {
                                    setProfileData(pre => ({ ...pre, facingPref: e.target.value }));
                                    if (errorStatus.facingPref) {
                                        setErrorStatus(pre => ({ ...pre, facingPref: '' }));
                                    }
                                }}
                            />
                            <DropdownInput title={'Want to Buy'} options={['Immediate', '1-3 Months', '3-6 Months', '6-12 Months']} placeholder={'Want to Buy'}
                                value={profileData.wantToBuy}
                                onChange={(e) => {
                                    setProfileData(pre => ({ ...pre, wantToBuy: e.target.value }));
                                    if (errorStatus.wantToBuy) {
                                        setErrorStatus(pre => ({ ...pre, wantToBuy: '' }));
                                    }
                                }}
                            />
                            <DropdownInput title={'Floor Preference'} options={['0-5', '6-10', '>10']} placeholder={'Floor Preference'}
                                value={profileData.floorPref}
                                onChange={(e) => {
                                    setProfileData(pre => ({ ...pre, floorPref: e.target.value }));
                                    if (errorStatus.floorPref) {
                                        setErrorStatus(pre => ({ ...pre, floorPref: '' }));
                                    }
                                }}
                            />
                        </>}
                    </div>
                    <button className={styles.btnBlackHover + styles.formBtn + ' mt-12'} disabled={loading} onClick={updateProfile}>Update Profile</button>
                </div>
            </div>
            <TopCItiesFilter />
            <Footer />
        </div>
    );
}

export default EditProfile;
