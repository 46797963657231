import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/Header/Header';
import FormCatagories, { JoditTextEditor } from '../../components/PostProperty/PostPropertyComp';
import { styles } from '../../Styles/Styles';

// import JoditEditor from 'jodit-react';
import TopCItiesFilter from '../../components/TopCItiesFilter';
import Footer from '../../components/Footer';
import NewProjectInfo from './NewProjectInfo';
import NewProjectGallery from '../../components/PostProperty/NewProjectGallery';
import UnitConfiguration from '../../components/PostProperty/UnitConfiguration';
import { useDispatch, useSelector } from 'react-redux';
import { setPostPropertyFormData } from '../../Redux/reducer/User';
import { useLocation } from 'react-router-dom';
import { UseApi } from '../../ApiConf';
import loader from '../../assets/Icons/loader.gif';


const formCatagories = ['New Project Info', 'Gallery', 'Unit Configuration'];

const NewProject = () => {
    const [currCatagory, setCurrCategory] = useState('New Project Info');
    const { postPropertyFormData, login_status, pageRefresh,loading } = useSelector(state => state.User);
    const videoSection = useRef();
    const currPath = useLocation();
    const { Request } = UseApi();

    const dispatch = useDispatch();
    useEffect(() => {
        if (login_status) {
            console.log('currPath...', currPath);
            let arr = currPath.pathname.split('/');
            console.log('arr...currpath..', arr);
            if (currPath.pathname.includes('/post-property/new-projects')) {
                if (arr.length > 3) {
                    getPropertyData(arr[3]);
                } else {
                    dispatch(setPostPropertyFormData({
                        ...postPropertyFormData,
                        generalInfo: { name: '', email: '', countryCode: '+91', mobileNum: '', Iam: 'Individual', Otp: '', mobileVerification: false, completed: true },
                        newProjectInfo: { ...postPropertyFormData.newProjectInfo, propertyID: '', projectId: '', imageUrl: '', completed: false },
                        newProjectGallery: { ...postPropertyFormData.newProjectGallery, completed: false }
                    }));
                }
            }
        } else {
            dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { name: '', email: '', countryCode: '+91', mobileNum: '', Iam: 'Individual', Otp: '', mobileVerification: false, completed: false } }));
        }
    }, [login_status, currPath]);

    // useEffect(() => {
    //     console.log('currPath...', currPath);
    //     let arr = currPath.pathname.split('/');
    //     console.log('arr...currpath..', arr);
    //     if (currPath.pathname.includes('/post-property/new-projects')) {
    //         if (arr.length > 3) {
    //             getPropertyData(arr[3]);
    //         } else if (postPropertyFormData.newProjectInfo.propertyID || postPropertyFormData.newProjectInfo.projectId) {
    //             dispatch(setPostPropertyFormData({
    //                 ...postPropertyFormData,
    //                 newProjectInfo: { ...postPropertyFormData.newProjectInfo, propertyID: '', projectId: '' }
    //             }));
    //         }
    //     }
    // }, [currPath]);

    const getPropertyData = async (propId) => {
        //    if(checkInputValidation()) return;
        let token = localStorage.getItem('token');
        let inputdata = {
            top_selling_project_id: propId,
            token: token,
        }
        let data;
        try {
            data = await Request('get-new-project-data', 'POST', inputdata);
        } catch (err) {
            console.log(err);
            // if (err?.message) {
            //     setInputErrorStatus(pre => ({ ...pre, Otp: err.message }));
            // }
        }
        console.log('result...', data);
        if (data?.statusCode == 200) {
            if (data.success) {
                // navigate('/dashboard/add-property/id');
                let propData = data.data.projectData;
                let gallery = data.data?.gallery;
                console.log('propdata...', propData);
                let amenities = {};
                // let furnishing = {};
                let furnishingItems = [];
                if (propData.Amenities) {
                    propData.Amenities.split(',').forEach((item) => {
                        if (item) {
                            amenities[item] = true
                        }
                    });
                }
                if (propData.FurnishingItem) {
                    propData.FurnishingItem.split(',').forEach((item) => {
                        if (item) {
                            furnishingItems = [...furnishingItems, { value: item, label: item }]
                        }
                    });
                }
                let unitconfigList = [];
                if (data.data.unitConf) {
                    unitconfigList = data.data.unitConf.map((item, index) => {
                        return {
                            completed: false, unitFrom: item.unit_from, unitTo: item.unit_to, unitType: item.unit_type, availability: item.availability, builtUpAreaFrom: item.built_up_area_from, builtUpAreaTo: item.built_up_area_to, builtUpUnit: item.built_up_area_measurement,
                            carpetAreaFrom: item.carpet_area_from, carpetAreaTo: item.carpet_area_to, carpetUnit: item.carpet_area_measurement, minPrice: item.min_price, maxPrice: item.max_price, liveInTourVideo: item.live_in_tour, makePayment: false, addPackage: '1',
                            floorPlanImage: item.floor_plan_image, floorPlanImageName: item.floor_plan_image_name, id: item.id
                        }
                    });
                }

                dispatch(setPostPropertyFormData({
                    ...postPropertyFormData,
                    generalInfo: { name: '', email: '', countryCode: '+91', mobileNum: '', Iam: 'Individual', Otp: '', mobileVerification: false, completed: true },
                    // currPropId: propData.ID,
                    // PlanStatus: propData.PlanStatus,
                    // currency: propData.CurrencySymbol,
                    // generalInfo: { name: propData.Name, email: propData.Email, countryCode: `+${propData.Country}`, mobileNum: '', Iam: 'Individual', Otp: '', mobileVerification: false, completed: false },
                    // type: propData.Featured == 'Y' ? 'featured' : '',
                    newProjectGallery: { completed: true, propertyImgScr: gallery.property ? gallery.property : [], floorPlanImgScr: gallery.floor ? gallery.floor : [], masterPlanImgScr: gallery.masterPlan ? gallery.masterPlan : [], routeMapImgScr: gallery.routeMap ? gallery.routeMap : [] },
                    newProjectInfo: {
                        completed: true, country: propData.t_country, state: propData.t_state, city: propData.t_city, locality: propData.t_locality, subLocality: propData.sublocality, societyName: propData.t_society_name, propertyType: propData.PropertySubTypeId, bedroomFrom: propData.bedrooms_from != '0' ? propData.bedrooms_from : '', bedRoomTo: propData.bedrooms_to != '0' ? propData.bedrooms_to : '',
                        bathroomsFrom: propData.bathrooms_from != '0' ? propData.bathrooms_from : '', bathroomsTo: propData.bathrooms_to != '0' ? propData.bathrooms_to : '', totalFloor: propData.floors_from != '0' ? propData.floors_from : '', amenities: amenities, furnishingType: propData.Furnishing, furnishingItem: furnishingItems, areaFrom: propData.area_from != '0' ? propData.area_from : '', areaUnit: propData.area_from_unit, areaTo: propData.area_to != '0' ? propData.area_to : '', areaToUnit: propData.area_to_unit,
                        priceFrom: propData.price_from != '0' ? propData.price_from : '', priceTo: propData.price_to != '0' ? propData.price_to : '', builderName: propData.builder_name, projectStaus: propData.project_status, description: propData.description, aboutBuilder: propData.about_builder, projectLink: propData.project_link, image: '', video: '', imageFile: null, imageUrl: propData.Image, propertyID: propData.propertyID, projectId: propData.ID
                    },
                    unitConfig: unitconfigList.length > 0 ? unitconfigList[unitconfigList.length - 1] : {
                        completed: false, unitFrom: '', unitTo: '', unitType: '', availability: '', builtUpAreaFrom: '', builtUpAreaTo: '', builtUpUnit: '', carpetAreaFrom: '', carpetAreaTo: '', carpetUnit: '',
                        minPrice: '', maxPrice: '', liveInTourVideo: null, makePayment: false, addPackage: '1', floorPlanImage: null, id: ''
                    },
                    unitConfigList: unitconfigList,
                    floorPlanImageDropdown: data.data?.floorPlanImageDropdown ? data.data?.floorPlanImageDropdown : [],
                    currPlayVideoUrl: null
                }));

                // setCurrCategory('Nearby Places');
                // window.scrollTo({ top: 0, });
            } else {
                // console.log(data?.message)
                // toast(data.message, { type: 'error' });
            }
        }

    }

    const changeCategories = (catetory) => {
        setCurrCategory(catetory);
    }

    const onclickVideoPopup = (e) => {
        // console.log('videoSection.current.contains...', videoSection.current.contains(e.target));
        if (!videoSection.current.contains(e.target)) {
            dispatch(setPostPropertyFormData({ ...postPropertyFormData, currPlayVideoUrl: null }));
        }
    }

    return (
        <div>
            <Header />
            {/* <JoditTextEditor title={'Description'} /> */}
            <div className='mt-[80px]'>
                {postPropertyFormData.currPlayVideoUrl && <div className='fixed h-screen w-screen top-0 flex items-center justify-center z-50 bg-black bg-opacity-70'
                    onClick={onclickVideoPopup}
                >
                    <div className='relative flex items-center justify-center w-1/2 mt-14 border-dotted border-[1px] border-white p-[1px]' >
                        <i class="fa-solid fa-xmark absolute text-2xl -top-8 -right-5 text-white cursor-pointer"
                            onClick={() => dispatch(setPostPropertyFormData({ ...postPropertyFormData, currPlayVideoUrl: null }))}></i>
                        <video ref={videoSection} autoPlay controls width="" className='w-full'>
                            {/* C:\fakepath\file_example_MP4_480_1_5MG.mp4 */}
                            <source
                                // src="https://www.truehomes24.com/assets/dynamic/unit-configuration/6636/5dd451239ce95933b2be48ec8a0aa057.mp4"
                                src={postPropertyFormData.currPlayVideoUrl}
                                type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>

                </div>}
                <div className={styles.postpropTitle}>New Projects</div>
                {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                    <img alt="Please wait.." title="Please wait.." src={loader} />
                </div>}
                <div className='mt-5 px-2 container mx-auto mb-20'>
                    <FormCatagories catagories={formCatagories} activeCatagory={currCatagory} onClickItem={(item) => setCurrCategory(item)} />
                    <div className={styles.formCard}>
                        {currCatagory === 'New Project Info' && <NewProjectInfo setCurrCategory={changeCategories} />}
                        {currCatagory === 'Gallery' && <NewProjectGallery setCurrCategory={changeCategories} />}
                        {currCatagory === 'Unit Configuration' && <UnitConfiguration setCurrCategory={changeCategories} />}
                    </div>
                </div>
            </div>
            <TopCItiesFilter />
            <Footer />
        </div>
    );
}

export default NewProject;
