import React, { useEffect, useState } from 'react';
import { styles } from '../Styles/Styles';
import { LoadScript, GoogleMap, Autocomplete, Marker, Circle } from '@react-google-maps/api';

const nearByPlacesType = [
    { label: 'Bus Stand', value: 'bus_station' },
    { label: 'School', value: 'school' },
    { label: 'Hospital', value: 'hospital' },
    { label: 'ATM', value: 'atm' },
    { label: 'Bank', value: 'bank' },
    { label: 'Park', value: 'park' },
    { label: 'Pharmacy', value: 'pharmacy' },
    // { label: 'Salon', value: 'salon' },
    { label: 'Restaurant', value: 'restaurant' },
    { label: 'Airport', value: 'airport' },
    // { label: 'Garden', value: 'garden' }
    // { label: 'Project', value: 'project' },
];
const circleOptions = {
    // strokeColor: '#FF0000', // Color of the circle border
    strokeOpacity: 0.5, // Opacity of the circle border
    strokeWeight: 0, // Width of the circle border
    fillColor: '#D2691E', // Color of the circle fill
    fillOpacity: 0.2, // Opacity of the circle fill
    clickable: false, // Disable click event on the circle
    draggable: false, // Disable drag event on the circle
    editable: false, // Disable edit event on the circle
    visible: true, // Make the circle visible
    radius: 100, // Radius of the circle in meters
    // center: center, // Center of the circle
};

const libraries = ["places"];

const NearByLocation = ({ address, nearTo, showMap, hideNearby, height, zoom, iconSize, hideMarker, customControl, circle }) => {
    const [nearByType, setNearByType] = useState({ label: '', value: '' });
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [nearbyPlaces, setNearbyPlaces] = useState([]);
    const [locality, setLocality] = useState('');

    useEffect(() => {
        fetchCoordinates(address);
    }, [address]);
    const fetchCoordinates = async (address) => {
        try {
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyDPTHOYE5ZFGDIYxVsiJmOwMn9sHx0iYQA`);
            const data = await response.json();
            console.log('fetchcordinates data...', data);
            if (data.results && data.results.length > 0) {
                const { lat, lng } = data.results[0].geometry.location;
                let addressArr = data.results[0].formatted_address.split(',');
                setLocality(addressArr[0]);
                setCenter({ lat, lng });
                if (!hideNearby) {
                    performNearbySearch(data.results[0].geometry.location, nearByType);
                }
                // fetchNearbyPlaces(data.results[0].geometry.location);
            } else {
                console.error('No results found');
            }
        } catch (error) {
            console.error('Error fetching coordinates:', error);
        }
    };

    const performNearbySearch = (location, nearbyloc) => {
        console.log('nearbyloc.....', nearbyloc);
        // if (!googleLoaded) return;
        let mainLocation = location ? location : center;
        const request = {
            location: mainLocation,
            radius: 5000, // Specify the radius in meters (e.g., 5000 meters)
            // type: nearbyloc.value ? nearbyloc.value : nearByType.value // Specify the type of place you want to search for
            type: nearbyloc.value
            // type: 'hospital' // Specify the type of place you want to search for
        };

        const service = new window.google.maps.places.PlacesService(document.createElement('div'));
        service.nearbySearch(request, (results, status) => {
            if (status === window?.google?.maps.places.PlacesServiceStatus.OK) {
                const placesWithDistance = results.map(place => {
                    const distance = calculateDistance(place.geometry.location, mainLocation);
                    return { ...place, distance };
                });
                const sortedPlaces = placesWithDistance.sort((a, b) => a.distance - b.distance);
                setNearbyPlaces(sortedPlaces);
                console.log('nearby results...', results);
            } else {
                console.error('Nearby search failed:', status);
            }
        });
    };

    const calculateDistance = (location, mainLocation) => {
        let mainLoc = mainLocation ? mainLocation : center;
        let lat1 = location.lat();
        let lon1 = location.lng();
        let lat2 = mainLoc.lat;
        let lon2 = mainLoc.lng;
        const R = 6371; // Radius of the Earth in kilometers
        const dLat = (lat2 - lat1) * (Math.PI / 180); // Convert degrees to radians
        const dLon = (lon2 - lon1) * (Math.PI / 180); // Convert degrees to radians
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in kilometers
        return distance.toFixed(2);
    };

    return (
        <div>
            <LoadScript
                libraries={libraries}
                googleMapsApiKey="AIzaSyDPTHOYE5ZFGDIYxVsiJmOwMn9sHx0iYQA">
                {showMap && <GoogleMap
                    id="autocomplete-map"
                    mapContainerStyle={{
                        width: '100%',
                        height: height ? height : '400px'
                    }}
                    zoom={zoom ? zoom : 14}
                    center={center}
                    options={customControl ? {
                        disableDefaultUI: true,
                        // zoomControl:true
                    } : {}}
                >
                    {/* <Marker position={center}/> */}
                    
                    {!hideMarker && <Marker position={center}
                        // icon={iconSize ? {
                        //     // scaledSize: new window.google.maps.Size(iconSize, iconSize),
                        //     url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
                        // } : {}}
                        //    style = {{width:'20px',height:'20px'}}
                    />}
                    {!hideNearby && nearbyPlaces.map((place, index) => (
                        <Marker
                            key={index}
                            // shape={}
                            position={{ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }}
                            icon={{
                                url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png', // Set custom icon for main pointer
                            }}
                            title={place.name}
                        />
                    ))}
                    {circle && <Circle options={{ ...circleOptions, center: center }} />}
                </GoogleMap>}
            </LoadScript>
            {!hideNearby && <div className='mt-10'>
                <div className={'mt-5 grid grid-cols-1 md:grid-cols-2 gap-3'}>
                    <div className={''}>
                        <span className={styles.textMedium}>Search Nearby Places</span>
                        <select name="" className={styles.input + 'mt-1 text-gray-500 '} value={JSON.stringify(nearByType)} onChange={(e) => {
                            let item = JSON.parse(e.target.value);
                            setNearByType(item);
                            performNearbySearch(center, item);
                        }}>
                            <option value={JSON.stringify({ label: '', value: '' })}>Select NearBy Type</option>
                            {nearByPlacesType.map((item, index) => {
                                return (
                                    <option key={index} className='text-sm sm:text-base text-gray-500' value={JSON.stringify(item)}>{item.label}</option>
                                )
                            })}
                        </select>
                    </div>

                </div>
                <div className='mt-5 lg:w-[100%]'>
                    {nearTo && <p className={styles.title4}>{nearByType.label} {nearByType.label ? 'near' : 'Near'} to {locality}</p>}
                    {nearbyPlaces.map((item, index) => {
                        return (
                            <div className='grid grid-cols-2 gap-5 mt-2  px-2 border-b-[1px] border-b-gray-200'>
                                <div>{item.name}</div>
                                {/* {calculateDistance(item.geometry.location)} */}
                                <div className='text-right'>{item.distance} KM</div>
                            </div>
                        )
                    })}
                </div>
            </div>}
        </div>
    );
}

export default NearByLocation;
