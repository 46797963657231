import React, { useEffect, useState } from 'react';
import { ButtonList, CategoryTitle, DropdownInput, JoditTextEditor } from './PostPropertyComp';
import { styles } from '../../Styles/Styles';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setPostPropertyFormData } from '../../Redux/reducer/User';
import { toast } from 'react-toastify';
import { numberToWords } from "amount-to-words";
import { UseApi } from '../../ApiConf';
import loader from '../../assets/Icons/loader.gif';

const facing = ['North', 'East', 'South', 'West', 'North East', 'North West', 'South East', 'South West'];
const ownerShipTypes = ['Freehold', 'Leasehold', 'Power Of Attorney', 'Co Operative Society'];
const parkingTypes = ['4 Wheeler', '2 Wheeler', 'Both', 'None'];
const flooringTypes = ['Mosaic', 'Wooden', 'Marble', 'Granite', 'Vertified Tiles', 'Ceramic Tiles', 'Common Tiles', 'Marbonite'];
const views = ['Pool', 'Garden', 'Lake', 'Road'];
const furnishingStatus = [
    { label: 'Furinshed', value: 'Furinshed' },
    { label: 'Semi Furnished', value: 'Semi furnished' },
    { label: 'Unfurnished', value: 'Unfurnished' }
];
const amenities = ['24by7Water', 'CCTV Camera', 'Gated Society', 'Gym', 'Internet Connectivity', 'Jogging Track', 'Kid Play Area', 'Kid Play Pool',
    'Large Open Space', 'Laundry Services', 'Lift', 'Maintenance Staff', 'Multipurpose Hall', 'Power Backup', 'RainWater Harvesting', 'Security', 'Swimming Pool', 'Visitor Parking', 'Waste Disposal'
];
const furnishingItems = ['Air Conditioner', 'Bath Rugs', 'Bed', 'Book Case', 'Chairs', 'Chimney', 'Curtain',
    'Desk', 'Dining Table', 'Dinnerware', 'Dish Rack', 'DVD Player', 'Exhaust Fan', 'Fan', 'Food Storage Containers', 'Gas Stove', 'Hair Dryer',
    'Iron Board', 'Laundry Basket', 'Mattress', 'Microwave Oven', 'Modular Kitchen', 'Pillows', 'Pots', 'Refrigerator', 'Shower',
    'Smart TV', 'Sofa', 'Table Lamps', 'Washing Maching'
]
const postPropertyPackages = [
    { label: 'Gold - Rs. 5 / Day ( 5 Images + 1 Video )', value: '1' },
    { label: 'Diamond - Rs. 7 / Day ( 7 Images + 1 Video )', value: '2' },
    { label: 'Platinum - Rs. 8 / Day ( 10 Images + 1 Video )', value: '3' }
]
const featuredPackages = [
    { label: 'Featured Property - Rs. 10 / Day ( 15 Images + 1 Video)', value: '5' }
]

const Amenities = ({ setCurrCategory }) => {
    const [animation, setAnimation] = useState(false);
    const { postPropertyFormData, userData, loading } = useSelector(state => state.User);
    const [inputErrStatus, setInputErrStatus] = useState({ salePrice: '', pricePerUnit: '' });
    const dispatch = useDispatch();
    const { Request } = UseApi();
    // const [loading, setLoading] = useState(false);
    const [packageDetails, setPackageDetails] = useState([])

    useEffect(() => {
        setAnimation(true);
    }, []);


    useEffect(() => {
        async function fetchAdPackage() {
            const token = localStorage.getItem('token');
            let inputdata = {
                type: '1',
                token: token,
            }
            try {
                const data = await Request('ad-package', 'POST', inputdata);
                if (data) {
                    
                    if(data?.data?.length){
                        console.log('data--------------->', data);
                        const list = data.data.map((item)=>{
                           return {label: `${item?.planName} - Rs. ${item?.costPerDay} ( ${item?.photosOfProperty} Images + 1 Video )`, value: item?.photosOfProperty}
                        })
                        console.log('list--------->', list);
                          setPackageDetails(list)
                    }
                  
                }
            } catch (err) {
                console.log(err);
            }
        }

        fetchAdPackage()
    }, [])

    //  useEffect(() => {
    //     async function requestClipboardPermission() {
    //         try {
    //             await navigator.clipboard.writeText('Permission test');
    //             console.log('Clipboard access granted');
    //         } catch (error) {
    //             console.error('Clipboard access denied:', error);
    //         }
    //     }

    //     requestClipboardPermission();
    // }, []);


    const onClickSave = async () => {

        // console.log('convert....', convertAmenitiesToString());
        if (checkInputValidation()) return;
        dispatch(setLoading(true));
        let amenityStr = convertAmenitiesToString();
        let furnishingStr = convertFurnishingToString();

        let isLandFlag = (postPropertyFormData.propertyInfo.propertyType == 3 || postPropertyFormData.propertyInfo.propertyType == 9 ||
            postPropertyFormData.propertyInfo.propertyType == 14 || postPropertyFormData.propertyInfo.propertyType == 15 || postPropertyFormData.propertyInfo.propertyType == 16) ? 'Yes' : 'No'
        let inputdata = {
            type: '2',
            token: userData?.jwt_tocken,
            property_id: postPropertyFormData.currPropId,
            action: 'update_info',
            temp_status_listed: postPropertyFormData.propertyInfo.listedFor,
            is_land_flag: isLandFlag,
            ExpectedPriceRent: postPropertyFormData.amenities.ExpectedPriceRent,
            ElectricityChargeIncluded: postPropertyFormData.amenities.ElectricityChargeIncluded,
            SecurityDeposite: postPropertyFormData.amenities.SecurityDeposite,
            Maintenance: postPropertyFormData.amenities.Maintenance,
            MaintenanceType: postPropertyFormData.amenities.MaintenanceType,
            DurationOfRentAgreement: postPropertyFormData.amenities.DurationOfRentAgreement,
            MonthOfNotice: postPropertyFormData.amenities.MonthOfNotice,
            expected_price: postPropertyFormData.amenities.salePrice,
            price_per_unit: postPropertyFormData.amenities.pricePerUnit,
            price_negotiable: postPropertyFormData.amenities.isPriceNegotiable,
            Facing: postPropertyFormData.amenities.facing,
            Ownership: postPropertyFormData.amenities.ownership,
            ReservedParking: postPropertyFormData.amenities.parking,
            Flooring: postPropertyFormData.amenities.flooringType,
            ViewSide: postPropertyFormData.amenities.view,
            Furnishing: postPropertyFormData.amenities.furnishingStatus,
            FurnishingItem: furnishingStr,
            Amenities: amenityStr,
            temp_plan_id: postPropertyFormData.amenities.adPackage,
            description: postPropertyFormData.amenities.aboutProperty,
            AboutBuilder: postPropertyFormData.amenities.aboutBuilder,
            embedded_location: postPropertyFormData.amenities.embadedLocation
        }
        let data;
        try {
            data = await Request('submit-property', 'POST', inputdata);
        } catch (err) {
            console.log(err);
            // if (err?.message) {
            //     setInputErrorStatus(pre => ({ ...pre, Otp: err.message }));
            // }
        }
        console.log('result...', data);
        if (data?.statusCode == 200) {
            dispatch(setLoading(false))
            if (data.success) {
                toast(data.message, { type: 'success' });
                // navigate('/dashboard/add-property/id');
                dispatch(setPostPropertyFormData({
                    ...postPropertyFormData,
                    amenities: { ...postPropertyFormData.amenities, completed: true }
                }));
                setCurrCategory('Gallery');
                window.scrollTo({ top: 0, });
            } else {
                // toast(data.message, { type: 'error' });
            }
        } else {
            dispatch(setLoading(false))
        }

        // setCurrCategory('Nearby Places');
        // dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, completed: true } }));
        // toast('Amenities information saved successfully !', { type: 'success' });
        // window.scrollTo({ top: 0, });
    }

    const convertAmenitiesToString = () => {
        let str = '';
        amenities.forEach((item, index) => {
            if (postPropertyFormData.amenities.aminities[item]) {
                str = item + (str ? ',' : '') + str;
            }
        });
        return str;
    }

    const convertFurnishingToString = () => {
        let str = '';
        furnishingItems.forEach((item, index) => {
            if (postPropertyFormData.amenities.furnishingItems[item]) {
                str = item + (str ? ',' : '') + str;
            }
        });
        return str;
    }

    const checkInputValidation = () => {
        let error = false;
        if (!postPropertyFormData.amenities.salePrice) {
            setInputErrStatus(pre => ({ ...pre, salePrice: 'Please write sale price !' }));
            error = true;
        }
        if (!postPropertyFormData.amenities.aboutProperty) {
            setInputErrStatus(pre => ({ ...pre, aboutProperty: 'Please write about property !' }));
            error = true;
        }
        if (!postPropertyFormData.amenities.aboutProperty) {
            setInputErrStatus(pre => ({ ...pre, aboutProperty: 'Please write about property !' }));
            error = true;
        }
        console.log('postPropertyFormData.amenities.aboutBuilder..', postPropertyFormData.amenities.aboutBuilder)
        if (postPropertyFormData.amenities.aboutBuilder?.replace(/<[^>]+>/g, '').length == 0) {
            setInputErrStatus(pre => ({ ...pre, aboutBuilder: 'Please write about builder !' }));
            error = true;
        }
        console.log('postPropertyFormData.amenities.aboutProperty...', postPropertyFormData.amenities.aboutProperty);
        if (postPropertyFormData.amenities.aboutProperty?.replace(/<[^>]+>/g, '').length == 0) {
            setInputErrStatus(pre => ({ ...pre, aboutProperty: 'Please write about property !' }));
            error = true;
        }
        console.log('postPropertyFormData.amenities.embadedLocation...', postPropertyFormData.amenities.embadedLocation);
        if (!postPropertyFormData.amenities.embadedLocation) {
            setInputErrStatus(pre => ({ ...pre, embadedLocation: 'Please copy and past google map embed location ' }));
            error = true;
        }
        // if (!postPropertyFormData.amenities.pricePerUnit) {
        //     setInputErrStatus(pre => ({ ...pre, pricePerUnit: 'Please write price per unit !' }));
        //     error = true;
        // }
        return error;
    }


    return (
        <div className={'mt-16 '
            // + (animation ? 'transition-transform ease-in-out transform -translate-y-10 duration-1000' : '')
        }>
            {
                (postPropertyFormData.generalInfo.completed && postPropertyFormData.propertyInfo.completed) ? <>
                    <CategoryTitle title={'Amenities :'} icon={'fa fa-house'} />
                    {/* {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                        <img alt="Please wait.." title="Please wait.." src={loader} />
                    </div>} */}
                    {postPropertyFormData.propertyInfo.listedFor == 'Sale' && <div className='mt-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 lg:gap-4'>
                        <DropdownInput title={'Sale Price ( Rs. )'} required={true} placeholder={'Enter Sale price'} inputClass={'w-[100%] mt-2'}
                            error={inputErrStatus.salePrice}
                            instruction={'Enter 0 if not interested to disclose'}
                            inWord={postPropertyFormData.amenities.salePrice !== '' && postPropertyFormData.amenities.salePrice?.length < 10 && numberToWords(postPropertyFormData.amenities.salePrice)}
                            value={postPropertyFormData.amenities.salePrice}
                            // error={inputErrStatus.salePrice}
                            onChange={(e) => {
                                if (/^[0-9]*$/.test(e.target.value)) {
                                    dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, salePrice: e.target.value } }));
                                    if (inputErrStatus.salePrice) { setInputErrStatus(pre => ({ ...pre, salePrice: '' })) };
                                }
                            }}
                        />
                        <DropdownInput title={`Price /${postPropertyFormData.propertyInfo.buildUpUnit} ( Rs. )`} required={false} placeholder={'0'} inputClass={'w-[100%] mt-2'}
                            value={postPropertyFormData.amenities.pricePerUnit}
                            error={inputErrStatus.pricePerUnit}
                            onChange={(e) => {
                                if (/^[0-9]*$/.test(e.target.value)) {
                                    dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, pricePerUnit: e.target.value } }));
                                    if (inputErrStatus.pricePerUnit) { setInputErrStatus(pre => ({ ...pre, pricePerUnit: '' })) };
                                }
                            }}
                        />
                        <div className='sm:ml-10 mt-5 flex-none'>
                            <div className={styles.textMedium + '-mt-[10px]'}>Price Negotiable<span className='text-red-500'>*</span></div>
                            <div className='flex gap-5 mt-2'>
                                <div className='flex'><input type='radio' name='price' className='mr-2 size-5 mt-[1px]'
                                    checked={postPropertyFormData.amenities.isPriceNegotiable == 'Y'}
                                    onClick={() => {
                                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, isPriceNegotiable: 'Y' } }));
                                        if (inputErrStatus.isPriceNegotiable) { setInputErrStatus(pre => ({ ...pre, isPriceNegotiable: '' })) };
                                    }}
                                /> <span>Yes</span></div>
                                <div className='flex'> <input type='radio' name='price' className='mr-2 mt-[1px] size-5'
                                    checked={postPropertyFormData.amenities.isPriceNegotiable == 'N'}
                                    onClick={() => {
                                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, isPriceNegotiable: 'N' } }));
                                        if (inputErrStatus.isPriceNegotiable) { setInputErrStatus(pre => ({ ...pre, isPriceNegotiable: '' })) };
                                    }}
                                /><span>No</span></div>
                            </div>
                        </div>
                    </div>}
                    {postPropertyFormData.propertyInfo.listedFor == 'Rent' && <div className='mt-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 lg:gap-4'>
                        <DropdownInput title={'Expected Rent ( Rs. )'} required={true} placeholder={'Enter Expected Rent'} inputClass={'w-[100%] mt-2'}
                            // instruction={'Enter 0 if not interested to disclose'}
                            value={postPropertyFormData.amenities.ExpectedPriceRent}
                            onChange={(e) => {
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, ExpectedPriceRent: e.target.value } }));
                                if (inputErrStatus.ExpectedPriceRent) { setInputErrStatus(pre => ({ ...pre, ExpectedPriceRent: '' })) };
                            }}
                        />
                        <ButtonList title={'Electricity + Water Charge Included '} btnNames={[{ label: 'Yes', value: 'Y' }, { label: 'No', value: 'N' }]} classname={'mt-5'}
                            value={postPropertyFormData.amenities.ElectricityChargeIncluded}
                            onClick={(item) => dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, ElectricityChargeIncluded: item } }))}
                        />
                        <DropdownInput title={'Security Deposit ( Rs. )'} placeholder={'Enter Security Deposit'} inputClass={'w-[100%] mt-2'}
                            // instruction={'Enter 0 if not interested to disclose'}
                            value={postPropertyFormData.amenities.SecurityDeposite}
                            onChange={(e) => {
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, SecurityDeposite: e.target.value } }));
                                if (inputErrStatus.SecurityDeposite) { setInputErrStatus(pre => ({ ...pre, SecurityDeposite: '' })) };
                            }}
                        />
                        <DropdownInput title={'Maintenance  ( Rs. )'} placeholder={'Enter Maintenance'} inputClass={'w-[100%] mt-2'}
                            // instruction={'Enter 0 if not interested to disclose'}
                            value={postPropertyFormData.amenities.Maintenance}
                            onChange={(e) => {
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, Maintenance: e.target.value } }));
                                if (inputErrStatus.Maintenance) { setInputErrStatus(pre => ({ ...pre, Maintenance: '' })) };
                            }}
                        />
                        <DropdownInput title={'Maintenance Type'} placeholder={'Select Maintenance Type'} inputClass={'w-[100%] mt-2'}
                            options={['Monthly', 'Quaterly', 'Six Monthly', 'Yearly']}
                            // instruction={'Enter 0 if not interested to disclose'}
                            value={postPropertyFormData.amenities.MaintenanceType}
                            onChange={(e) => {
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, MaintenanceType: e.target.value } }));
                                if (inputErrStatus.MaintenanceType) { setInputErrStatus(pre => ({ ...pre, MaintenanceType: '' })) };
                            }}
                        />
                    </div>}
                    {postPropertyFormData.propertyInfo.listedFor == 'Rent' && <div className='mt-5 grid grid-cols-1 sm:grid-cols-2 gap-3 lg:gap-4'>
                        <DropdownInput title={'Duration Of Rent Agreement ( In Months )'} placeholder={'Enter Duration Of Rent Agreement ( In Months ) like 12'} inputClass={'w-[100%] mt-2'}
                            required={true}
                            value={postPropertyFormData.amenities.DurationOfRentAgreement}
                            onChange={(e) => {
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, DurationOfRentAgreement: e.target.value } }));
                                if (inputErrStatus.DurationOfRentAgreement) { setInputErrStatus(pre => ({ ...pre, DurationOfRentAgreement: '' })) };
                            }}
                        />
                        <DropdownInput title={'Month Of Notice ( In Months )'} placeholder={'Enter Month Of Notice ( In Months ) like 1'} inputClass={'w-[100%] mt-2'}
                            required={true}
                            value={postPropertyFormData.amenities.MonthOfNotice}
                            onChange={(e) => {
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, MonthOfNotice: e.target.value } }));
                                if (inputErrStatus.MonthOfNotice) { setInputErrStatus(pre => ({ ...pre, MonthOfNotice: '' })) };
                            }}
                        />
                    </div>}
                    {
                        (postPropertyFormData.propertyInfo.propertyType == '1' || postPropertyFormData.propertyInfo.propertyType == '2'
                            || postPropertyFormData.propertyInfo.propertyType == '6' || postPropertyFormData.propertyInfo.propertyType == '10'
                        ) && <>
                            <ButtonList title={'Facing '} btnNames={facing} classname={'mt-5'}
                                value={postPropertyFormData.amenities.facing}
                                onClick={(item) => dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, facing: item } }))}
                            />
                            <ButtonList title={'Ownership'} btnNames={ownerShipTypes} classname={'mt-5'}
                                value={postPropertyFormData.amenities.ownership}
                                onClick={(item) => dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, ownership: item } }))}
                            />
                            <ButtonList title={'Parking '} btnNames={parkingTypes} classname={'mt-5'}
                                value={postPropertyFormData.amenities.parking}
                                onClick={(item) => dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, parking: item } }))}
                            />
                            <ButtonList title={'Types Of Flooring'} btnNames={flooringTypes} classname={'mt-5'}
                                value={postPropertyFormData.amenities.flooringType}
                                onClick={(item) => dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, flooringType: item } }))}
                            />
                            <ButtonList title={'View'} btnNames={views} classname={'mt-5'}
                                value={postPropertyFormData.amenities.view}
                                onClick={(item) => dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, view: item } }))}
                            />

                            <ButtonList title={'Furnishing Status'} btnNames={furnishingStatus} classname={'mt-5'}
                                value={postPropertyFormData.amenities.furnishingStatus}
                                onClick={(item) => dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, furnishingStatus: item } }))}
                            />

                            <div className='mt-5'>
                                <span>Aminities</span>
                                <div className='flex flex-wrap gap-2 mt-2 text-sm'>
                                    {amenities.map((item, index) => {
                                        return (
                                            <button key={index}
                                                onClick={() => dispatch(setPostPropertyFormData({
                                                    ...postPropertyFormData,
                                                    amenities: {
                                                        ...postPropertyFormData.amenities,
                                                        aminities: { ...postPropertyFormData.amenities.aminities, [item]: !postPropertyFormData.amenities.aminities[item] }
                                                    }
                                                }))}
                                                className={(postPropertyFormData.amenities.aminities[item] ? 'border-orange-600 text-orange-600' : 'border-gray-400 text-gray-400') + ' hover:border-orange-600 hover:text-orange-600 border-[1px] px-3 py-1 rounded-xl '}>
                                                {item}
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>

                            {postPropertyFormData.amenities.furnishingStatus && postPropertyFormData.amenities.furnishingStatus != 'Unfurnished' && <div className='mt-5'>
                                <span>Furnishing Item</span>
                                <div className='flex flex-wrap gap-2 mt-2 text-sm'>
                                    {furnishingItems.map((item, index) => {
                                        return (
                                            <button key={index}
                                                onClick={() => dispatch(setPostPropertyFormData({
                                                    ...postPropertyFormData,
                                                    amenities: {
                                                        ...postPropertyFormData.amenities,
                                                        furnishingItems: { ...postPropertyFormData.amenities.furnishingItems, [item]: !postPropertyFormData.amenities.furnishingItems[item] }
                                                    }
                                                }))}
                                                className={(postPropertyFormData.amenities.furnishingItems[item] ? 'border-orange-600 text-orange-600' : 'border-gray-400 text-gray-400') + ' hover:border-orange-600 hover:text-orange-600 border-[1px] px-3 py-1 rounded-xl '}>
                                                {item}
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>}
                        </>
                    }

                    <ButtonList title={'Ad Package'} required={true} btnNames={postPropertyFormData.type ? featuredPackages : packageDetails} classname={'mt-5'}
                        value={postPropertyFormData.amenities.adPackage}
                        onClick={(item) => { dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, adPackage: item } })); console.log('item------>', item); }}
                    />
                    <JoditTextEditor title={'About Property'} maxChar={500}
                        error={inputErrStatus.aboutProperty}
                        value={postPropertyFormData.amenities.aboutProperty}
                        onBlur={(content) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, aboutProperty: content } }))
                        }}
                    />
                    <JoditTextEditor title={'About Builder'} maxChar={500}
                        error={inputErrStatus.aboutBuilder}
                        value={postPropertyFormData.amenities.aboutBuilder}
                        onBlur={(content) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, aboutBuilder: content } }))
                        }}
                    />
                    <div className='mt-5'>
                        <span >Embedded Location<span className='text-red-600'>*</span></span>
                        {console.log('postPropertyFormData.amenities.embadedLocation..', postPropertyFormData.amenities.embadedLocation)}
                        <textarea placeholder='Copy & Past Google Map Embed Location' className={styles.input + ' mt-2'}
                            value={postPropertyFormData.amenities.embadedLocation}
                            onChange={(e) => {
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, amenities: { ...postPropertyFormData.amenities, embadedLocation: e.target.value } }))
                                if (inputErrStatus.embadedLocation) {
                                    setInputErrStatus(pre => ({ ...pre, embadedLocation: '' }))
                                }
                            }}
                        />
                        {inputErrStatus.embadedLocation && <div className='text-red-600 text-sm py-1'>{inputErrStatus.embadedLocation}</div>}
                    </div>
                    <div className='my-7 flex gap-5'>
                        <button className={styles.formBtn}
                            onClick={() => setCurrCategory('Property Info')}
                        >Back</button>
                        <button type='submit' className={styles.formBtn} onClick={onClickSave}>Save & Next</button>
                    </div>
                </>
                    :
                    <div className={(animation ? 'transition-opacity opacity-100 duration-500' : 'opacity-0') + ' bg-red-600 rounded text-white p-2 font-semibold'}>
                        Please submit {!postPropertyFormData.generalInfo.completed ? 'general' : 'property'} information first
                    </div>
            }

        </div>
    );
}

export default Amenities;
