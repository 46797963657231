import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styles } from '../../Styles/Styles';
import { setPostPropertyFormData } from '../../Redux/reducer/User';
import { ButtonList, DropdownInput } from './PostPropertyComp';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { UseApi } from '../../ApiConf';
import loader from '../../assets/Icons/loader.gif';


const unit = [
    { label: '1', value: '1' },
    { label: '1.5', value: '1.5' },
    { label: '2', value: '2' },
    { label: '2.5', value: '2.5' },
    { label: '3', value: '3' },
    { label: '3.5', value: '3.5' },
    { label: '4', value: '4' },
    { label: '4.5', value: '4.5' },
    { label: '5', value: '5' },
    { label: '5.5', value: '5.5' },
    { label: '6', value: '6' },
    { label: '6.5', value: '6.5' },
    { label: '7', value: '7' },
    { label: '7.5', value: '7.5' },
    { label: '8', value: '8' },
    { label: '8.5', value: '8.5' },
    { label: '9', value: '9' },
    { label: '9.5', value: '9.5' },
    { label: '10', value: '10' },
];

const unitTypes = ['Independent House/Villa', 'Apartment'];
const units = ['sq.ft.', 'sq.yards', 'sq.m.', 'acres', 'cents', 'grounds', 'guntha', 'bigha', 'kottah', 'karnal', 'ares', 'aankadam', 'hectares', 'chataks', 'perch', 'rood', 'biswa', 'marla']
const UnitConfiguration = ({ setCurrCategory }) => {
    const [animation, setAnimation] = useState(false);
    const { postPropertyFormData } = useSelector(state => state.User);
    const [inputErrStatus, setInputErrStatus] = useState({});
    const [editIndex, setEditIndex] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const updateBtn = useRef();
    const { Request } = UseApi();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setAnimation(true);
    }, [])

    const submitUnitConfig = async (type) => {
        // setCurrCategory('New Project Info');
        setLoading(true)
        let token = localStorage.getItem('token');
        let inputData = {
            type: '4',
            token: token,
            property_id: postPropertyFormData.newProjectInfo.propertyID,
            top_selling_project_id: postPropertyFormData.newProjectInfo.projectId,
            unit_from: postPropertyFormData.unitConfig.unitFrom,
            unit_to: postPropertyFormData.unitConfig.unitTo,
            unit_type: postPropertyFormData.unitConfig.unitType,
            availability: postPropertyFormData.unitConfig.availability,
            built_up_area_from: postPropertyFormData.unitConfig.builtUpAreaFrom,
            built_up_area_to: postPropertyFormData.unitConfig.builtUpAreaTo,
            builtUpAreameasurement: postPropertyFormData.unitConfig.builtUpUnit,
            carpet_area_from: postPropertyFormData.unitConfig.carpetAreaFrom,
            carpet_area_to: postPropertyFormData.unitConfig.carpetAreaTo,
            carpetAreaMeasurement: postPropertyFormData.unitConfig.carpetUnit,
            min_price: postPropertyFormData.unitConfig.minPrice,
            max_price: postPropertyFormData.unitConfig.maxPrice,
            floorPlanImage: postPropertyFormData.unitConfig.floorPlanImage,
            id: postPropertyFormData.unitConfig.id ? postPropertyFormData.unitConfig.id : ''
        }
        console.log('inputData...', inputData);
        let data;
        try {
            data = await Request('submit-new-project-property', 'POST', inputData);
        } catch (err) {
            console.log(err);
        }
        console.log('data unitconfig...',data);
        if (data.success) {
            toast(data.message, { type: 'success' });
            if (type == 'add') {
                dispatch(setPostPropertyFormData({
                    ...postPropertyFormData,
                    unitConfigList: [...postPropertyFormData.unitConfigList, { ...postPropertyFormData.unitConfig, id: type == 'add' ? data.id : postPropertyFormData.unitConfig.id }]
                }));
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                let arr = postPropertyFormData.unitConfigList.map((item, index) => index == editIndex ? postPropertyFormData.unitConfig : item);
                dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfigList: arr }));
                // toast('Unit configuration updated successfully!', { type: 'success' });
                setEditIndex(null);
            }
        } else {
            toast(data.message, { type: 'error' });
        }
        setLoading(false);
    }

    // const onClickAddNew = async (item) => {
    //     // setCurrCategory('New Project Info');
    //     setLoading(false)
    //     let token = localStorage.getItem('token');
    //     let inputData = {
    //         type: '4',
    //         token: token,
    //         property_id: postPropertyFormData.unitConfig.propertyID,
    //         top_selling_project_id: postPropertyFormData.unitConfig.projectId,
    //         unit_from: postPropertyFormData.unitConfig.unitFrom,
    //         unit_to: postPropertyFormData.unitConfig.unitTo,
    //         unit_type: postPropertyFormData.unitConfig.unitType,
    //         availability: postPropertyFormData.unitConfig.availability,
    //         built_up_area_from: postPropertyFormData.unitConfig.builtUpAreaFrom,
    //         built_up_area_to: postPropertyFormData.unitConfig.builtUpAreaTo,
    //         builtUpAreameasurement: postPropertyFormData.unitConfig.builtUpUnit,
    //         carpet_area_from: postPropertyFormData.unitConfig.carpetAreaFrom,
    //         carpet_area_to: postPropertyFormData.unitConfig.carpetAreaTo,
    //         carpetAreaMeasurement: postPropertyFormData.unitConfig.carpetUnit,
    //         min_price: postPropertyFormData.unitConfig.minPrice,
    //         max_price: postPropertyFormData.unitConfig.maxPrice,
    //         floorPlanImage: postPropertyFormData.unitConfig.floorPlanImage,
    //         id: postPropertyFormData.unitConfig.id
    //     }
    //     console.log('inputData...',inputData);
    //     let data;
    //     try {
    //         data = await Request('submit-new-project-property', 'POST', inputData);
    //     } catch (err) {
    //         console.log(err);
    //     }
    //     if (data.success) {
    //         toast(data.message, { type: 'success' });
    //         dispatch(setPostPropertyFormData({
    //             ...postPropertyFormData,
    //             unitConfigList: [...postPropertyFormData.unitConfigList,{...postPropertyFormData.unitConfig,id:data.id}]
    //         }));
    //         window.scrollTo({ top: 0, behavior: 'smooth' });
    //     } else {
    //         toast(data.message, { type: 'error' });
    //     }
    //     setLoading(false);
    // }



    const onClickEdit = (item, index) => {
        setEditIndex(index);
        dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: item }));
        updateBtn.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // const onClickUpdate = () => {
    //     let arr = postPropertyFormData.unitConfigList.map((item, index) => index == editIndex ? postPropertyFormData.unitConfig : item);
    //     dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfigList: arr }));
    //     toast('Unit configuration updated successfully!', { type: 'success' });
    //     setEditIndex(null);
    // }
    const onClickDelete = async (item, index) => {
        setLoading(true)
        let token = localStorage.getItem('token');
        let inputData = {
            type: '5',
            token: token,
            unit_config_id: item.id

        }
        console.log('inputData...', inputData);
        let data;
        try {
            data = await Request('submit-new-project-property', 'POST', inputData);
        } catch (err) {
            console.log(err);
        }
        console.log('data...delete...',data);
        if (data.success) {
            toast(data.message, { type: 'success' });
            let arr = postPropertyFormData.unitConfigList.filter(it => it != item);
            dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfigList: arr }));
            // toast('Unit configuration deleted successfully!', { type: 'success' });
        } else {
            toast(data.message, { type: 'error' });
        }
        setLoading(false);
    }

    const onClickMakePayment = () => {
        dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: { ...postPropertyFormData.unitConfig, makePayment: true } }));
    }
    const proceedToPayment = () => {
        navigate('/membership');
    }

    return (
        <div className={'mt-16 ' + (animation ? 'transition-transform ease-in-out transform -translate-y-10 duration-1000' : '')}>
            {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                <img alt="Please wait.." title="Please wait.." src={loader} />
            </div>}
            {postPropertyFormData.newProjectInfo.completed ? <>

                {postPropertyFormData.unitConfigList.length > 0 && <div className='overflow-x-auto mb-8'>
                    <p className={styles.title3}>Unit Configuartion List</p>
                    <table className='table-auto min-w-full mt-5 text-center'>
                        <tr className='font-semibold bg-gray-900 text-white'>
                            <td className="px-4 py-2 w-[25%] text-left">Unit Types</td>
                            <td className="px-4  py-2 w-[16%]">Built-Up Area	</td>
                            <td className="px-4  py-2 w-[11%]">Price</td>
                            <td className="px-4 py-2 w-[16%]">Floor Plans</td>
                            <td className="px-4 py-2 w-[16%]">Live-in Tour</td>
                            <td className="px-4 py-2 w-[16%]">Action</td>
                        </tr>
                        {postPropertyFormData.unitConfigList.map((item, index) => {
                            return (
                                <tr>
                                    <td className=" px-4 py-4 text-left ">
                                        {item.unitFrom}-{item.unitTo} BHK {item.unitType}
                                        <p className='text-sm text-gray-600'>Availability: {item.availability == '1' ? 'Yes' : 'No'}</p>
                                    </td>
                                    <td className=" px-4 py-4">{item.builtUpAreaFrom}-{item.builtUpAreaTo} {item.builtUpUnit}</td>
                                    <td className=" px-4 py-4">{item.minPrice}-{item.maxPrice}</td>
                                    <td className=" px-4 py-4">{item.floorPlanImage ? item.floorPlanImage : 'NA'}</td>
                                    <td className=" px-4 py-4">{item.liveInTourVideo ?
                                        <i class="fa-solid fa-film hover:opacity-70 opacity-90 cursor-pointer"
                                            onClick={() => dispatch(setPostPropertyFormData({ ...postPropertyFormData, currPlayVideoUrl: item.liveInTourVideo }))}>
                                        </i> : 'NA'}
                                    </td>
                                    <td className=" px-4 py-4 flex justify-center gap-1">
                                        <button className={styles.btn + 'bg-gray-800 hover:bg-gray-700 py-[2px] text-white'} onClick={() => onClickEdit(item, index)}>Edit</button>
                                        <button className={styles.btn + 'bg-red-800 hover:bg-red-700 py-[2px] text-white'} onClick={() => onClickDelete(item, index)}>Delete</button>
                                    </td>
                                </tr>
                            )
                        })}
                    </table>
                </div>}

                <p className={styles.title3}>{editIndex == null ? 'Add/' : ''}Update Unit Configuration</p>
                <div className='border-b-[1px] border-gray-300 mt-2' />
                <div className='mt-5 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-4 lg:gap-5'>
                    <DropdownInput title={'Unit From'} options={unit} placeholder={'Select Unit From'} required={false}
                        value={postPropertyFormData.unitConfig.unitFrom}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: { ...postPropertyFormData.unitConfig, unitFrom: e.target.value } }));
                            if (inputErrStatus.unitFrom) { setInputErrStatus(pre => ({ ...pre, unitFrom: '' })) };
                        }}
                    />
                    <DropdownInput title={'Unit to'} options={unit} placeholder={'Select Unit to'} required={false}
                        value={postPropertyFormData.unitConfig.unitTo}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: { ...postPropertyFormData.unitConfig, unitTo: e.target.value } }));
                            if (inputErrStatus.unitTo) { setInputErrStatus(pre => ({ ...pre, unitTo: '' })) };
                        }}
                    />
                    <DropdownInput title={'Unit Type'} options={unitTypes} placeholder={'Select Unit Type'} required={false}
                        value={postPropertyFormData.unitConfig.unitType}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: { ...postPropertyFormData.unitConfig, unitType: e.target.value } }));
                            if (inputErrStatus.unitType) { setInputErrStatus(pre => ({ ...pre, unitType: '' })) };
                        }}
                    />
                    <DropdownInput title={'Availability'} options={[{label:'Yes',value:'1'},{label:'No',value:'0'}]} placeholder={'Select Availability'} required={false}
                        value={postPropertyFormData.unitConfig.availability}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: { ...postPropertyFormData.unitConfig, availability: e.target.value } }));
                            if (inputErrStatus.availability) { setInputErrStatus(pre => ({ ...pre, availability: '' })) };
                        }}
                    />
                </div>
                <div className='mt-5 grid grid-cols-1 sm:grid-cols-2 gap-5'>
                    <div className='mt-5 grid grid-cols-1 sm:grid-cols-3'>
                        <DropdownInput title={'Built-up Area From'} placeholder={'Enter Built-up area from'} required={false}
                            value={postPropertyFormData.unitConfig.builtUpAreaFrom}
                            onChange={(e) => {
                                if (/^[0-9.]*$/.test(e.target.value)) {
                                    dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: { ...postPropertyFormData.unitConfig, builtUpAreaFrom: e.target.value } }));
                                    if (inputErrStatus.builtUpAreaFrom) { setInputErrStatus(pre => ({ ...pre, builtUpAreaFrom: '' })) };
                                }
                            }}
                        />
                        <DropdownInput title={'Built-up Area to'} placeholder={'Enter Built-up area to'} required={false}
                            value={postPropertyFormData.unitConfig.builtUpAreaTo}
                            onChange={(e) => {
                                if (/^[0-9.]*$/.test(e.target.value)) {
                                    dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: { ...postPropertyFormData.unitConfig, builtUpAreaTo: e.target.value } }));
                                    if (inputErrStatus.builtUpAreaTo) { setInputErrStatus(pre => ({ ...pre, builtUpAreaTo: '' })) };
                                }
                            }}
                        />
                        <DropdownInput title={'Unit Measurement '} options={units} placeholder={'Select Measurement'} required={false} dropdownClass={'py-[8.5px]'}
                            value={postPropertyFormData.unitConfig.builtUpUnit}
                            onChange={(e) => {
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: { ...postPropertyFormData.unitConfig, builtUpUnit: e.target.value } }));
                                if (inputErrStatus.builtUpUnit) { setInputErrStatus(pre => ({ ...pre, builtUpUnit: '' })) };
                            }}
                        />
                    </div>
                    <div className='mt-5 grid grid-cols-1 sm:grid-cols-3'>
                        <DropdownInput title={'carpet Area From'} placeholder={'Enter carpet area from'} required={false}
                            value={postPropertyFormData.unitConfig.carpetAreaFrom}
                            onChange={(e) => {
                                if (/^[0-9.]*$/.test(e.target.value)) {
                                    dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: { ...postPropertyFormData.unitConfig, carpetAreaFrom: e.target.value } }));
                                    if (inputErrStatus.carpetAreaFrom) { setInputErrStatus(pre => ({ ...pre, carpetAreaFrom: '' })) };
                                }
                            }}
                        />
                        <DropdownInput title={'Carpet Area to'} placeholder={'Enter carpet area to'} required={false}
                            value={postPropertyFormData.unitConfig.carpetAreaTo}
                            onChange={(e) => {
                                if (/^[0-9.]*$/.test(e.target.value)) {
                                    dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: { ...postPropertyFormData.unitConfig, carpetAreaTo: e.target.value } }));
                                    if (inputErrStatus.carpetAreaTo) { setInputErrStatus(pre => ({ ...pre, carpetAreaTo: '' })) };
                                }
                            }}
                        />
                        <DropdownInput title={'Unit Measurement '} options={units} placeholder={'Select Measurement'} required={false} dropdownClass={'py-[8.5px]'}
                            value={postPropertyFormData.unitConfig.carpetUnit}
                            onChange={(e) => {
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: { ...postPropertyFormData.unitConfig, carpetUnit: e.target.value } }));
                                if (inputErrStatus.carpetUnit) { setInputErrStatus(pre => ({ ...pre, carpetUnit: '' })) };
                            }}
                        />
                    </div>

                </div>
                <div className='mt-5 grid grid-cols-1 sm:grid-cols-3 gap-5'>
                    <DropdownInput title={'Min. Price '} placeholder={'Enter minimum price'} required={false}
                        value={postPropertyFormData.unitConfig.minPrice}
                        onChange={(e) => {
                            if (/^[0-9.]*$/.test(e.target.value)) {
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: { ...postPropertyFormData.unitConfig, minPrice: e.target.value } }));
                                if (inputErrStatus.minPrice) { setInputErrStatus(pre => ({ ...pre, minPrice: '' })) };
                            }
                        }}
                    />
                    <DropdownInput title={'Max. Price '} placeholder={'Enter maximum price'} required={false}
                        value={postPropertyFormData.unitConfig.maxPrice}
                        onChange={(e) => {
                            if (/^[0-9.]*$/.test(e.target.value)) {
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: { ...postPropertyFormData.unitConfig, maxPrice: e.target.value } }));
                                if (inputErrStatus.maxPrice) { setInputErrStatus(pre => ({ ...pre, maxPrice: '' })) };
                            }
                        }}
                    />
                    <div>
                        <span className={styles.textMedium}>Live-in Tour (Video)</span>
                        <input className={styles.input + ' mt-1'} type='file' accept='video/*'
                            // value={postPropertyFormData.unitConfig.liveInTourVideo}
                            onChange={(e) => {
                                let url = URL.createObjectURL(e.target.files[0]);
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: { ...postPropertyFormData.unitConfig, liveInTourVideo: url } }));
                                console.log('url ....', url);
                                if (inputErrStatus.liveInTourVideo) { setInputErrStatus(pre => ({ ...pre, liveInTourVideo: '' })) };
                            }}
                        />
                    </div>
                </div>
                <div className='mt-5'>
                    <span className={styles.textMedium}>Floor Plan Image</span>
                    <div className='flex w-[80%] mt-2'>
                        <select name="" className={styles.input + ' text-gray-500 '}
                            value={JSON.stringify(postPropertyFormData.unitConfig.floorPlanImage)}
                            onChange={(e) => {
                                let option = JSON.parse(e.target.value)
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: { ...postPropertyFormData.unitConfig, floorPlanImage: option} }));
                                if (inputErrStatus.floorPlanImage) { setInputErrStatus(pre => ({ ...pre, floorPlanImage: '' })) };
                            }}
                        >
                            <option value={JSON.stringify({Image:'',id:''})}>Select a floor plan Image</option>
                            {postPropertyFormData.floorPlanImageDropdown.map((item, index) => {
                            return (
                                <option key={index} className='text-sm sm:text-base text-gray-500' value={JSON.stringify({item})}>{item.Image}</option>
                            )
                        })}
                        </select>
                        <button className='px-2 border-[1px] border-gray-500 hover:bg-gray-500 hover:text-white flex-none'>Get Images</button>
                    </div>
                </div>
                <div ref={updateBtn} className='my-7 flex gap-2 scroll-mt-[550px]'>
                    <button className={styles.formBtn}
                        onClick={() => {
                            setCurrCategory('Gallery');
                            window.scrollTo({ top: 0 });
                        }}
                    >Back</button>
                    {editIndex !== null ? <button type='submit' className={'hover:bg-green-700 bg-green-600 text-white text-sm sm:text-base px-4 py-1 rounded '} onClick={() => submitUnitConfig('update')} >Update</button>
                        :
                        <button type='submit' className={styles.formBtn} onClick={() => submitUnitConfig('add')} >Add New</button>
                    }
                    <button type='submit' className={'hover:bg-cyan-700 bg-cyan-600 text-white text-sm sm:text-base px-4 py-1 rounded '}
                        onClick={onClickMakePayment}
                    >Make Payment</button>
                </div>
                {postPropertyFormData.unitConfig.makePayment && <div>
                    <ButtonList title={'Add Package'} btnNames={[{ label: 'Top Selling Projects - Rs. 10 / Day ( 15 Images + 1 Video)', value: '1' }]} classname={'mt-5'}
                        value={postPropertyFormData.unitConfig.addPackage}
                        onClick={(item) => dispatch(setPostPropertyFormData({ ...postPropertyFormData, unitConfig: { ...postPropertyFormData.unitConfig, addPackage: item } }))}
                    />
                    <button className='mt-5 hover:bg-cyan-100 bg-cyan-50 rounded text-red-600 py-1 px-2'
                        onClick={proceedToPayment}
                    >Proceed to Payment</button>
                </div>}
            </>
                :
                <div className={(animation ? 'transition-opacity opacity-100 duration-500' : 'opacity-0') + ' bg-red-600 rounded text-white p-2 font-semibold'}>
                    Please submit new project info first
                </div>
            }
        </div>
    );
}

export default UnitConfiguration;
