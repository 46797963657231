import React, { useEffect, useMemo, useRef, useState } from 'react';
import { styles } from '../../Styles/Styles';
import JoditEditor from 'jodit-react';

const FormCatagories = ({ catagories, activeCatagory, onClickItem, classname }) => {
    return (
        <div className={styles.lightBorder + classname + ' flex flex-wrap'}>
            {catagories.map((item, index) => {
                return (
                    <a key={index}
                        // href={`#${index}`}
                        onClick={() => onClickItem(item)}
                        className={(activeCatagory === item ? 'border-b-[1px]' : '') + ' cursor-pointer hover:border-b-[1px] border-b-gray-700 pb-1 mt-2 px-1 mr-[2%]'}>
                        <p className={styles.textMedium + ''}>{item}</p>
                    </a>
                )
            })}
        </div>
    );
}

export const CategoryTitle = ({ title, icon }) => {
    return (
        <div className='flex my-5 text-gray-700 bg-gray-50 p-3 -mx-[1.6%]'>
            <i class={" mt-[5px] mr-2 " + icon}></i>
            <span className={styles.title3}>{title}</span>
        </div>
    )
}

export const ButtonList = ({ title, btnNames, classname, required, onClick, value }) => {
    // const [selectedBtnName, setSelectedBtnName] = useState(initialName);
    return (
        <div className={classname}>
            <span>{title} {required && <span className='text-red-600'>*</span>}</span>
            <div className='flex flex-wrap gap-2 mt-2 text-sm'>
                {btnNames?.length > 0 && btnNames.map((item, index) => {
                    return (
                        <button key={index}
                            onClick={() => onClick(item.label ? item.value : item)}
                            className={(((item.label && value === item.value) || (!item.label && value == item)) ? 'border-orange-600 text-orange-600' : 'border-gray-400 text-gray-400') + ' hover:border-orange-600 hover:text-orange-600 border-[1px] px-3 py-1 rounded-xl '}>
                            {item.label ? item.label : item}
                        </button>
                    )
                })}
            </div>
        </div>
    )
}

export const InputList = ({ inputs, classname }) => {
    return (
        <div className={classname ? classname : 'mt-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3'}>
            {inputs.map((item, index) => {
                return (
                    <DropdownInput title={item.name} options={item.dropdownData} placeholder={item.placeholder} required={item.star} />
                )
            })}
        </div>
    )
}

export const DropdownInput = ({ title, options, placeholder, required, inputClass, type, value, onChange, dropdownClass, instruction, error, inWord, acceptType }) => {
    return (
        <div className={inputClass}>
            {title && <span className={styles.textMedium}>{title}{required && <span className='text-red-500'>*</span>}</span>}
            {options ? <select required={required} name="" className={styles.input + 'mt-1 text-gray-500 ' + dropdownClass} value={value} onChange={(e) => onChange(e)}>
                <option value="">{placeholder}</option>
                {options.map((item, index) => {
                    return (
                        <option key={index} className='text-sm sm:text-base text-gray-500' value={item.label ? item.value : item}>{item.label ? item.label : item}</option>
                    )
                })}
            </select>
                :
                <input required={required} type={type} accept={acceptType ? acceptType : ''} placeholder={placeholder} className={styles.input + 'mt-1'} value={value} onChange={(e) => onChange(e)} />
            }
            {inWord && !error && <div className='text-green-950 text-sm py-1 px-2'>{inWord} only</div>}
            {error && <div className='text-red-600 text-sm py-1'>{error}</div>}
            {instruction && !error && <span className='bg-cyan-100 text-cyan-900 text-sm py-1 px-2'>{instruction}</span>}
        </div>
    )
}

const units = ['sq.ft.', 'sq.yards', 'sq.m.', 'atres', 'cents', 'grounds', 'guntha', 'bigha', 'kottah']
export const AreaInputs = ({ inputDatas }) => {
    return (
        <div className='mt-5 grid grid-cols-1 sm:grid-cols-2 gap-[5%]'>
            {inputDatas.map((item, index) => {
                return (
                    <div>
                        {/* <p className={styles.textMedium}>{item.name}</p> */}
                        <div className='flex justify-between mt-1'>
                            <DropdownInput title={item.name} placeholder={'Enter ' + item.name} inputClass={'w-[70%]'} required={item.star} />
                            <DropdownInput title={'Units'} placeholder={'Please Select'} options={units} inputClass={'w-[27%]'} required={item.star} />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export const JoditTextEditor = ({ title, maxChar, onBlur, value, error }) => {
    const [content, setContent] = useState('');
    const [errMessage, setErrMessage] = useState(null);
    const editor = useRef(null);
    // const jodit  

    // useEffect(() => {
    //     // if (!editor.current) return;
    //     const pasteListener = (event) => {
    //         console.log('event....',event);
    //         const isPasteEvent = event.type === 'paste' || (event.ctrlKey || event.metaKey) && event.key === 'v';
    //         if (isPasteEvent) {
    //             event.preventDefault();
    //             handlePaste();
    //         }
    //     };

    //     document.addEventListener('paste', pasteListener);

    //     return () => {
    //         document.removeEventListener('paste', pasteListener);
    //     };
    // }, []);

    // const handleCopy = () => {
    //     const selectedText = editor.current.selection.text();
    //     navigator.clipboard.writeText(selectedText)
    //         .then(() => {
    //             console.log('Text copied to clipboard successfully');
    //         })
    //         .catch((error) => {
    //             console.error('Failed to copy text to clipboard:', error);
    //         });
    // };

    // const handlePaste = () => {
    //     navigator.clipboard.readText()
    //         .then((text) => {
    //             // const editor = window.Jodit.instances[0];
    //             editor.current.selection.insertHTML(text);
    //             console.log('Text pasted from clipboard successfully');
    //         })
    //         .catch((error) => {
    //             console.error('Failed to read text from clipboard:', error);
    //         });
    // };

    // useEffect(() => {
    //     console.log('content...', content);
    // }, [content]);
    const config = {
        // buttons: [
        //     'bold', 'italic', 'underline', 'strikethrough', 'ul', 'ol', 'indent', 'outdent',
        //     'font', 'fontsize', 'brush', 'paragraph', 'link', 'align', 'undo', 'redo', 'table',
        //     'cut', 'hr', 'copy', 'paste', 'image', 'video', 'source','fullsize'
        // ]
        height: 250,
        // createAttributes: {
        //     div: {
        //         style: {
        //             margin: '10px', // Set the margin for the editor here
        //         },
        //     },
        // },
    };
    // const config = useMemo(
    //     {
    //         readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    //         placeholder: placeholder || 'Start typings...'
    //     },
    //     [placeholder]
    // );
    return (
        <div className='mt-10 mr-2'>
            <p className='mb-2 ml-2'>{title}<span className='text-red-500'>*</span> </p>
            <JoditEditor
                ref={editor}
                value={value ? value : content}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => {
                    if (!errMessage) {
                        setContent(newContent);
                        onBlur(newContent);
                    }
                }}
                
                onChange={newContent => {
                    // console.log('new content...',newContent)
                    // if (!errMessage) {
                    //     setContent(newContent);
                    //     onBlur(newContent);
                    // }
                    let text = newContent.replace(/<[^>]+>/g, '');
                    // console.log('new contenth lenght onchange...', text.length)
                    if (text.length > maxChar) {
                        setErrMessage('Maximum character limit exceeded !');
                    } else if (errMessage) {
                        setErrMessage(null);
                    }
                }}
                className='shadow-md px-1'
            // onChange={newContent => setContent(newContent)}
            />
            {!errMessage && !error && <span className='bg-cyan-100 text-cyan-700 text-sm py-1 px-2'>Only {maxChar} characters allowed</span>}
            {errMessage && !error && <span className='bg-red-100 text-red-900 text-sm py-1 px-2'>{errMessage}</span>}
            {error && <span className='text-red-600 text-sm py-1'>{error}</span>}
        </div>
    )
}

export default FormCatagories;

