import React, { useEffect } from 'react';
import Header from '../../components/Header/Header';
import { NavLink, useNavigate } from 'react-router-dom';
import { styles } from '../../Styles/Styles';
import userBackImage from '../../assets/images/user.svg'
import TopCItiesFilter from '../../components/TopCItiesFilter';
import Footer from '../../components/Footer';
import { useSelector } from 'react-redux';


const ManageProfile = () => {
    const { login_status, pageRefresh, userData } = useSelector(state => state.User);
    // const navigate = useNavigate();
       
    useEffect(()=>{
       console.log('userData...',userData);
    },[]);
    // useEffect(()=>{
    //     if(!login_status && !pageRefresh){
    //       navigate('/');
    //     }
    // },[login_status]);
    return (
        <div>
            <Header />
            <div className='mt-16'>
                <div className='py-5 ml-[8%] border-b-[1px] border-green-300]'>
                    <p className={styles.title2}>Profile Details</p>
                    <div className='text-sm text-gray-700'><NavLink to={'/'}>Home</NavLink>{'/'}<span>Profile Details</span></div>
                </div>
                <div
                    className='flex flex-wrap gap-[5%] pt-8 pl-[10%]'
                    // className='grid grid-cols-1 md:grid-cols-2 gap-[15%] pt-8 pl-[10%]'
                >
                    <div className='flex flex-col items-center mb-5'>
                        <div className='p-2 border-[1px] w-[190px] border-gray-300'>
                            <img alt='' src={userData?.ProfilePhoto_url?userData?.ProfilePhoto_url : userBackImage} className='h-[200px] w-[200px]' />
                        </div>
                    </div>
                    <div className='pb-10 border-gray-300 rounded-md px-5'>
                        <div className='flex gap-5'>
                            <div className='w-[320px]'>
                                <p className={styles.title2 + '-mt-2 mb-1'}>{userData?.Name}</p>
                                <p className={styles.textMedium + ''}>User As: {userData?.UserAs}</p>
                                {userData?.BusinessTitle !== null && <p className={styles.textMedium + 'mt-2'}>Business Title: {userData?.BusinessTitle ? userData?.BusinessTitle : 'N/A'}</p>}
                                <p className={styles.textMedium + 'mt-2'}>{userData?.UserAs == 'Buyer' ? 'Interested' : ''} Country: N/A</p>
                                <p className={styles.textMedium + 'mt-2'}>{userData?.UserAs == 'Buyer' ? 'Interested' : ''} State: N/A</p>
                                <p className={styles.textMedium + 'mt-2'}>{userData?.UserAs == 'Buyer' ? 'Interested' : ''} City: N/A</p>
                                {userData?.BusinessAddress !== null && <p className={styles.textMedium + 'mt-2'}>Business Address: N/A</p>}
                                <p className={styles.textMedium + 'mt-2'}>Mobile : {userData?.ContactNumber}</p>
                                <p className={styles.textMedium + 'mt-2'}>Mobile Verified: {userData?.MobileVerified == 'Y' ? 'Yes' : 'No'}</p>
                                <p className={styles.textMedium + 'mt-2'}>Email : {userData?.Email}</p>
                                <p className={styles.textMedium + 'mt-2'}>Email Verified: {userData?.EmailVerified == 'Y' ? 'Yes' : 'No'}</p>
                            </div>
                           {userData?.UserAs == 'Buyer' && <div className=''>
                                <p className={styles.textMedium + 'mt-0'}>Interested Property : {userData?.InterestedProperty?userData?.InterestedProperty:'N/A'}</p>
                                <p className={styles.textMedium + 'mt-2'}>Budget : {userData.budget?userData.budget : (userData?.MaxBudget || userData?.MinBudget)?(userData?.MinBudget+' to '+ userData?.MaxBudget) :'N/A'}</p>
                                <p className={styles.textMedium + 'mt-2'}>Interested In : {userData?.InterestedIn?userData?.InterestedIn:'N/A'}</p>
                                <p className={styles.textMedium + 'mt-2'}>Facing Preference : {userData?.FacingPreference?userData?.FacingPreference:'N/A'}</p>
                                <p className={styles.textMedium + 'mt-2'}>Want To Buy : {userData?.WantToBuy?userData?.WantToBuy:'N/A'}</p>
                                <p className={styles.textMedium + 'mt-2'}>Floor Preference  : {userData?.FloorPreference?userData?.FloorPreference:'N/A'}</p>
                            </div>}
                        </div>
                        <div className=''>
                            <NavLink to={'/dashboard/edit-profile'}>
                                <button className={styles.formBtn + styles.btnBlackHover + 'mt-8'}>Edit Profile</button>
                            </NavLink>
                        </div>
                    </div>

                </div>

            </div>
            <TopCItiesFilter />
            <Footer />

        </div>
    );
}

export default ManageProfile;
