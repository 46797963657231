import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import TopCItiesFilter from '../../components/TopCItiesFilter';
import Footer from '../../components/Footer';
import { NavLink } from 'react-router-dom';
import { styles } from '../../Styles/Styles';
import { UseApi } from '../../ApiConf';
import loader from '../../assets/Icons/loader.gif';

const MyOrders = () => {
    const [currTab, setCurrTab] = useState('completed');
    const [myOrders, setMyOrders] = useState({ completed: { list: [], total: '0' }, pending: { list: [], total: '0' } });
    const [loading, setLoading] = useState(true);
    const { Request } = UseApi();

    useEffect(() => {
        getMyOrders();
    }, []);

    const getMyOrders = async () => {
        let token = localStorage.getItem('token');
        setLoading(true);
        let inputdata = {
            token: token,
            page: 1,
            // limit: '10',
        }
        let data;
        try {
            data = await Request('my-order', 'POST', inputdata);
        } catch (err) {
            console.log('error...', err);
        }
        console.log(' data...', data);
        if (data?.success && data.data) {
            setMyOrders(data.data);
        } else {
            // toast(data.message, { type: 'error' });
        }
        setLoading(false);
    }

    return (
        <div>
            <Header />
            <div className='mt-16 container mx-auto px-2 lg:px-[5%]'>
                {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                    <img alt="Please wait.." title="Please wait.." src={loader} />
                </div>}
                <div className='py-5 border-b-[1px] border-green-300]'>
                    <p className={styles.title2}>My Orders</p>
                    <div className='text-sm text-gray-700'><NavLink to={'/'}>Home</NavLink>{'/'}<span>My Orders</span></div>
                </div>
                <div className='my-16'>
                    <div className='flex gap-1 sm:gap-3 border-b-[1px] border-gray-300'>
                        <button
                            onClick={() => setCurrTab('pending')}
                            className={(currTab == 'pending' ? 'border-b-[1px] border-gray-700' : '') + ' p-2'}>
                            Pending Orders (0)
                        </button>
                        <button
                            onClick={() => setCurrTab('completed')}
                            className={(currTab == 'completed' ? 'border-b-[1px] border-gray-700' : '') + ' p-2'}>
                            Completed / Expired Orders ({myOrders[currTab]?.total})
                        </button>
                    </div>
                    <div className='mt-10'>
                        {myOrders[currTab]?.list.map((item, index) => {
                            return (
                                <div className='border-gray-700 rounded-lg border-[1px] mt-5'>
                                    <div className='p-4 border-b-[1px] border-dotted border-gray-600'>
                                        <p className={styles.title3}>Package you Purchased : <span className='text-orange-700'>{item.packYouPurchased}</span></p>
                                    </div>
                                    <div className='p-3 px-3 sm:px-6 grid grid-cols-2 gap-2 mt-2 ml-2'>
                                        <div className='grid grid-cols-2 gap-2 font-semibold'>
                                            <p className='text-gray-700'>Purchased on: <span className='text-black ml-2'>{item.purchasedOn}</span> </p>
                                        </div>
                                        <div className='grid grid-cols-2 gap-2 font-semibold'>
                                            <p className='text-gray-700'>Expired on: <span className='text-black ml-2'>{item.expiredOn}</span> </p>
                                        </div>
                                    </div>
                                    <div className='p-3 px-3 sm:px-6 grid grid-cols-2 gap-2 mt-1 mb-2 ml-2'>
                                        <div className='grid grid-cols-2 gap-2 font-semibold'>
                                            <p className='text-gray-700'>Payment Mode: <span className='text-black ml-2'>{item.paymentMode}</span> </p>
                                        </div>
                                        <div className='grid grid-cols-2 gap-2 font-semibold'>
                                            <p className='text-gray-700'>Amount Paid: <span className='text-black ml-2'>{`\u20B9`} {item.amountPaid}</span></p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {!loading && myOrders[currTab].list.length == 0 && <div className='bg-red-600 rounded text-white p-2 font-semibold mt-5'>No pending orders found</div>}
                    </div>
                </div>
            </div>
            <TopCItiesFilter />
            <Footer />
        </div>
    );
}

export default MyOrders;
