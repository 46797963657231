import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header/Header';
import TopSearchNavBar from '../components/TopSearchNavBar';
import { styles } from '../Styles/Styles';
import PropertyListCard from '../components/PropertyListCard';
import RecentViewCard from '../components/RecentViewCard';
import FAQs from '../components/FAQs';
import Footer from '../components/Footer';
import TopCItiesFilter from '../components/TopCItiesFilter';
import { NavLink, useLocation } from 'react-router-dom';
import PropertyForSlides from '../components/PropertyForSlides';
import PropertyCitiesListSlides from '../components/PropertyCitiesListSlides'
import Contact from '../components/Contact';
import { useDispatch, useSelector } from 'react-redux';
import useApi, { UseApi } from '../ApiConf';
import Pagenation from '../components/Pagenation';
import { setPageRefress, setPropertyListState, setlocation, gettingListPageUrlData, setGettingListPageUrlData } from '../Redux/reducer/User';
import loader from '../assets/Icons/loader.gif';
// import { Helmet } from 'react-helmet';
import MetaTags from '../components/MetaTags';
// import ScrollUp from '../components/ScrollUp';

const propTypes = [
    {
        "label": "apartment",
        "value": "1"
    },
    {
        "label": "independent-house-villa",
        "value": "2"
    },
    {
        "label": "residential-land",
        "value": "3"
    },
    {
        "label": "warehouse",
        "value": "5"
    },
    {
        "label": "builder-floor",
        "value": "6"
    },
    {
        "label": "office-space",
        "value": "7"
    },
    {
        "label": "shop-showroom",
        "value": "9"
    },
    {
        "label": "serviced-apartment",
        "value": "10"
    },
    {
        "label": "agricultural-farm-land",
        "value": "14"
    },
    {
        "label": "farm-house",
        "value": "15"
    },
    {
        "label": "commercial-land",
        "value": "16"
    }
]

const furnishTypes = [
    {
        "label": "furnished",
        "value": "Furnished"
    },
    {
        "label": "semi-furnished",
        "value": "Semi furnished"
    },
    {
        "label": "unfurnished",
        "value": "Unfurnished"
    }
]

const propertyTypes = ['Localities', 'Property Status', 'Budget', 'Builders'];

const PropertyList = () => {
    const [contactModalStatus, setcontactModalStatus] = useState({ show: false, data: {} });
    // const [propertyType, setPropertyType] = useState('Localities');
    const [showTabs, setShowTabs] = useState([true, true, true, true]);
    const { login_status, currLocation, propertyListState, pageRefresh, userData, filterMenus, gettingListPageUrlData } = useSelector(state => state.User);
    const [currPage, setCurrPage] = useState(1);
    const [propertyListData, setPropertyListData] = useState({ currPage: 1, totalProperty: null, lastPage: null, propertyList: [1, 2, 3, 4, 5, 6], faqs: [], meta: null });
    const [localities, setLocalities] = useState([]);
    const [propStatusTab, setPropStatusTab] = useState([]);
    const [budgetTab, setBudgetTab] = useState([]);
    const [builderTab, setBuilderTab] = useState([]);
    const [currLocalityTabInd, setCurrLocalityTabInd] = useState(1);
    const [rightListData, setRightListData] = useState({ recentView: [], newProject: [], loading: true });
    const [loadingList, setLoadingList] = useState(true);
    const { FetchData } = UseApi();
    const dispatch = useDispatch();
    const scrollUpTarget = useRef();
    const listPage = useRef();
    const routePath = useLocation();
    const [gettingUrldata, setGettingUrlData] = useState(true);
    const [highlightStatus, setHighlightStatus] = useState({ price: '', propertyTypes: '' });


    useEffect(() => {
        // if (pageRefresh) {
        // console.log('routePath.pathname...',routePath.pathname)
        // setGettingUrlData(true);
        dispatch(setGettingListPageUrlData(true));
        let arrPath = routePath.pathname.split('/');
        if (arrPath[1] == 'sale') {
            getPropTypeStatus(arrPath[2], { text: 'Buy', value: 'sale', for: 'Sale', index: 0 });
        } else if (arrPath[1] == 'rent') {
            getPropTypeStatus(arrPath[2], { text: 'Rent', value: 'rent', for: 'Rent', index: 1 });
        } else if (arrPath[1] == 'new-projects') {
            getPropTypeStatus(arrPath[2], { text: 'New Project', value: 'new project', for: 'Sale', index: 2 });
        }
        else if (arrPath[1].includes('new-projects-')) {
            getBuilderStatus(arrPath[1], { text: 'New Project', value: 'new project', for: 'Sale', index: 2 });
        }
        else if (arrPath[1].includes('new-project-') || (arrPath[2] && arrPath[2].includes('new-project-'))) {
            getPropTypeStatus(arrPath[1], { text: 'New Project', value: 'new project', for: 'Sale', index: 2 });
        }
        else if (arrPath[1].includes('-rent-') || (arrPath[2] && arrPath[2].includes('-rent-'))) {
            getPropTypeStatus(arrPath[1], { text: 'Rent', value: 'rent', for: 'Rent', index: 1 });
        }
        else if (arrPath[1].includes('-sale-') || (arrPath[2] && arrPath[2].includes('-sale-'))) {
            getPropTypeStatus(arrPath[1], { text: 'Buy', value: 'sale', for: 'Sale', index: 0 });
        }
        else {
            getPropTypeStatus(arrPath[2], { text: 'Buy', value: 'sale', for: 'Sale', index: 0 }, arrPath[1]);
        }
        //  let price = 12000;
        // console.log('price.toLoacalstirn.....',price.toLocaleString());

    }, [routePath.pathname]);

    const getBuilderStatus = async (endpoint, propStatus) => {
        let arr = endpoint.split('-in-');
        if (arr[0].includes('-by-')) {
            let arr2 = arr[0].split('-by-');
            let builderName = arr2[1].replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
            let cityname = arr[1].replace(/-/g, " ").replace(/\b\w/g, match => match.toUpperCase());
            let location = await getLocId(cityname, '', null, 'builder');
            console.log('cityId...', location.cityId, 'buildrname...', builderName);
            let data;
            try {
                data = await FetchData(`real-estate-builders?is_autocomplete=1&city=${location.cityId}&search=${builderName}`, 'GET');
            } catch (err) {
                console.log('err... data..', err);
            }
            console.log('builder data...', data);
            if (data?.length > 0) {
                console.log('searchdata...', data);

                let propstate = {
                    ...propertyListState,
                    propertyStatus: propStatus,
                    builder: data[0].value
                };
                dispatch(setPropertyListState(propstate));
                getPropertyList(1, propstate);
                // setGettingUrlData(false);
            }
        }
    }

    const getPropTypeStatus = (endpoint, propStatus, path1) => {
        let arr = endpoint.split('-for-');
        let constructionStatus = '';
        let furnishType = '';
        let propType = '';
        let bhk = '';
        let builderId = '';
        if (!path1) {
            propTypes.forEach((item, index) => {
                if (arr[0].includes(item.label)) {
                    propType = item.value;
                    let furnishingArr = arr[0].split(item.label);
                    if (furnishingArr[0].includes('bhk')) {
                        let bhkArr = furnishingArr[0].split('-');
                        bhk = bhkArr[0];
                    }
                    else if (furnishingArr[0]) {
                        furnishTypes.forEach((itm, ind) => {
                            if (furnishingArr[0].includes(itm.label)) {
                                furnishType = itm.value;
                            }
                        });
                    }
                }
            });
            if (arr[0].includes('-under-construction')) {
                constructionStatus = 'Under Construction';
            } else if (arr[0].includes('-ready-to-move')) {
                constructionStatus = 'Ready to Move';
            }
        } else if (path1 == 'property') {
            if (endpoint == 'commercial') {
                propType = '5-7-9-16';
            } else if (endpoint == 'plot') {
                propType = '3';
            } else if (endpoint == 'residential') {
                propType = '1-2-3-6-10';
            } else if (endpoint == 'commercial-land') {
                propType = '16';
            }
        }

        let array = endpoint.split('-in-');
        let cityname = '';
        let localityName = '';
        let priceRange = ['', ''];
        if (array[0]?.includes('below')) {
            let arr2 = array[0].split('-below-');
            let price = getPriceLimit(arr2[1]);
            priceRange = [0, price];
            cityname = array[1].replace(/-/g, " ").replace(/\b\w/g, match => match.toUpperCase());
            setHighlightStatus(pre => ({ ...pre, price: `Below ${convertPrice(price)}` }))
        } else if (array[0]?.includes('above')) {
            let arr2 = array[0].split('-above-');
            let price = getPriceLimit(arr2[1]);
            priceRange = [price, 1000000000];
            cityname = array[1].replace(/-/g, " ").replace(/\b\w/g, match => match.toUpperCase());
            setHighlightStatus(pre => ({ ...pre, price: `Above ${convertPrice(price)}` }));
        }
        else if (array[0]?.includes('from')) {
            let arr2 = array[0].split('-from-');
            let arr3 = array[0].split('-to-');
            let price1 = getPriceLimit(arr2[1]);
            let price2 = getPriceLimit(arr3[1]);
            priceRange = [price1, price2];
            cityname = array[1].replace(/-/g, " ").replace(/\b\w/g, match => match.toUpperCase());
            setHighlightStatus(pre => ({ ...pre, price: `From ${convertPrice(price1)} To ${convertPrice(price2)}` }))
        } else if (array[1]?.includes('below')) {
            let arr2 = array[1].split('-below-');
            let price = getPriceLimit(arr2[1]);
            priceRange = [0, price];
            cityname = arr2[0].replace(/-/g, " ").replace(/\b\w/g, match => match.toUpperCase());
            setHighlightStatus(pre => ({ ...pre, price: `Below ${convertPrice(price)}` }))
        } else if (array[1]?.includes('above')) {
            let arr2 = array[1].split('-above-');
            let price = getPriceLimit(arr2[1]);
            priceRange = [price, 1000000000];
            cityname = arr2[0].replace(/-/g, " ").replace(/\b\w/g, match => match.toUpperCase());
            setHighlightStatus(pre => ({ ...pre, price: `Above ${convertPrice(price)}` }));
        }
        else if (array[1]?.includes('from')) {
            let arr2 = array[1].split('-from-');
            let arr3 = array[1].split('-to-');
            let price1 = getPriceLimit(arr2[1]);
            let price2 = getPriceLimit(arr3[1]);
            priceRange = [price1, price2];
            cityname = arr2[0].replace(/-/g, " ").replace(/\b\w/g, match => match.toUpperCase());
            setHighlightStatus(pre => ({ ...pre, price: `From ${convertPrice(price1)} To ${convertPrice(price2)}` }));
        }
        else {
            if (array[1] == 'india') {
                let currloc = { ...currLocation, country: '90', city: 'India', code: '', location: '', locationName: null, project: '', projectName: null, area: 'City' };
                dispatch(setlocation(currloc));
            } else if (array[1]) {
                if (array[2]) {
                    localityName = array[1].replace(/-/g, " ").replace(/\b\w/g, match => match.toUpperCase());
                    cityname = array[2].replace(/-/g, " ").replace(/\b\w/g, match => match.toUpperCase());
                } else {
                    cityname = array[1].replace(/-/g, " ").replace(/\b\w/g, match => match.toUpperCase());
                }
            }
            setHighlightStatus(pre => ({ ...pre, price: '', propertyTypes: '' }));
        }

        let propstate = {
            ...propertyListState,
            BHKtype: bhk,
            propertyStatus: propStatus,
            propertyTypes: propType,
            moreStatus: { ...propertyListState.moreStatus, furnishingTypes: furnishType, constructionStatus: constructionStatus },
            builder: '',
            priceRange: priceRange
        };

        dispatch(setPropertyListState(propstate));

        if (array[1] && array[1] != 'india') {
            getLocId(cityname, localityName, propstate);
        } else {
            let currloc = { ...currLocation, country: '90', city: 'India', code: '', location: '', locationName: null, project: '', projectName: null, area: 'City' };
            dispatch(setlocation(currloc));
            getPropertyList(1, propstate, currloc);
            localStorage.setItem('location', JSON.stringify(currloc));
            // setGettingUrlData(false);
        }

    }



    const getPriceLimit = (str) => {
        let priceArr = str.split('-');
        let priceNumeric = parseFloat(priceArr[0].replace(/,/g, ''));
        if (priceArr[1] && priceArr[1] == 'lakhs') {
            priceNumeric = priceNumeric * 100000;
        } else if (priceArr[1] && priceArr[1] == 'crore') {
            priceNumeric = priceNumeric * 10000000;
        }
        return priceNumeric;
    }

    const convertPrice = (price) => {
        if (price >= 10000000) {
            return `${(price / 10000000).toFixed(price % 10000000 === 0 ? 0 : 2)} Cr`;
        } else if (price >= 100000) {
            return `${(price / 100000).toFixed(price % 100000 === 0 ? 0 : 2)} Lakhs`;
        } else if (price < 100000) {
            return price.toLocaleString();
        }
    }



    const getLocId = async (cityName, localityName, propstatus, type) => {
        let data;
        try {
            data = await FetchData(`header-menu?is_id_query=1&city=${cityName}&locality=${localityName}`, 'GET');
        } catch (err) {
            console.log(err);
        }
        if (data.success) {
            let location = { ...currLocation, country: '90', city: cityName, code: data.city, location: data.locality ? data.locality : '', locationName: localityName, project: '', projectName: null, area: cityName };
            dispatch(setlocation(location));
            if (type != 'builder') {
                getPropertyList(1, propstatus ? propstatus : null, location);
            }
            localStorage.setItem('location', JSON.stringify(location));
            // setGettingUrlData(false);
            return { cityId: data.city, locId: data.locality ? data.locality : '' }
        }
    }


    useEffect(() => {
        if (!pageRefresh) {
            getLocalities();
        }
        // getLocalities();
    }, [propertyListState.propertyStatus.index, currLocation.code]);


    useEffect(() => {
        // (!pageRefresh || currLocation.code != '') &&
        console.log('gettingListPageUrlData....', gettingListPageUrlData);
        if (!gettingListPageUrlData) {
            let location = localStorage.getItem('location');
            getPropertyList(1, null, JSON.parse(location));
        }
        // getPropertyList(1);
    }, [propertyListState, currLocation, login_status]);


    useEffect(() => {
        if (!pageRefresh && !gettingListPageUrlData) {
            let location = localStorage.getItem('location');
            getPropertyList(currPage, null, JSON.parse(location));
        }
        // else if(login_status){
        //     getPropertyList(currPage);
        // }
        // getPropertyList(currPage);
    }, [currPage, login_status]);

    // useEffect(() => {
    //     if (!pageRefresh) {
    //         // let location = localStorage.getItem('location');
    //         getPropertyList(currPage);
    //     }
    //     // getPropertyList(currPage);
    // }, [currLocation,propertyListState, login_status,currPage,currLocation.code]);



    useEffect(() => {
        getRightListData();
    }, [currLocation]);

    const getRightListData = async () => {
        let data;
        try {
            data = await FetchData(`listing-right-side-content?city=${currLocation.code}`, 'GET');
        } catch (err) {
            console.log(err);
        }
        console.log('right list data...', data);
        if (data) {
            setRightListData({ recentView: data.recentView, newProject: data.newProjcts, loading: false });
        }
    }
    const getLocalities = async (cityCode) => {
        let data;
        try {
            data = await FetchData(`city-stats?property_status=${propertyListState?.propertyStatus?.value}&city=${cityCode ? cityCode : currLocation.code}&limit=6`, 'GET');
        } catch (err) {
            console.log(err);
        }
        let arr = [true, true, true, true];
        if (data?.content?.localityStat?.length) {
            setLocalities(data?.content?.localityStat);
        } else {
            arr[0] = false;
        }
        if (data?.content?.propertyStat?.length) {
            setPropStatusTab(data?.content?.propertyStat);
        } else {
            arr[1] = false;
        }
        if (data?.content?.budgetCount?.length) {
            setBudgetTab(data?.content?.budgetCount);
        } else {
            arr[2] = false;
        }
        if (data?.content?.builderStat?.length) {
            setBuilderTab(data?.content?.builderStat);
        } else {
            arr[3] = false;
        }
        setShowTabs(arr);
        for (let i = 0; i < 4; i++) {
            if (arr[i]) {
                setCurrLocalityTabInd(i + 1);
                return;
            };
        }
    }

    const onClickContactBtn = (item) => {
        console.log('item...', item);
        setcontactModalStatus({
            show: true, data: {
                owner: item.userDetails?.name,
                type: item.userAs,
                icon: item.userDetails?.image,
                propId: item.id,
                city: item.cityName,
                isNewProject: item.details.includes('new-project') ? '1' : '0',
                sellerContact: item.contactNumber
            }
        });
    }
    const onCloseContact = () => {
        setcontactModalStatus({ show: false, data: null });
    }

    const getPropertyList = async (currpage, propListState, currloc) => {
        let userId = localStorage.getItem('userId');
        setLoadingList(true);
        let proState = propListState ? propListState : propertyListState;
        let currlocation = currloc ? currloc : currLocation;
        let data;
        let quary = `property_status=${proState?.propertyStatus?.value == 'new projects' ? 'new project' : proState?.propertyStatus?.value}` +
            `&country=${currlocation.country}&city=${currlocation.code}&locality=${currlocation.location}` +
            `&bedroom=${proState?.BHKtype}` +
            `&property_type=${proState.propertyTypes}` +
            `&min_price=${proState.priceRange[0]}&max_price=${proState.priceRange[1]}` +
            // `&min_price=&max_price=` +
            `&furnishing=${proState.moreStatus.furnishingTypes}` +
            `&bathroom=${proState.moreStatus.bathrooms}` +
            `&min_area=${proState.moreStatus.minArea}&max_area=${proState.moreStatus.maxArea}` +
            `&availableFor=${proState.moreStatus.newResale}` +
            `&availability=${proState.moreStatus.constructionStatus}` +
            `&facing=${proState.moreStatus.facing}` +
            `&floor=${proState.moreStatus.floor}` +
            `&amenities=${proState.moreStatus.amenities}` +
            `&listed_by=${proState.moreStatus.listedBy}` +
            `&verified=&page=${currpage}` +
            `&order_by=${proState.sortBy}` +
            // `&user_id=${userData?.ID?userData?.ID:''}`
            `&user_id=${userId ? userId : ''}` +
            `&builder=${proState.builder}`
        let endpoint = 'property-list?' + quary;

        try {
            data = await FetchData(endpoint, 'GET');
        } catch (err) {
            console.log('err fetching propertylist...', err);
        }
        if (data) {
            console.log('userData?.ID?userData?.ID....', userData?.ID ? userData?.ID : '');
            console.log('propertylist data...', data);
            console.log('propertyListState...', propertyListState);
            console.log('currLocation...', currLocation);
            let proptypeStr = '';
            let proptypeArr = proState.propertyTypes.split('-');
            filterMenus?.propertyType?.forEach((item, index) => {
                if (proptypeArr.includes(item.value)) {
                    proptypeStr = proptypeStr + (proptypeStr ? ', ' : '') + item.label;
                }
            });
            setHighlightStatus(pre => ({ ...pre, propertyTypes: proptypeStr }));
            setPropertyListData({ currPage: data.page, totalProperty: data.totalProperty, lastPage: data.totalPage, propertyList: data.property, faqs: data.listingFaq, meta: data?.meta });
        }
        setLoadingList(false);
    }

    return (
        <div ref={listPage} className='mx-auto' >
            <Header />
            <MetaTags metaData={propertyListData.meta} />
            <div className={'mt-[50px] ' + (loadingList && 'opacity-70')}>
                <TopSearchNavBar pageRef={listPage} />
                <div ref={scrollUpTarget} className='px-[2%] pt-[250px] sm:pt-[140px] lg:pt-28  container mx-auto py-5'>
                    <div className={styles.textMedium}>
                        <NavLink className={'hover:opacity-70'} to="/">Home</NavLink> {'> '}
                        {propertyListState.propertyStatus.value == 'new project' ? 'New Projects' : 'Property'} for {propertyListState.propertyStatus.for} in {currLocation.city}
                        {currLocation.locationName ? (`> ${propertyListState.propertyStatus.value == 'new project' ? 'New Projects' : 'Property'} for ${propertyListState.propertyStatus.for} in ${currLocation.locationName}`) : ''}
                    </div>
                    <div className='lg:flex gap-5'>

                        {<div className='mt-5 tracking-wide lg:w-[64%]'>
                            <div>
                                <p className={styles.textMedium}>Showing {(currPage - 1) * 25 + propertyListData?.propertyList?.length > 0 ? 1 : 0}-{(currPage - 1) * 25 + propertyListData?.propertyList?.length} of {propertyListData.totalProperty} property for {propertyListState?.propertyStatus?.for}</p>
                                <p className={styles.title3 + 'mt-1'}>{propertyListState.moreStatus.constructionStatus ? (propertyListState.moreStatus.constructionStatus + ' ') : ''}
                                    {highlightStatus.propertyTypes ? highlightStatus.propertyTypes : 'Property'} for {propertyListState.propertyStatus.for + ' '} {highlightStatus.price ? (highlightStatus.price + ' ') : ''}
                                    in {currLocation.locationName ? (currLocation.locationName + ', ') : ''} {currLocation.city}</p>
                                {currLocation.area != 'City' && <>
                                    <div className='flex gap-2 border-b-[1px] mt-2 border-b-gray-200'>
                                        {propertyTypes.map((item, index) => {
                                            return (
                                                <>
                                                    {(index < 3 || propertyListState?.propertyStatus?.value == 'new project') && showTabs[index] && <button key={index}
                                                        onClick={() => setCurrLocalityTabInd(index + 1)}
                                                        className={(currLocalityTabInd == index + 1 ? 'border-b-[1px]' : '') + ' hover:border-b-[1px] border-b-gray-700 pb-1 mr-3'}>
                                                        <p className={styles.textMedium + ''}>{item}</p>
                                                    </button>}
                                                </>
                                            )
                                        })}
                                    </div>

                                    {showTabs.toString() != [false, false, false, false].toString() && <div className='shadow-sm rounded flex flex-wrap max-h-[140px] min-h-[100px]  border-[1px] border-gray-200 mt-5 mx-2 overflow-y-auto p-2'>
                                        {currLocalityTabInd == 1 && showTabs[0] && localities.map((item, index) => {
                                            return (
                                                <div>
                                                    <NavLink
                                                        onClick={() => {
                                                            dispatch(setPropertyListState({
                                                                ...propertyListState,
                                                                BHKtype: '',
                                                                propertyTypes: '',
                                                                priceRange: ['', ''],
                                                                moreStatus: { furnishingTypes: '', bathrooms: '', minArea: '', maxArea: '', newResale: '', constructionStatus: '', facing: '', amenities: '', listedBy: '', floor: '' },
                                                                sortBy: 'featured',
                                                                builder: '',
                                                                clearAll: true
                                                            }));
                                                            // setHighlightStatus(pre=>({...pre,price:''}));
                                                            dispatch(setlocation({ ...currLocation, location: item.locality_id, locationName: item.localityName, project: '', projectName: null }));
                                                            localStorage.setItem('location', JSON.stringify({ ...currLocation, location: item.locality_id, locationName: item.localityName, project: '', projectName: null }));
                                                        }}
                                                        to={`/${item.link}`} key={index} className={styles.btn + 'h-7 m-1 hover:bg-orange-50 border-orange-500'}>
                                                        <p className='text-sm'>{`${item.localityName} (${item.count})`}</p>
                                                    </NavLink>
                                                </div>
                                            )
                                        })}
                                        {currLocalityTabInd == 2 && showTabs[1] && propStatusTab.map((item, index) => {
                                            return (
                                                <div>
                                                    <NavLink
                                                        onClick={() => {
                                                            dispatch(setlocation({ ...currLocation, location: '', locationName: null, project: '', projectName: null }));
                                                            dispatch(setPropertyListState({
                                                                ...propertyListState,
                                                                BHKtype: '',
                                                                propertyTypes: '',
                                                                priceRange: ['', ''],
                                                                moreStatus: { furnishingTypes: '', bathrooms: '', minArea: '', maxArea: '', newResale: '', constructionStatus: item.statName, facing: '', amenities: '', listedBy: '', floor: '' },
                                                                sortBy: 'featured',
                                                                builder: '',
                                                                clearAll: false
                                                            }));
                                                            // setHighlightStatus(pre=>({...pre,price:''}));
                                                        }}
                                                        to={`/${item.link}`} key={index} className={styles.btn + ' items-center justify-center m-1 hover:bg-orange-50 border-orange-500'}>
                                                        <p className='text-sm'>{`${item.statName} (${item.count})`}</p>
                                                    </NavLink>
                                                </div>
                                            )
                                        })}
                                        {currLocalityTabInd == 3 && showTabs[2] && budgetTab.map((item, index) => {
                                            return (
                                                <NavLink
                                                    onClick={() => {
                                                        dispatch(setlocation({ ...currLocation, location: '', locationName: null, project: '', projectName: null }));
                                                        // dispatch(setPropertyListState({
                                                        //     ...propertyListState,
                                                        //     BHKtype: '',
                                                        //     propertyTypes: '',
                                                        //     priceRange: [item.minPrice, item.maxPrice],
                                                        //     moreStatus: { furnishingTypes: '', bathrooms: '', minArea: '', maxArea: '', newResale: '', constructionStatus: '', facing: '', amenities: '', listedBy: '', floor: '' },
                                                        //     sortBy: 'featured',
                                                        //     builder: '',
                                                        //     clearAll: false
                                                        // }));
                                                        // setHighlightStatus(pre=>({...pre,price:''}));
                                                    }}
                                                    to={`/${item.link}`} key={index} className={styles.btn + 'h-7 m-1 hover:bg-orange-50 border-orange-500'}>
                                                    <p className='text-sm'>{`${item.budgetName} (${item.count})`}</p>
                                                </NavLink>
                                            )
                                        })}
                                        {currLocalityTabInd == 4 && showTabs[3] && builderTab.map((item, index) => {
                                            return (
                                                <NavLink
                                                    to={`/${item.link}`} key={index} className={styles.btn + 'h-7 m-1 hover:bg-orange-50 border-orange-500'}>
                                                    <p className='text-sm'>{`${item.name} (${item.count})`}</p>
                                                </NavLink>
                                            )
                                        })}
                                    </div>}
                                </>
                                }


                                {/* {loadingList && <div className="fixed top-[100px] right-1/2 flex justify-center items-center mt-16">
                                    <img alt="Please wait.." title="Please wait.." src={loader} />
                                </div>} */}
                                <div className='min-h-screen'>
                                    {propertyListData?.propertyList?.map((item, index) => {
                                        return (
                                            <PropertyListCard func={onClickContactBtn} Data={item} loadingList={loadingList} />
                                        )
                                    })}

                                    {(!loadingList && propertyListData?.propertyList?.length == 0) && <div className={styles.noDataFound}>
                                        No Data Available, Please try again.
                                    </div>}
                                </div>
                                {propertyListData?.propertyList?.length != 0 && <div>
                                    <Pagenation lastPage={propertyListData.lastPage} changeCurrPage={(pageNum) => setCurrPage(pageNum)} />
                                </div>}
                                <div className='mt-10'>
                                    {propertyListData?.faqs?.length > 0 && <FAQs Data={propertyListData.faqs} />}
                                </div>
                            </div>
                        </div>}

                        <div className={'w-full lg:w-[35%] xl:w-[32%] bg-slate-50 py-4 px-1 ' + (rightListData.loading && 'opacity-50 animate-pulse bg-white')}>
                            {rightListData?.recentView?.length > 0 && <div>
                                <RecentViewCard title={'Recent View Property'} Data={rightListData.recentView} />
                            </div>}
                            {rightListData?.newProject?.length > 0 && <div className='mt-10'>
                                <RecentViewCard title={'New Project Property'} Data={rightListData.newProject} />
                            </div>}
                        </div>
                    </div>
                </div>

                <div className='px-[2%] py-5'>
                    <PropertyCitiesListSlides />
                </div>
                <div className='px-[2%] py-5'>
                    {/* <p className={styles.title1 + 'mb-8 text-left'}>Property In {currLocation.city} For {propertyListState.propertyStatus.for}</p> */}
                    <PropertyForSlides />
                </div>
            </div>
            {/* <ScrollUp targetElement={scrollUpTarget} /> */}
            {contactModalStatus.show && <Contact Data={contactModalStatus.data} func={onCloseContact} />}
            <div>
                {!loadingList && <TopCItiesFilter />}
                <Footer />
            </div>

        </div>
    );
}

export default PropertyList;
