import React, { useState } from 'react';
import { setLoading, setPostPropertyFormData, setuser } from '../../Redux/reducer/User';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { styles } from '../../Styles/Styles';
import PhoneInput from 'react-phone-input-2';
import { ButtonList, CategoryTitle } from './PostPropertyComp';
import { UseApi } from '../../ApiConf';
import Auth from '../Auth';

const Iam = [
    { label: 'Individual/Owner', value: 'Individual' },
    { label: 'Builder/Developer', value: 'Builder' },
    { label: 'Agent/Dealer', value: 'Agent' },
]
const PersonalInfo = ({ setCurrCategory, category, title, showLoginPopup }) => {
    const [inputErrorStatus, setInputErrorStatus] = useState({ name: '', email: '', countryCode: '', mobileNum: '', Otp: '' });
    const { postPropertyFormData, loading } = useSelector(state => state.User);
    const dispatch = useDispatch();
    const { Request } = UseApi();
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');

    const onClickContitue = async () => {
        if (checkInputValidation()) return;
        dispatch(setLoading(true));
        if (postPropertyFormData.generalInfo.completed) {
            if (category) {
                setCurrCategory(category);
            }
            return;
        }
        if (postPropertyFormData.generalInfo.mobileVerification) {
            verifyMobile();
        } else {
            submitPersonalData();
        }
        dispatch(setLoading(false));
    }

    const submitPersonalData = async () => {
        dispatch(setLoading(true));
        let inputdata = {
            type: title == 'Property Personal :' ? '1' : '0',
            prop_username: postPropertyFormData.generalInfo.name,
            prop_email: postPropertyFormData.generalInfo.email,
            prop_country_code: postPropertyFormData.generalInfo.countryCode,
            prop_mobile: postPropertyFormData.generalInfo.mobileNum,
            prop_UserAs: postPropertyFormData.generalInfo.Iam,
            prop_user_password: password
        };
        let data;
        try {
            data = await Request('submit-personal', 'POST', inputdata);
        } catch (err) {
            console.log(err);
        }
        console.log('submit personal data....', data);
        if (data?.statusCode && data?.success) {
            setUserId(data.user_id);
            toast(data.message, { type: 'success' });
            if (data.token) {
                localStorage.setItem('token', data.token);
                if (data.data) {
                    dispatch(setuser(data.data));
                } else {
                    dispatch(setuser({}));
                }
            } else {
                dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, mobileVerification: true } }))
            }
        } else if (data?.registered) {
            if (data.verified) {
                toast(data?.message, { type: 'error' });
                showLoginPopup();
            } else {
                // toast('You have already registerd, please verify your mobile number.', { type: 'error' });
                resendOtp(data.user_id);
                setUserId(data.user_id);
                dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, mobileVerification: true } }))
            }
        }
        dispatch(setLoading(false));
    }

    const verifyMobile = async () => {
        dispatch(setLoading(true));
        let inputdata = {
            type: '2',
            otp: postPropertyFormData.generalInfo.Otp,
            mobile: postPropertyFormData.generalInfo.mobileNum,
            user_id: userId
        }
        console.log('inputdata verify mobile....', inputdata);
        let data;
        try {
            data = await Request('otp', 'POST', inputdata);
        } catch (err) {
            console.log(err);
            if (err?.message) {
                setInputErrorStatus(pre => ({ ...pre, Otp: err.message }));
            }
        }
        console.log('otp data...', data);
        if (data?.statusCode == 200) {
            if (data.success) {
                dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, completed: true, mobileVerification: false } }));
                if (data.data) {
                    dispatch(setuser(data.data));
                } else {
                    dispatch(setuser({}));
                }
                if (data.token) {
                    localStorage.setItem('token', data.token);
                    // localStorage.setItem('isLoggedIn', true);
                }
                toast(data.message, { type: 'success' });
            } else {
                toast(data.message, { type: 'error' });
            }
        } else {
            console.log('error,,', data);
            // setInputErrorStatus(pre => ({ ...pre, Otp: data.message }));
        }
        dispatch(setLoading(false));
    }

    const resendOtp = async (UserId) => {
        dispatch(setLoading(true));
        let inputdata = {
            type: '1',
            mobile: postPropertyFormData.generalInfo.mobileNum,
            user_id: UserId ? UserId : userId
        }
        let data;
        try {
            data = await Request('otp', 'POST', inputdata);
        } catch (err) {
            console.log(err);
            if (err?.message) {
                setInputErrorStatus(pre => ({ ...pre, Otp: err.message }));
            }
        }
        if (data?.statusCode == 200) {
            if (data.success) {
                toast(data.message, { type: 'success' });
            } else {
                toast(data.message, { type: 'error' });
            }
        }
        setInputErrorStatus(pre => ({ ...pre, Otp: '' }));
        dispatch(setLoading(false));
    }

    const checkInputValidation = () => {
        let error = false;
        if (!/^[a-zA-Z\s'-]+$/.test(postPropertyFormData.generalInfo.name)) {
            setInputErrorStatus(pre => ({ ...pre, name: 'Please write a valid name !' }));
            error = true;
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(postPropertyFormData.generalInfo.email)) {
            setInputErrorStatus(pre => ({ ...pre, email: 'Please write a valid email !' }));
            error = true;
        }

        if (!/^\d{7,15}$/.test(postPropertyFormData.generalInfo.mobileNum)) {
            setInputErrorStatus(pre => ({ ...pre, mobileNum: 'Please write a valid contact number!' }));
            error = true;
        }
        if (!password) {
            setInputErrorStatus(pre => ({ ...pre, password: 'Please write a password!' }));
            error = true;
        }
        return error;
    }
    return (
        <div>
            <CategoryTitle title={title} icon={'fa-regular fa-user mt-2'} />
            {!postPropertyFormData.generalInfo.mobileVerification ?
                <>
                    <div className='mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-8'>
                        <div>
                            <span className={styles.textMedium}>Name<span className='text-red-500'>*</span></span>
                            <input required placeholder='Enter Your Name' className={styles.input + 'mt-1'}
                                value={postPropertyFormData.generalInfo.name}
                                onChange={(e) => {
                                    // setGeneralInfoValus(pre => ({ ...pre, name: e.target.value }));
                                    dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, name: e.target.value } }))
                                    if (/^[a-zA-Z\s'-]+$/.test(e.target.value) && inputErrorStatus.name) {
                                        setInputErrorStatus(pre => ({ ...pre, name: '' }));
                                    }
                                }}
                            />
                            {inputErrorStatus.name && <p className='text-red-600 text-sm'>{inputErrorStatus.name}</p>}
                        </div>
                        <div>
                            <span className={styles.textMedium}>Email<span className='text-red-500'>*</span></span>
                            <input required placeholder='Enter Your Email' className={styles.input + 'mt-1'}
                                value={postPropertyFormData.generalInfo.email}
                                onChange={(e) => {
                                    // setGeneralInfoValus(pre => ({ ...pre, email: e.target.value }));
                                    dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, email: e.target.value } }))
                                    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value) && inputErrorStatus.email) {
                                        setInputErrorStatus(pre => ({ ...pre, email: '' }));
                                    }
                                }}
                            />
                            {inputErrorStatus.email && <p className='text-red-600 text-sm'>{inputErrorStatus.email}</p>}
                        </div>
                        <div>
                            <div className='flex gap-2'>
                                <div className='w-[30%]'>
                                    <span className={styles.textMedium}>Mobile<span className='text-red-500'>*</span></span>
                                    {/* <input required placeholder='+ 91 (India)' readOnly className={styles.input + 'mt-1'} /> */}
                                    <PhoneInput
                                        country={'in'}
                                        containerStyle={{ width: '100%', marginTop: '5px' }}
                                        inputStyle={{ width: '100%', height: '42px', borderRadius: '0px' }}
                                        // buttonStyle={{width:'50%'}}
                                        // containerClass='w-full'
                                        // inputClass='py-2'
                                        placeholder='Phone*'
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                            autoFocus: true,
                                            readOnly: true
                                        }}
                                        // buttonClass='w-[100px]'
                                        value={postPropertyFormData.generalInfo.countryCode}
                                        onClick={() => {
                                            if (postPropertyFormData.generalInfo.countryCode == '') {
                                                // setGeneralInfoValus(pre => ({ ...pre, countryCode: '+91' }));
                                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, countryCode: '+91' } }))
                                            }
                                        }}
                                        onChange={(val) => dispatch(
                                            setPostPropertyFormData({
                                                ...postPropertyFormData,
                                                generalInfo: { ...postPropertyFormData.generalInfo, countryCode: val, completed: false }
                                            }))}
                                    />
                                </div>
                                <div className='w-[70%] mt-[25px]'>
                                    {/* <span className={styles.textMedium}>Mobile<span className='text-red-500'>*</span></span> */}
                                    <input required placeholder='Enter Your Mobile No' className={styles.input + 'mt-1'}
                                        value={postPropertyFormData.generalInfo.mobileNum}
                                        onChange={(e) => {
                                            if (/^[0-9]*$/.test(e.target.value)) {
                                                // setGeneralInfoValus(pre => ({ ...pre, mobileNum: e.target.value }));
                                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, mobileNum: e.target.value, completed: false } }))
                                                if (/^\d{7,15}$/.test(e.target.value) && inputErrorStatus.mobileNum) {
                                                    setInputErrorStatus(pre => ({ ...pre, mobileNum: '' }));
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            {inputErrorStatus.mobileNum && <p className='text-red-600 text-sm'>{inputErrorStatus.mobileNum}</p>}
                        </div>
                    </div>
                    <div className='mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-8'>
                        <div>
                            <span className={styles.textMedium}>Password<span className='text-red-500'>*</span></span>
                            <input required placeholder='Enter password' className={styles.input + 'mt-1'}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    if (inputErrorStatus.password) {
                                        setInputErrorStatus(pre => ({ ...pre, password: '' }));
                                    }
                                }}
                            />
                            {inputErrorStatus.password && <p className='text-red-600 text-sm'>{inputErrorStatus.password}</p>}
                        </div>
                    </div>
                    <ButtonList title={'I Am'} btnNames={Iam} classname={'mt-8'} required={true}
                        value={postPropertyFormData.generalInfo.Iam}
                        onClick={(item) => dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, Iam: item } }))}
                    />
                </>
                :
                <div>
                    <div className='w-full max-w-[400px]'>
                        <span className={styles.textMedium}>Verify Your Mobile<span className='text-red-500'>*</span></span>
                        <input required placeholder='Enter OTP received on your mobile' className={styles.input + 'mt-1'}
                            value={postPropertyFormData.generalInfo.Otp}
                            onChange={(e) => {
                                // setGeneralInfoValus(pre => ({ ...pre, Otp: e.target.value }));
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, Otp: e.target.value } }))
                                if (inputErrorStatus.Otp) {
                                    setInputErrorStatus(pre => ({ ...pre, Otp: '' }));
                                }
                            }}
                        />
                        {inputErrorStatus.Otp && <p className='text-red-600 text-sm'>{inputErrorStatus.Otp}</p>}
                    </div>
                </div>
            }
            <div className='mt-8 flex justify-center gap-2'>
                {postPropertyFormData.generalInfo.mobileVerification && <button className={styles.formBtn + styles.btnBlackHover}
                    onClick={() => dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, mobileVerification: false } }))}>
                    Back</button>}
                <button className={styles.formBtn + styles.btnBlackHover} disabled={loading} onClick={onClickContitue}>Continue</button>
                {postPropertyFormData.generalInfo.mobileVerification && <button className={styles.formBtn + styles.btnBlackHover} disabled={loading} onClick={resendOtp}>Resend OTP</button>}
            </div>
        </div>
    );
}

export default PersonalInfo;
