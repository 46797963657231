// import Razorpay from 'razorpay';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseApi } from '../ApiConf';

const PaymentGateway = ({ closeGateway, paymentData, PropertyID, initId, selectedPack }) => {
    const navigate = useNavigate();
    const { FetchData, Request } = UseApi();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            const options = {
                // key: 'rzp_test_YoJ0a1xySRHBf7',
                key: 'rzp_live_Gq225hV7rKvySx',
                // key: paymentData.merchant_key,
                // key_secret: '9jRq5GsulKHvNOTgAT69T5AH',
                key_secret: 'faa87b9121f2449cb849f27e4d737f35',
                // key_secret: paymentData.merchant_salt,
                // amount: paymentData.pay_amount*100, // Amount in paise (e.g., 10000 paise = ₹100)
                amount: paymentData.pay_amount * 100, // Amount in paise (e.g., 10000 paise = ₹100)
                // amount: 100,
                currency: 'INR',
                name: 'Your Company Name',
                description: 'Test Transaction',
                // image: 'https://your-company-logo.png',
                handler: function (response) {
                    if (response.error) {
                        console.log('response.error---->', response.error);
                        alert('Payment failed: ' + response.error.description);
                        fetchPaymentRes(response)
                    } else {
                        // alert(`Payment successful. Payment ID: ${response.razorpay_payment_id}`);
                        fetchPaymentRes(response)
                    }
                },
                modal: {
                    ondismiss: function () {
                        // alert('Payment cancelled by user');
                        closeGateway();
                        // Close the payment gateway here if needed
                    }
                },
                prefill: {
                    name: paymentData.first_name,
                    email: paymentData.email,
                    contact: paymentData.mobile
                },
                notes: {
                    address: 'Razorpay Corporate Office'
                },
                theme: {
                    color: '#F37254'
                }
            };
            const rzp = new window.Razorpay(options);
            rzp.open();
        };

        return () => {
            document.body.removeChild(script);
        }
    }, []);


    const fetchPaymentRes = async (response) => {
        let token = localStorage.getItem('token');
        try {
            const payload = {
                "type": 2,
                "token": token,
                "razorpay_payment_id": response?.razorpay_payment_id,
                "payment_method": "RazorPay",
                "plan_id": paymentData?.plan_id,
                "selected_property": PropertyID,
                "validity_pack": selectedPack,
                "currency": "Rs.",
                "response_data": response,
                "payment_init_id": initId
            }
            const res = await FetchData('payment', 'POST', payload)
           
            if (res) {
                if (res?.success) {
                    navigate('/dashboard/my-property/active');
                }
            }
        } catch (error) {
            console.log('error------>', error);
        }
    }

    return (
        <div>

        </div>
    );
}

export default PaymentGateway;
