import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import TopCItiesFilter from '../../components/TopCItiesFilter';
import Footer from '../../components/Footer';
import { styles } from '../../Styles/Styles';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UseApi } from '../../ApiConf';
import loader from '../../assets/Icons/loader.gif';
import Pagenation from '../../components/Pagenation';

const ViewResponse = () => {
    const [showDetails, setShowDetails] = useState({ show: false, details: null });
    const { login_status, pageRefresh } = useSelector(state => state.User);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { Request } = UseApi();
    const [responses, setResponses] = useState({ responses: [], total: '' });
    const [currPage, setCurrPage] = useState(1);

    useEffect(() => {
        getResponses('1');
    }, [currPage]);

    const getResponses = async (type, id) => {
        let token = localStorage.getItem('token');
        setLoading(true);
        let inputdata = {
            token: token,
            page: currPage,
            limit: '10',
            type: type,
            id: id ? id : ''
        }
        let data;
        try {
            data = await Request('view-responses', 'POST', inputdata);
        } catch (err) {
            console.log('error...', err);
        }
        console.log(' data...', data);
        if (data?.success && data.data) {
            if (type == '1') {
                setResponses(data.data);
            } else if (type == '2') {
                // setShowDetails(pre => ({ show: true, details: data.data }));
            }
        } else {
            // toast(data.message, { type: 'error' });
        }
        setLoading(false);
    }
    

    return (
        <div className=''>
            <Header />
            <div className='mt-20 container mx-auto px-2'>
                {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                    <img alt="Please wait.." title="Please wait.." src={loader} />
                </div>}
                <div className='overflow-x-auto'>
                    <p className={styles.title2}>View Responses ({responses.total})</p>
                    {responses.total > 0 && <table className='table-auto min-w-full mt-8 text-center'>
                        <tr className='font-semibold'>
                            <td className="border px-4 py-2 w-[20%]"></td>
                            <td className="border px-4  py-2 w-[40%]">Property Detail</td>
                            <td className="border px-4 py-2 w-[20%]">Date/Time</td>
                            <td className="border px-4 py-2 w-[15%]">Action</td>
                        </tr>
                        {responses.responses.map((item, index) => {
                            return (
                                <tr>
                                    <td className="border px-4 py-4">{item.name}</td>
                                    <td className="border px-4 py-4">{item.propName}</td>
                                    <td className="border px-4 py-4">{item.date}</td>
                                    <td className="border px-4 py-4">
                                        <button
                                            onClick={() => {
                                                // getResponses('2', item.id);
                                                setShowDetails(pre => ({ show: true, details: item }));
                                            }}
                                            className={styles.btn + 'bg-black py-[6px] w-full text-white'}>View Detail</button>
                                    </td>
                                </tr>
                            )
                        })}
                    </table>}
                </div>

                {responses?.total / 10 > 1 && <div>
                    <Pagenation lastPage={Math.ceil(responses.total / 10)} changeCurrPage={(pageNum) => setCurrPage(pageNum)} />
                </div>}
                {responses.total == 0 && !loading && <div className='bg-red-600 rounded text-white p-2 font-semibold mt-5'>
                    No Data Found
                </div>}

                {showDetails.show && <div className='fixed top-0 z-[2000] flex left-0 w-screen h-screen items-center justify-center bg-black bg-opacity-60 '>
                    <div className='relative bg-gray-100 shadow-lg border-[1px] p-4 w-[90%] sm:w-[85%] max-w-[420px] '>
                        <div className='text-lg font-semibold'>Contact Details</div>
                        <button
                            onClick={() => setShowDetails(pre => ({ show: false, details: null }))}
                            className='absolute top-2 right-2'>
                            {/* <CrossIcon /> */}
                            <i class="fa-solid fa-xmark text-lg"></i>
                        </button>
                        <div className='border-[1px] mt-3 bg-white shadow rounded border-gray-400 p-2'>
                            <div><span className='font-semibold'>Username: </span><span> {showDetails.details?.name}</span></div>
                            <div><span className='font-semibold'>Email: </span><span>{showDetails.details?.email}</span>
                                {showDetails.details?.verified == 'Y' ? <span className='text-white bg-green-700 text-xs  px-2 py-[2px] ml-5'>VERIFIED</span>
                                    :
                                    <span className='text-white bg-red-500 text-xs  px-2 py-[2px] ml-5'>NOT VERIFIED</span>
                                }
                            </div>
                            <div><span className='font-semibold'>Contact No:: </span><span> {showDetails.details?.mobile}</span></div>
                            <div><span className='font-semibold'>Message: </span><span>{showDetails.details?.message}</span></div>
                        </div>
                        <div className='flex justify-center'>
                            <button onClick={() => setShowDetails(pre => ({ ...pre, show: false, details: null }))}
                                className='bg-red-600 px-2 py-1 text-white rounded mt-5'>Close</button>
                        </div>
                    </div>
                </div>}

            </div>
            <div className='mt-10'>
                <TopCItiesFilter />
                <Footer />
            </div>

        </div>
    );
}

export default ViewResponse;
