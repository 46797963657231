import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PersonalInfo from '../../components/PostProperty/PersonalInfo';
import ProjectInfo from '../../components/PostProperty/ProjectInfo';
import { setPostPropertyFormData } from '../../Redux/reducer/User';


const NewProjectInfo = ({ setCurrCategory }) => {
    const [animation, setAnimation] = useState(false);
    const { postPropertyFormData, login_status} = useSelector(state => state.User);
    const dispatch = useDispatch();

    useEffect(() => {
        setAnimation(true);
    }, [])

    // useEffect(() => {
    //     if (login_status) {
    //         dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { name: '', email: '', countryCode: '+91', mobileNum: '', Iam: 'Individual', Otp: '', mobileVerification: false, completed: true } }));
    //     } else {
    //         dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { name: '', email: '', countryCode: '+91', mobileNum: '', Iam: 'Individual', Otp: '', mobileVerification: false, completed: false } }));
    //     }
    // }, [login_status]);


    return (
        <div>
            <div className={''
                // + (animation ? 'transition-transform ease-in-out transform -translate-y-10 duration-1000' : '')
            }>
                {!postPropertyFormData.generalInfo.completed ?
                    <PersonalInfo setCurrCategory={setCurrCategory} title={'Property Personal :'} /> : <ProjectInfo setCurrCategory={setCurrCategory} category={'Gallery'} />
                }
            </div>
        </div>

    );
}

export default NewProjectInfo;
