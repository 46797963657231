import React, { useEffect, useState } from 'react';
import { Apartment, Bedroom, FavouriteIcon, LandArea, LocationIcon } from './svgIcons';
import { styles } from '../Styles/Styles';
// import possession2 from '../assets/Icons/possession2.png'
import userIcon from '../assets/images/user.svg'
import { NavLink, useLocation } from 'react-router-dom';
// import { useDispatch} from 'react-redux';
import loader from '../assets/Icons/loader.gif';
import { UseApi } from '../ApiConf';
import { toast } from 'react-toastify';
import Auth from './Auth';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
// import {noImage} from '../assets/images/no'
import noImage from '../assets/images/defaultImage.png'
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Download from "yet-another-react-lightbox/plugins/download";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";


const PropertyListCard = ({ Data, func, loadingList }) => {
    const [loading, setLoading] = useState(false);
    const { Request } = UseApi();
    const [isFavourite, setIsFavourite] = useState(Data?.isFavoriteProp);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const { login_status } = useSelector(state => state.User);
    const pathLocation = useLocation();
    const [openGallery, setOpenGallery] = useState(false);
    // const dispatch = useDispatch();

    // const getPath = () => {
    //     let arr = Data?.details?.split('/');
    //     if (!arr?.length) return;
    //     let lastIndex = arr.length - 1;
    //     return `/${arr[lastIndex - 2]}/${arr[lastIndex - 1]}/${arr[lastIndex]}`;
    // }

    // useEffect(() => {
    //     if (!loadingList) {
    //         setPropData(Data);
    //     }
    // }, [loadingList]);

    useEffect(() => {
        // console.log('Data?.isFavoriteProp...',Data?.isFavoriteProp)
        // console.log('isFabouriertae...',isFavourite)
        setIsFavourite(Data.isFavoriteProp);
    }, [Data]);
    const onCloseLoginPopup = () => {
        setShowLoginPopup(false);
    }

    const addToFavouriteList = async (propId) => {
        if (!login_status) {
            setShowLoginPopup(true);
            return;
        }
        let token = localStorage.getItem('token');
        setLoading(true);
        let inputdata = {
            token: token,
            property_id: propId
        }
        let data;
        console.log('inputdat...', inputdata);
        try {
            data = await Request('add-to-favourite', 'POST', inputdata);
        } catch (err) {
            console.log('error...', err);
        }
        console.log(' data...', data);
        if (data?.success) {
            toast(data.message, { type: 'success' });
            setIsFavourite(!isFavourite);
        } else {
            // toast(data.message, { type: 'error' });
        }
        setLoading(false);
    }

    return (
        <div className='rounded-xl flex flex-wrap md:flex-nowrap  lg:flex-wrap xl:flex-nowrap shadow-lg border-[1px] h-full mt-6 mx-2 group'>
            {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                <img alt="Please wait.." title="Please wait.." src={loader} />
            </div>}
            <div className='items-center relative border-gray-300 w-full  md:min-w-[33%]  h-full border-[1px] rounded-xl overflow-hidden '>
                {loadingList ? <Skeleton className='h-[240px] w-full' /> : <NavLink to={`/${Data.details}`}>
                    <img alt='' src={Data?.image ? Data.image : noImage}
                        onError={(e) => {
                            e.target.src = noImage;
                        }}
                        className='w-full h-[240px] transform transition-transform hover:scale-110 duration-1000 hover:cursor-pointer'
                    />
                </NavLink>}
                <div className='h-[70px] flex gap-1 justify-between items-center p-2 pl-1 bg-gradient-to-b from-gray-100 to-gray-400 
                  group-hover:bg-gradient-to-b group-hover:form-gray-300 group-hover:to-black'>
                    {loadingList ? <Skeleton className='' /> : <>
                        <NavLink to={`/${Data?.userDetails?.link}`}
                            onClick={() => window.scrollTo({ top: 0 })}
                            className='flex gap-1 z-50'>
                            <img alt='' src={Data.userDetails?.image ? Data.userDetails?.image : userIcon} className='h-10 w-10 hidden group-hover:block rounded-full hover:cursor-pointer' />
                            <p className='text-sm block sm:hidden group-hover:block text-white mt-2 hover:cursor-pointer '>{Data.userDetails?.name}</p>
                        </NavLink>
                        <div className='flex justify-between gap-1'>
                            {Data?.imageGallery && Data?.imageGallery?.length > 0 && <div onClick={() => setOpenGallery(true)} className=' flex sm:hidden group-hover:flex rounded-full items-center justify-center h-8 w-8 p-1 bg-black bg-opacity-60 hover:cursor-pointer'>
                                <i class="fa-solid fa-camera text-gray-300"></i>
                            </div>}
                            <div onClick={() => addToFavouriteList(Data?.id)} className=' block sm:hidden group-hover:block rounded-full p-1 bg-black bg-opacity-60 hover:cursor-pointer'>
                                <FavouriteIcon classname={isFavourite ? 'fill-white' : '' + ' text-white'} />
                            </div>
                        </div>
                    </>}
                    <Lightbox
                        open={openGallery}
                        plugins={[Thumbnails, Download, Fullscreen, Zoom]}
                        // thumbnails={Data.imageGallery.slice(0, 8)}
                        close={() => setOpenGallery(false)}
                        slides={Data?.imageGallery?.map((item, index) => {
                            return { src: item }
                        })}
                    />
                </div>
                {!loadingList && <div className='flex absolute gap-2 top-5 right-5'>
                    {(Data.top_selling_project_id || Data.featured != '') && <button className={styles.labelBtn + (Data.featured == '' ? ' bg-orange-600' : ' bg-green-600')}>
                        {Data.top_selling_project_id ? 'New Project' : Data.featured}
                    </button>}
                    <button className={styles.labelBtn + 'bg-orange-600'}>
                        {Data.listedFor}
                    </button>
                </div>}
            </div>

            <div className='p-3 pb-0 text-left h-full w-full md:min-w-[62%] ml-[2%]'>
                <div className='min-h-[205px]'>
                    {loadingList ? <Skeleton className='h-10' /> : <div className='flex justify-between'>
                        {/* <NavLink to={`/${Data.details}`}><p className={styles.title4 + 'cursor-pointer hover:text-gray-500'}>{Data.top_selling_project_id ? Data.top_selling_project_id : Data.title}</p></NavLink> */}
                        <NavLink to={`/${Data.details}`}><p className={styles.title4 + 'cursor-pointer hover:text-gray-500'}>{Data.title}</p></NavLink>
                        {Data.top_selling_project_id ? Data.price ? <p className={styles.title4}><i className={Data.currency + ' text-base'}></i> {Data.price}</p> : null : Data.isVerified == 'Y' &&
                            <img alt='' src='https://www.truehomes24.com/assets/front_end/images/property/checkmark.svg' className='h-8 w-8 ml-2' />}
                    </div>}
                    {loadingList ? <Skeleton className='h-10' /> : <div className='flex mt-3'>
                        <span className=''>
                            <LocationIcon classname={'h-5 w-4 mt-[0.5px]'} />
                        </span>
                        <h1 className='text-sm text-gray-700 ml-1'><p className={styles.textMedium + 'text-gray-500 font-medium inline-block'}>{Data.society ? 'Society:' : ''} </p>
                            {' '}{Data.localityName}, {Data.cityName}, {Data.stateName ? (Data.stateName + ', ') : ''}{Data.countryName}
                        </h1>
                    </div>}
                    {Data.top_selling_project_id && <div className='text-sm mt-1'>
                        {loadingList ? <Skeleton count={1} /> : <>
                            <span className='opacity-80'>By</span> <span>{Data.userDetails?.name}</span>
                            {Data.possession && Data.possession != '' && <div className='font-semibold text-base tracking-wider mt-1'>
                                {/* <span className='text-gray-600'>Possession By</span> */}
                                <span className='text-gray-700'>{Data.possession}</span>
                            </div>}
                        </>}
                    </div>}

                    {Data.unitConfig ?
                        <div className='mt-5 font-medium text-sm overflow-y-auto max-h-40 sm:max-h-20 overflow-x-auto'>
                            {loadingList ? <Skeleton count={2} /> : <>
                                {Data.unitConfig?.map((item, index) => {
                                    return (
                                        <div className='grid grid-cols-2 sm:grid-cols-3 gap-1 mt-5 sm:mt-1 text-gray-500'>
                                            {item.showUnitField != 0 && <div>{item.unit}</div>}
                                            {item.showBuiltUpField && <div>{item.unitBuiltUpArea}</div>}
                                            {/* <div>{'\u20B9'} 2.5 Cr</div> */}
                                            {item.unitPrice && <div><i className={Data.currency + ' text-sm'}></i> {item.unitPrice}</div>}
                                        </div>
                                    )
                                })}
                            </>}
                        </div>
                       
                        :
                        <>
                            {loadingList ? <div className='mt-10'><Skeleton count={3} /></div> : <div className='flex flex-wrap mt-1'>
                                {/* <div className='flex w-1/2 mt-2'>
                                <span className=''>
                                    <Apartment classname={'h-5 w-4 mt-[1px] opacity-80'} />
                                </span>
                                <h1 className='text-sm ml-2'>{Data.propertyType}</h1>
                            </div> */}
                                {/* {(Data.builtUpArea && Data.builtUpArea != '') && <div className='flex w-1/2 mt-2'>
                                <span className=''>
                                    <LandArea classname={'h-5 w-4 mt-[1px]'} />
                                </span>
                                <h1 className='text-sm ml-2'>{Data.builtUpArea} {Data.buildUnit}</h1>
                            </div>} */}
                                {(Data.isLand == 'Yes' ? Data.totalArea : Data.builtUpArea) && <div className='flex w-1/2 mt-2'>
                                    <span className=''>
                                        <LandArea classname={'h-5 w-4 mt-[1px]'} />
                                    </span>
                                    {/* {console.log('Data.builtUpArea...')} */}
                                    <h1 className='text-sm ml-2'>{Data.isLand == 'Yes' ? Data.totalArea : ((Data.builtUpArea && Data.builtUpArea != '0') ? Data.builtUpArea :
                                        !pathLocation?.pathname?.includes('user') ? Data.totalArea : Data.builtUpArea)
                                    } {' '}
                                        {pathLocation?.pathname?.includes('user') ? Data.unitDisplay : Data.buildUnit ? Data.buildUnit : Data.measurement}</h1>
                                </div>}

                                {Data.possession && Data.possession != '0' && <div className='flex w-1/2 mt-2'>
                                    <span className=''>
                                        <i class="fa-solid fa-bars opacity-70 text-sm"></i>
                                    </span>
                                    <h1 className='text-sm ml-2'>Possession By {Data.possession}</h1>
                                </div>}
                                {Data.totalFloor && Data.totalFloor != '0' && <div className='flex w-1/2 mt-2'>
                                    <span className=''>
                                        <Apartment classname={'h-5 w-4 mt-[1px] opacity-80'} />
                                    </span>
                                    <h1 className='text-sm ml-2'>{Data.totalFloor} Floor</h1>
                                </div>}
                                {Data.bathrooms && Data.bathroom != '' && <div className='flex w-1/2 mt-2'>
                                    <span className=''>
                                        <i class="fa-solid fa-toilet text-sm opacity-70"></i>
                                    </span>
                                    <h1 className='text-sm ml-2 mt-[2px]'>{Data.bathrooms} {Data.bathrooms > 1 ? 'Bathrooms' : 'Bathroom'}</h1>
                                </div>}
                                {Data.bedroom && <div className='flex w-1/2 mt-2'>
                                    <span className=''>
                                        <Bedroom classname={'h-5 w-4 mt-[1px] opacity-80'} />
                                    </span>
                                    <h1 className='text-sm ml-2'>{Data.bedroom}</h1>
                                </div>}
                                {Data.reservedParking && Data.reservedParking != '' && <div className='flex w-1/2 mt-2'>
                                    <span className=''>
                                        <i class="fa-solid fa-square-parking opacity-70"></i>
                                    </span>
                                    <h1 className='text-sm ml-2'>{Data.reservedParking}</h1>
                                </div>}
                                {Data.availableFor && <div className='flex w-1/2 mt-2'>
                                    <span className=''>
                                        <i class="fa-solid fa-bars opacity-70 text-sm"></i>
                                    </span>
                                    <h1 className='text-sm ml-2 mt-[2px]'>{Data.availableFor}</h1>
                                </div>}
                                {Data.availability && Data.availability != '' && <div className='flex w-1/2 mt-2'>
                                    <span className=''>
                                        <i class="fa-solid fa-bars opacity-70"></i>
                                    </span>
                                    <h1 className='text-sm ml-2'>{Data.availability}</h1>
                                </div>}
                                {Data.totalFloor && Data.totalFloor != '0' && <div className='flex w-1/2 mt-2'>
                                    <span className=''>
                                        <i class="fa-solid fa-bars opacity-70"></i>
                                    </span>
                                    <h1 className='text-sm ml-2'>{Data.totalFloor} Floor</h1>
                                </div>}
                            </div>}
                        </>
                    }
                    {Data.furnishing && Data.furnishing != '' && <div className='mt-2'>
                        <span className='bg-gray-700 px-2 py-[2px] rounded text-white text-sm'>
                            {Data.furnishing}
                        </span>
                    </div>}
                </div>
                {/* + (Data.top_selling_project_id ? 'justify-end' : 'justify-between') */}
                <div className={'border-t-[1px] mt-2 flex py-1 justify-between '}>
                    {loadingList ? <button className='w-[100px]'><Skeleton className='h-8' /></button> : <div>
                        {Data.expectedPrice && Data.expectedPrice != '0' ? <p className={styles.title3}><i className={Data.currency + ' text-base text-gray-600'}></i> {Data.convertedExpectedPrice ? Data.convertedExpectedPrice : Data.expectedPriceWithUnit}
                            <span className='text-gray-500'>{Data.listedFor == 'Rent' ? ' /' : ''}</span>
                            <span className='text-gray-500 text-sm font-semibold ml-[2px]'>{Data.listedFor == 'Rent' ? 'Month' : ''}</span></p>
                            :
                            <>
                                {(!Data.price || !Data.unitConfig) && <button
                                    onClick={() => func(Data)}
                                    className={styles.btn + 'bg-green-600 px-4 my-2 text-white hover:bg-green-700'}>
                                    ASK FOR PRICE
                                </button>}
                            </>
                        }

                        {!Data.top_selling_project_id && <p className='text-sm text-gray-600'>{Data.userAs}: {Data.userDetails?.name}</p>}
                    </div>}
                    {/* !Data.top_selling_project_id && */}
                    {<div className='mt-2'>
                        {loadingList ? <button className='w-[100px]'><Skeleton className='h-8' /></button> : <button
                            onClick={() => func(Data)}
                            className={styles.btn + 'bg-green-600 hover:bg-green-700 px-6 py-[5px]'}>
                            <i class="fa-solid fa-phone text-gray-50 mt-1 mr-1 text-sm"></i>
                            <p className='text-white'>Contact</p>
                        </button>}
                    </div>}
                </div>

            </div>
            {showLoginPopup && <Auth onClose={onCloseLoginPopup} />}

        </div>
    );
}

export default PropertyListCard;
