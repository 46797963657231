import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { setLoading, setPostPropertyFormData } from '../../Redux/reducer/User';
import { toast } from 'react-toastify';
import { ButtonList, DropdownInput, JoditTextEditor } from './PostPropertyComp';
import { styles } from '../../Styles/Styles';
import { useNavigate } from 'react-router-dom';
import { UseApi } from '../../ApiConf';
// import loader from '../../assets/Icons/loader.gif';

const propTypesDropdownData = [
    { label: 'Apartment', value: '1' },
    { label: 'Independent House/Villa', value: '2' },
    { label: 'Shop/Showroom', value: '9' }
]
const projectStatus = ['Ongoing', 'Upcoming', 'Completed', 'Sold Out'];


const amenities = ['24by7 Water', 'CCTV Camera', 'Gated Society', 'Gym', 'Internet Connectivity',
    'Jogging Track', 'Kids Play Area', 'Kids Play Pool', 'Large Open Space', 'Laundry Services', 'Lift', 'Maintenance Staff',
    'Multipurpose Hall', 'Power Backup', 'RainWater Harvesting', 'Security', 'Swimming Pool', 'Visitor Parking', 'Waste Disposal'
]

const furnishingItem = [
    { value: 'Ari Conditioner', label: 'Ari Conditioner' },
    { value: 'Bed', label: 'Bed' },
    { value: 'Book Case', label: 'Book Case' },
    { value: 'Chairs', label: 'Chairs' },
    { value: 'Chimney', label: 'Chimney' },
    { value: 'Curtain', label: 'Curtain' },
    { value: 'Desk', label: 'Desk' },
    { value: 'Dining Table', label: 'Dining Table' },
    { value: 'Dinerware', label: 'Dinerware' },
    { value: 'Bath rugs', label: 'Bath rugs' },
    { value: 'DVD Player', label: 'DVD Player' },
    { value: 'Exhaust Fan', label: 'Exhaust Fan' },
    { value: 'Fan', label: 'Fan' },
    { value: 'Food storage containers', label: 'Food storage containers' },
    { value: 'Iron Board', label: 'Iron Board' },
    { value: 'Gas Stove', label: 'Gas Stove' },
    { value: 'Hair Dryer', label: 'Hair Dryer' },
    { value: 'Smart TV', label: 'Smart TV' },
    { value: 'Sofa', label: 'Sofa' },
    { value: 'Table Lamps', label: 'Table Lamps' },
    { value: 'Washing machine', label: 'Washing machine' }
];
const units = ['sq.ft.', 'sq.yards', 'sq.m.', 'atres', 'cents', 'grounds', 'guntha', 'bigha', 'kottah'];
const ProjectInfo = ({ setCurrCategory, category }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const { postPropertyFormData, userData, loading } = useSelector(state => state.User);
    const [inputErrStatus, setInputErrStatus] = useState({});
    const dispatch = useDispatch();
    // const [loading, setLoading] = useState(true);
    // const [inputErrorState, setInputErrState] = useState({ country: '', state: '' });
    const [inputFields, setInputFields] = useState({ countries: [], length: [], propertyType: [], status: [], unit: [], ageProperty: [] });
    const [addressData, setAddressData] = useState({ states: [], cities: [], localities: [], subLocalities: [], societies: [] });
    const navigate = useNavigate();
    const { Request } = UseApi();

    useEffect(() => {
        // setAnimation(true);
        getInputOptions('1');
    }, [])

    // useEffect(() => {
    //     getInputOptions('2', 'state_list', postPropertyFormData.newProjectInfo.country, 'oldLocation');
    // }, [postPropertyFormData.newProjectInfo.country]);

    // useEffect(() => {
    //     getInputOptions('2', 'city_list', postPropertyFormData.newProjectInfo.state, 'oldLocation');
    // }, [postPropertyFormData.newProjectInfo.state]);

    // useEffect(() => {
    //     getInputOptions('2', 'locality_list', postPropertyFormData.newProjectInfo.city, 'oldLocation');
    //     if (postPropertyFormData.newProjectInfo.city != 'Other') {
    //         getInputOptions('2', 'project_society', postPropertyFormData.newProjectInfo.city, 'oldLocation');
    //     }
    // }, [postPropertyFormData.newProjectInfo.city]);

    // useEffect(() => {
    //     getInputOptions('2', 'sublocality_list', postPropertyFormData.newProjectInfo.locality, 'oldLocation');
    // }, [postPropertyFormData.newProjectInfo.locality]);

    // const getInputOptions = async (type, get_list, currnet_val, locType) => {
    //     dispatch(setLoading(true));
    //     if (!currnet_val && type == '2') return;
    //     // if (checkInputValidation()) return;
    //     let params = {
    //         type: type,
    //         token: userData?.jwt_tocken,
    //         get_list: get_list ? get_list : '',
    //         currnet_val: (currnet_val || currnet_val != 'Other') ? currnet_val : ''
    //     }
    //     let data;
    //     try {
    //         data = await Request('form-related', 'POST', params);
    //     } catch (err) {
    //         console.log(err);
    //     }
    //     console.log('data....', data);
    //     if (data?.statusCode == 200) {
    //         if (data.success) {
    //             if (type == '1') {
    //                 setInputFields(pre => ({ ...pre, ...data.data }));
    //             }
    //             if (type == '2') {
    //                 if (get_list == 'state_list') {
    //                     // setAddressData(pre => ({ ...pre, states: data.data, cities: [], localities: [], subLocalities: [], societies: [] }));
    //                     setAddressData(pre => ({ ...pre, states: data.data }));
    //                     if (locType != 'oldLocation') {
    //                         dispatch(setPostPropertyFormData({
    //                             ...postPropertyFormData,
    //                             newProjectInfo: {
    //                                 ...postPropertyFormData.newProjectInfo,
    //                                 state: '', stateName: '', city: '', cityName: '', locality: '', localityName: '', otherLocality: '', subLocality: '', otherSubLocality: '', societyName: '', otherSociety: ''
    //                             }
    //                         }));
    //                     }
    //                 }
    //                 if (get_list == 'city_list') {
    //                     // setAddressData(pre => ({ ...pre, cities: data.data, localities: [], subLocalities: [], societies: [] }));
    //                     setAddressData(pre => ({ ...pre, cities: data.data }));
    //                     if (locType != 'oldLocation') {
    //                         dispatch(setPostPropertyFormData({
    //                             ...postPropertyFormData,
    //                             newProjectInfo: {
    //                                 ...postPropertyFormData.newProjectInfo,
    //                                 city: '', cityName: '', locality: '', localityName: '', otherLocality: '', subLocality: '', otherSubLocality: '', societyName: '', otherSociety: ''
    //                             }
    //                         }));
    //                     }

    //                 }
    //                 if (get_list == 'locality_list') {
    //                     console.log('locality data...', data.data);
    //                     setAddressData(pre => ({ ...pre, localities: data.data }));
    //                     if (locType != 'oldLocation') {
    //                         dispatch(setPostPropertyFormData({
    //                             ...postPropertyFormData,
    //                             newProjectInfo: {
    //                                 ...postPropertyFormData.newProjectInfo,
    //                                 locality: '', localityName: '', otherLocality: '', subLocality: '', otherSubLocality: '',
    //                             }
    //                         }));
    //                     }
    //                 }
    //                 if (get_list == 'sublocality_list') {
    //                     setAddressData(pre => ({ ...pre, subLocalities: data.data }));
    //                     if (locType != 'oldLocation') {
    //                         dispatch(setPostPropertyFormData({
    //                             ...postPropertyFormData,
    //                             newProjectInfo: {
    //                                 ...postPropertyFormData.newProjectInfo,
    //                                 subLocality: '', otherSubLocality: ''
    //                             }
    //                         }));
    //                     }

    //                 }
    //                 if (get_list == 'project_society') {
    //                     setAddressData(pre => ({ ...pre, societies: data.data }));
    //                     if (locType != 'oldLocation') {
    //                         dispatch(setPostPropertyFormData({
    //                             ...postPropertyFormData,
    //                             newProjectInfo: {
    //                                 ...postPropertyFormData.newProjectInfo,
    //                                 societyName: '', otherSociety: ''
    //                             }
    //                         }));
    //                     }

    //                 }
    //             }
    //             if (data.message) {
    //                 toast(data.message, { type: 'success' });
    //             }
    //             // setLoading(false);
    //         } else {
    //             // toast(data.message, { type: 'error' });
    //         }
    //     }
    //     dispatch(setLoading(false));
    // }

    const getInputOptions = async (type, get_list, currnet_val, locType) => {
        dispatch(setLoading(true));
        if (!currnet_val && type == '2') return;
        // if (checkInputValidation()) return;
        let params = {
            type: type,
            token: userData?.jwt_tocken,
            get_list: get_list ? get_list : '',
            currnet_val: (currnet_val || currnet_val != 'Other') ? currnet_val : ''
        }
        let data;
        try {
            data = await Request('form-related', 'POST', params);
        } catch (err) {
            console.log(err);
        }
        console.log('data....', data);
        if (data?.statusCode == 200) {
            if (data.success) {
                if (type == '1') {
                    setInputFields(pre => ({ ...pre, ...data.data }));
                    if(postPropertyFormData.newProjectInfo.country){
                        getInputOptions('2', 'state_list', postPropertyFormData.newProjectInfo.country, 'oldLocation');
                    }
                }
                if (type == '2') {
                    if (get_list == 'state_list') {
                        // setAddressData(pre => ({ ...pre, states: data.data, cities: [], localities: [], subLocalities: [], societies: [] }));
                        setAddressData(pre => ({ ...pre, states: data.data }));
                        if (locType == 'oldLocation') {
                            getInputOptions('2', 'city_list', postPropertyFormData.newProjectInfo.state, 'oldLocation');
                        }
                    }
                    if (get_list == 'city_list') {
                        // setAddressData(pre => ({ ...pre, cities: data.data, localities: [], subLocalities: [], societies: [] }));
                        setAddressData(pre => ({ ...pre, cities: data.data }));
                        if (locType == 'oldLocation') {
                            getInputOptions('2', 'locality_list', postPropertyFormData.newProjectInfo.city, 'oldLocation');
                            if (postPropertyFormData.newProjectInfo.city != 'Other') {
                                getInputOptions('2', 'project_society', postPropertyFormData.newProjectInfo.city, 'oldLocation');
                            }
                        }
                    }
                    if (get_list == 'locality_list') {
                        console.log('locality data...', data.data);
                        setAddressData(pre => ({ ...pre, localities: data.data }));
                        if (locType == 'oldLocation') {
                            getInputOptions('2', 'sublocality_list', postPropertyFormData.newProjectInfo.locality, 'oldLocation');
                        }
                    }
                    if (get_list == 'sublocality_list') {
                        setAddressData(pre => ({ ...pre, subLocalities: data.data }));
                    }
                    if (get_list == 'project_society') {
                        setAddressData(pre => ({ ...pre, societies: data.data }));
                    }
                    if (data.message) {
                        toast(data.message, { type: 'success' });
                    }
                    // setLoading(false);
                } else {
                    // toast(data.message, { type: 'error' });
                }
            }
            dispatch(setLoading(false));
        }
    }

    // const onClickContinue = () => {
    //     setCurrCategory(category);
    //     toast('New project has been creacted successfully !', { type: 'success' });
    //     dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, completed: true } }));
    //     window.scrollTo({ top: 0 });
    // }

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            let newFile = e.target.files[0];
            console.log('newfiles...', newFile);
            let reader = new FileReader();
            reader.onload = () => {
                dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, image: reader.result, imageFile: newFile } }));
                setInputErrStatus(pre => ({ ...pre, image: '' }));
            }
            reader.readAsDataURL(newFile);
        }
    }

    const convertAmenitiesToString = () => {
        let str = '';
        amenities.forEach((item, index) => {
            if (postPropertyFormData.newProjectInfo.amenities[item]) {
                str = item + (str ? ',' : '') + str;
            }
        });
        return str;
    }
    const furnishingItemToString = () => {
        let str = '';
        postPropertyFormData.newProjectInfo.furnishingItem.forEach((item, index) => {
            if (index != 0) str = str + ','
            str = str + item.value;
        });
        return str;
    }

    const onClickContinue = async () => {
        let amenityStr = convertAmenitiesToString();
        let furnishingItemsStr = furnishingItemToString();
        console.log('selectedOption...', selectedOption);
        console.log('furnishingItemsStr......', furnishingItemsStr);
        // return ;
        let inputdata = {
            type: '1',
            token: userData?.jwt_tocken,
            // property_id: postPropertyFormData.currPropId,
            country: postPropertyFormData.newProjectInfo.country,
            state: postPropertyFormData.newProjectInfo.state,
            city: postPropertyFormData.newProjectInfo.city,
            locality: postPropertyFormData.newProjectInfo.locality,
            landmark: postPropertyFormData.newProjectInfo.subLocality,
            society_name: postPropertyFormData.newProjectInfo.societyName,
            locality_manual: postPropertyFormData.newProjectInfo.otherLocality,
            sublocality_manual: postPropertyFormData.newProjectInfo.otherSubLocality,
            ProjectName_manual: postPropertyFormData.newProjectInfo.otherSociety,
            propertyType: postPropertyFormData.newProjectInfo.propertyType,
            bedrooms_from: postPropertyFormData.newProjectInfo.bedroomFrom,
            bedrooms_to: postPropertyFormData.newProjectInfo.bedRoomTo,
            bathrooms_from: postPropertyFormData.newProjectInfo.bathroomsFrom,
            bathrooms_to: postPropertyFormData.newProjectInfo.bathroomsTo,
            floors_from: postPropertyFormData.newProjectInfo.totalFloor,
            amenities: amenityStr,
            Furnishing: postPropertyFormData.newProjectInfo.furnishingType,
            FurnishingItem: postPropertyFormData.newProjectInfo.furnishingType != 'Unfurnished' ? furnishingItemsStr : '',     //makde dynamik
            area_from: postPropertyFormData.newProjectInfo.areaFrom,
            BuildUnitMeasurment: postPropertyFormData.newProjectInfo.areaUnit,
            area_to: postPropertyFormData.newProjectInfo.areaTo,
            price_from: postPropertyFormData.newProjectInfo.priceFrom,
            price_to: postPropertyFormData.newProjectInfo.priceTo,
            builder_name: postPropertyFormData.newProjectInfo.builderName,
            project_status: postPropertyFormData.newProjectInfo.projectStaus,
            date: postPropertyFormData.newProjectInfo.possession,
            description: postPropertyFormData.newProjectInfo.description,
            about_builder: postPropertyFormData.newProjectInfo.aboutBuilder,
            project_link: postPropertyFormData.newProjectInfo.projectLink,
            image: postPropertyFormData.newProjectInfo.imageFile,
            video: postPropertyFormData.newProjectInfo.video,
            hidden_id: '',
            // ID:postPropertyFormData.newProjectInfo.propertyID,
            ID: postPropertyFormData.newProjectInfo.projectId,
            temp_plan_id: '',
        }
        console.log('inputdata...', inputdata);
        if (checkInputValidation()) return;
        dispatch(setLoading(true));
        let data;
        try {
            data = await Request('submit-new-project-property', 'POST', inputdata);
        } catch (err) {
            console.log(err);
            // if (err?.message) {
            //     setInputErrorStatus(pre => ({ ...pre, Otp: err.message }));
            // }
        }
        if (data?.statusCode == 200) {
            if (data.success) {
                toast(data.message, { type: 'success' });
                // dispatch(setPostPropertyFormData({
                //     ...postPropertyFormData,
                //     currPropId: data.property_id,
                //     generalInfo: { ...postPropertyFormData.generalInfo, completed: true },
                //     propertyInfo: { ...postPropertyFormData.propertyInfo, completed: true }
                // }));
                navigate(`/post-property/new-projects/${data.project_id}/gallery`);
                setCurrCategory(category);
                // toast('New project has been creacted successfully !', { type: 'success' });
                dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, completed: true, projectId: data.project_id } }));
                window.scrollTo({ top: 0 });
            } else {
                // toast(data.message, { type: 'error' });
            }
            dispatch(setLoading(false));
        } else {
            dispatch(setLoading(false));
        }
    }

    const checkInputValidation = () => {
        let error = false;
        // && !postPropertyFormData.newProjectInfo.imageUrl
        if (!postPropertyFormData.newProjectInfo.imageFile && !postPropertyFormData.newProjectInfo.imageUrl) {
            setInputErrStatus(pre => ({ ...pre, image: 'Please select an image !' }));
            error = true;
        }
        if (!postPropertyFormData.newProjectInfo.country) {
            setInputErrStatus(pre => ({ ...pre, country: 'Please select a country!' }));
            error = true;
        }
        if (!postPropertyFormData.newProjectInfo.state) {
            setInputErrStatus(pre => ({ ...pre, state: 'Please select a state!' }));
            error = true;
        }
        if (!postPropertyFormData.newProjectInfo.city) {
            setInputErrStatus(pre => ({ ...pre, city: 'Please select a city!' }));
            error = true;
        }
        if (!postPropertyFormData.newProjectInfo.locality) {
            setInputErrStatus(pre => ({ ...pre, locality: 'Please select a locality!' }));
            error = true;
        }

        if (!postPropertyFormData.newProjectInfo.societyName) {
            setInputErrStatus(pre => ({ ...pre, societyName: 'Please select a society name!' }));
            error = true;
        }
        if (!postPropertyFormData.newProjectInfo.propertyType) {
            setInputErrStatus(pre => ({ ...pre, propertyType: 'Please select a property type!' }));
            error = true;
        }
        if (!postPropertyFormData.newProjectInfo.totalFloor) {
            setInputErrStatus(pre => ({ ...pre, totalFloor: 'Please write tatal floor!' }));
            error = true;
        }
        // if (!postPropertyFormData.newProjectInfo.amenities) {
        //     setInputErrStatus(pre => ({ ...pre, amenities: 'Please select amenities!' }));
        //     error = true;
        // }
        if (!postPropertyFormData.newProjectInfo.areaFrom) {
            setInputErrStatus(pre => ({ ...pre, areaFrom: 'Please select area from!' }));
            error = true;
        }
        if (!postPropertyFormData.newProjectInfo.areaUnit) {
            setInputErrStatus(pre => ({ ...pre, areaUnit: 'Please select an unit!' }));
            error = true;
        }
        if (!postPropertyFormData.newProjectInfo.areaTo) {
            setInputErrStatus(pre => ({ ...pre, areaTo: 'Please select area to!' }));
            error = true;
        }
        // if (!postPropertyFormData.newProjectInfo.areaToUnit) {
        //     setInputErrStatus(pre => ({ ...pre, areaToUnit: 'Please select an unit!' }));
        //     error = true;
        // }
        if (!postPropertyFormData.newProjectInfo.priceFrom) {
            setInputErrStatus(pre => ({ ...pre, priceFrom: 'Please write price from!' }));
            error = true;
        }
        if (!postPropertyFormData.newProjectInfo.priceTo) {
            setInputErrStatus(pre => ({ ...pre, priceTo: 'Please write price to!' }));
            error = true;
        }
        if (!postPropertyFormData.newProjectInfo.builderName) {
            setInputErrStatus(pre => ({ ...pre, builderName: 'Please write builder name!' }));
            error = true;
        }
        if (!postPropertyFormData.newProjectInfo.projectStaus) {
            setInputErrStatus(pre => ({ ...pre, projectStaus: 'Please write project status!' }));
            error = true;
        }
        if (!postPropertyFormData.newProjectInfo.possession && postPropertyFormData.newProjectInfo.projectStaus == 'Ongoing') {
            setInputErrStatus(pre => ({ ...pre, possession: 'Please select possession!' }));
            error = true;
        }
        if (postPropertyFormData.newProjectInfo.description?.replace(/<[^>]+>/g, '').length == 0) {
            setInputErrStatus(pre => ({ ...pre, description: 'Please write description!' }));
            error = true;
        } else {
            setInputErrStatus(pre => ({ ...pre, description: '' }));
        }
        if (postPropertyFormData.newProjectInfo.aboutBuilder?.replace(/<[^>]+>/g, '').length == 0) {
            setInputErrStatus(pre => ({ ...pre, aboutBuilder: 'Please write about builder!' }));
            error = true;
        } else {
            setInputErrStatus(pre => ({ ...pre, aboutBuilder: '' }));
        }
        if (!postPropertyFormData.newProjectInfo.projectLink) {
            setInputErrStatus(pre => ({ ...pre, projectLink: 'Please copy and past a project link!' }));
            error = true;
        }
        console.log('inputErrStatus...', inputErrStatus);
        return error;
    }

    return (
        <div>
            {/* {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                <img alt="Please wait.." title="Please wait.." src={loader} />
            </div>} */}
            <div className={'mt-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 lg:gap-5'}>
                <DropdownInput title={'Country'} options={inputFields.countries} placeholder={'Select Country'} required={true}
                    error={inputErrStatus.country}
                    value={postPropertyFormData.newProjectInfo.country}
                    onChange={(e) => {
                        getInputOptions('2', 'state_list', e.target.value);
                        dispatch(setPostPropertyFormData({
                            ...postPropertyFormData, newProjectInfo: {
                                ...postPropertyFormData.newProjectInfo, country: e.target.value,
                                state: '', stateName: '', city: '', cityName: '', locality: '', localityName: '', otherLocality: '', subLocality: '', otherSubLocality: '', societyName: '', otherSociety: ''
                            }
                        }));
                        if (inputErrStatus.country) { setInputErrStatus(pre => ({ ...pre, country: '' })) };
                    }}
                />
                <DropdownInput title={'State'} options={addressData.states} placeholder={'Select State'} required={true}
                    error={inputErrStatus.state}
                    value={postPropertyFormData.newProjectInfo.state}
                    onChange={(e) => {
                        getInputOptions('2', 'city_list', e.target.value);
                        dispatch(setPostPropertyFormData({
                            ...postPropertyFormData, newProjectInfo: {
                                ...postPropertyFormData.newProjectInfo, state: e.target.value,
                                city: '', cityName: '', locality: '', localityName: '', otherLocality: '', subLocality: '', otherSubLocality: '', societyName: '', otherSociety: ''
                            }
                        }));
                        if (inputErrStatus.state) { setInputErrStatus(pre => ({ ...pre, state: '' })) };
                    }}
                />
                <DropdownInput title={'City'} options={addressData.cities} placeholder={'Select City'} required={true}
                    error={inputErrStatus.city}
                    value={postPropertyFormData.newProjectInfo.city}
                    onChange={(e) => {
                        getInputOptions('2', 'locality_list', e.target.value);
                        if (e.target.value != 'Other') {
                            getInputOptions('2', 'project_society', e.target.value);
                        }
                        dispatch(setPostPropertyFormData({
                            ...postPropertyFormData, newProjectInfo: {
                                ...postPropertyFormData.newProjectInfo, city: e.target.value,
                                locality: '', localityName: '', otherLocality: '', subLocality: '', otherSubLocality: '', societyName: '', otherSociety: ''
                            }
                        }));
                        if (inputErrStatus.city) { setInputErrStatus(pre => ({ ...pre, city: '' })) };
                    }}
                />
                <DropdownInput title={'Locality'} placeholder={'Select Locality'} required={true} options={addressData.localities}
                    error={inputErrStatus.locality}
                    value={postPropertyFormData.newProjectInfo.locality}
                    onChange={(e) => {
                        getInputOptions('2', 'sublocality_list', e.target.value);
                        dispatch(setPostPropertyFormData({
                            ...postPropertyFormData, newProjectInfo: {
                                ...postPropertyFormData.newProjectInfo, locality: e.target.value,
                                subLocality: '', otherSubLocality: ''
                            }
                        }));
                        if (inputErrStatus.locality) { setInputErrStatus(pre => ({ ...pre, locality: '' })) };
                    }}
                />
                <DropdownInput title={'Sub Locality'} placeholder={'Select Sub Locality'} required={false} options={addressData.subLocalities}
                    value={postPropertyFormData.newProjectInfo.subLocality}
                    onChange={(e) => {
                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, subLocality: e.target.value } }));
                        if (inputErrStatus.subLocality) { setInputErrStatus(pre => ({ ...pre, subLocality: '' })) };
                    }}
                />
                <DropdownInput title={'Society Name'} placeholder={'Select Society Name'} required={true} options={addressData.societies}
                    error={inputErrStatus.societyName}
                    value={postPropertyFormData.newProjectInfo.societyName}
                    onChange={(e) => {
                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, societyName: e.target.value } }));
                        if (inputErrStatus.societyName) { setInputErrStatus(pre => ({ ...pre, societyName: '' })) };
                    }}
                />
                <DropdownInput title={'Property Type'} options={propTypesDropdownData} placeholder={'Select Property Type'} required={true}
                    error={inputErrStatus.propertyType}
                    value={postPropertyFormData.newProjectInfo.propertyType}
                    onChange={(e) => {
                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, propertyType: e.target.value } }));
                        if (inputErrStatus.propertyType) { setInputErrStatus(pre => ({ ...pre, propertyType: '' })) };
                    }}
                />
                <DropdownInput title={'Bedroom from (Ex. 1BHK)'} placeholder={'Enter Bedroom from'} required={false}
                    value={postPropertyFormData.newProjectInfo.bedroomFrom}
                    onChange={(e) => {
                        if (/^[0-9]*$/.test(e.target.value)) {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, bedroomFrom: e.target.value } }));
                            if (inputErrStatus.bedroomFrom) { setInputErrStatus(pre => ({ ...pre, bedroomFrom: '' })) };
                        }
                    }}
                />
                <DropdownInput title={'Bedroom to (Ex. 1BHK)'} placeholder={'Enter Bedroom to'} required={false}
                    value={postPropertyFormData.newProjectInfo.bedRoomTo}
                    onChange={(e) => {
                        if (/^[0-9]*$/.test(e.target.value)) {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, bedRoomTo: e.target.value } }));
                            if (inputErrStatus.bedRoomTo) { setInputErrStatus(pre => ({ ...pre, bedRoomTo: '' })) };
                        }
                    }}
                />
                <DropdownInput title={'No. of Bathrooms from'} placeholder={'Enter starting Bathroom number'} required={false}
                    value={postPropertyFormData.newProjectInfo.bathroomsFrom}
                    onChange={(e) => {
                        if (/^[0-9]*$/.test(e.target.value)) {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, bathroomsFrom: e.target.value } }));
                            if (inputErrStatus.bathroomsFrom) { setInputErrStatus(pre => ({ ...pre, bathroomsFrom: '' })) };
                        }
                    }}
                />
                <DropdownInput title={'No. of Bathrooms to'} placeholder={'Enter highest Bathroom number'} required={false}
                    value={postPropertyFormData.newProjectInfo.bathroomsTo}
                    onChange={(e) => {
                        if (/^[0-9]*$/.test(e.target.value)) {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, bathroomsTo: e.target.value } }));
                            if (inputErrStatus.bathroomsTo) { setInputErrStatus(pre => ({ ...pre, bathroomsTo: '' })) };
                        }
                    }}
                />
                <DropdownInput title={'Total Floor'} placeholder={'Enter total floor'} required={true}
                    error={inputErrStatus.totalFloor}
                    value={postPropertyFormData.newProjectInfo.totalFloor}
                    onChange={(e) => {
                        if (/^[0-9]*$/.test(e.target.value)) {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, totalFloor: e.target.value } }));
                            if (inputErrStatus.totalFloor) { setInputErrStatus(pre => ({ ...pre, totalFloor: '' })) };
                        }
                    }}
                />
            </div>
            {/* <ButtonList title={'Amenities'} btnNames={amenities} classname={'mt-5'} required={true}
                value={postPropertyFormData.newProjectInfo.amenities}
                onClick={(item) => dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, amenities: item } }))}
            /> */}
            <div className='mt-5'>
                <span>Aminities</span>
                <div className='flex flex-wrap gap-2 mt-2 text-sm'>
                    {amenities.map((item, index) => {
                        return (
                            <button key={index}
                                onClick={() => {
                                    dispatch(setPostPropertyFormData({
                                        ...postPropertyFormData,
                                        newProjectInfo: {
                                            ...postPropertyFormData.newProjectInfo,
                                            amenities: { ...postPropertyFormData.newProjectInfo.amenities, [item]: !postPropertyFormData.newProjectInfo.amenities[item] }
                                        }
                                    }));
                                    if (!postPropertyFormData.newProjectInfo.amenities) {
                                        setInputErrStatus(pre => ({ ...pre, amenities: '' }));
                                    }
                                }}
                                className={(postPropertyFormData.newProjectInfo.amenities[item] ? 'border-orange-600 text-orange-600' : 'border-gray-400 text-gray-400') + ' hover:border-orange-600 hover:text-orange-600 border-[1px] px-3 py-1 rounded-xl '}>
                                {item}
                            </button>
                        )
                    })}
                </div>
                {inputErrStatus.amenities && <div className='text-red-600 text-sm py-1'>{inputErrStatus.amenities}</div>}
            </div>
            <ButtonList title={'Furnishing Type '} btnNames={['Furnished', 'Semi Furnished', 'Unfurnished']} initialName={''} classname={'mt-5'}
                value={postPropertyFormData.newProjectInfo.furnishingType}
                onClick={(item) => dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, furnishingType: item } }))}
            />
            {postPropertyFormData.newProjectInfo.furnishingType && postPropertyFormData.newProjectInfo.furnishingType != 'Unfurnished' && <div className='mt-5'>
                <span className=''>Furnishing Item </span>
                <Select
                    defaultValue={postPropertyFormData.newProjectInfo.furnishingItem}
                    onChange={(items) => {
                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, furnishingItem: items } }));
                    }}
                    // defaultValue={selectedOption}
                    // onChange={setSelectedOption}
                    options={furnishingItem}
                    isMulti
                    className='mt-2'
                />
            </div>}
            <div className='mt-5 grid grid-cols-1 sm:grid-cols-2 gap-[5%]'>
                <div className='flex justify-between mt-1'>
                    <DropdownInput title={'Area from'} placeholder={'Enter Area from'} inputClass={'w-[70%]'} required={true}
                        error={inputErrStatus.areaFrom}
                        value={postPropertyFormData.newProjectInfo.areaFrom}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, areaFrom: e.target.value } }));
                            if (inputErrStatus.areaFrom) { setInputErrStatus(pre => ({ ...pre, areaFrom: '' })) };
                        }}
                    />
                    <DropdownInput title={'Units'} placeholder={'Please Select'} options={inputFields.unit} inputClass={'w-[27%]'} required={true}
                        error={inputErrStatus.areaUnit}
                        value={postPropertyFormData.newProjectInfo.areaUnit}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, areaUnit: e.target.value } }));
                            if (inputErrStatus.areaUnit) { setInputErrStatus(pre => ({ ...pre, areaUnit: '' })) };
                        }}
                    />
                </div>
                <div className='flex justify-between mt-1'>
                    <DropdownInput title={'Area to'} placeholder={'Enter Area to'} inputClass={'w-[70%]'} required={true}
                        error={inputErrStatus.areaTo}
                        value={postPropertyFormData.newProjectInfo.areaTo}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, areaTo: e.target.value } }));
                            if (inputErrStatus.areaTo) { setInputErrStatus(pre => ({ ...pre, areaTo: '' })) };
                        }}
                    />
                    <DropdownInput title={'Units'} placeholder={'Please Select'} options={inputFields.unit} inputClass={'w-[27%]'} required={true}
                        error={inputErrStatus.areaUnit}
                        value={postPropertyFormData.newProjectInfo.areaUnit}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, areaUnit: e.target.value } }));
                            if (inputErrStatus.areaUnit) { setInputErrStatus(pre => ({ ...pre, areaUnit: '' })) };
                        }}
                    />
                </div>
            </div>

            <div className={'mt-5 grid grid-cols-1 sm:grid-cols-2 gap-5'}>
                <DropdownInput title={'Price from (in Rs)'} placeholder={'Enter your price from'} required={true}
                    error={inputErrStatus.priceFrom}
                    value={postPropertyFormData.newProjectInfo.priceFrom}
                    onChange={(e) => {
                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, priceFrom: e.target.value } }));
                        if (inputErrStatus.priceFrom) { setInputErrStatus(pre => ({ ...pre, priceFrom: '' })) };
                    }}
                />
                <DropdownInput title={'Price to (in Rs)'} placeholder={'Enter your price from'} required={true}
                    error={inputErrStatus.priceTo}
                    value={postPropertyFormData.newProjectInfo.priceTo}
                    onChange={(e) => {
                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, priceTo: e.target.value } }));
                        if (inputErrStatus.priceTo) { setInputErrStatus(pre => ({ ...pre, priceTo: '' })) };
                    }}
                />
                <DropdownInput title={'Builder name'} placeholder={'Enter ypur builder name'} required={true}
                    error={inputErrStatus.builderName}
                    value={postPropertyFormData.newProjectInfo.builderName}
                    onChange={(e) => {
                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, builderName: e.target.value } }));
                        if (inputErrStatus.builderName) { setInputErrStatus(pre => ({ ...pre, builderName: '' })) };
                    }}
                />
                <DropdownInput title={'Project status'} options={projectStatus} placeholder={'Select One'} required={true}
                    error={inputErrStatus.projectStaus}
                    value={postPropertyFormData.newProjectInfo.projectStaus}
                    onChange={(e) => {
                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, projectStaus: e.target.value } }));
                        if (inputErrStatus.projectStaus) { setInputErrStatus(pre => ({ ...pre, projectStaus: '' })) };
                    }}
                />
                {postPropertyFormData.newProjectInfo.projectStaus == 'Ongoing' && <DropdownInput title={'Possession '} placeholder={'dd-mm-yyyy'} required={true}
                    error={inputErrStatus.possession}
                    type={'date'}
                    value={postPropertyFormData.newProjectInfo.possession}
                    onChange={(e) => {
                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, possession: e.target.value } }));
                        if (inputErrStatus.possession) { setInputErrStatus(pre => ({ ...pre, possession: '' })) };
                    }}
                />}
            </div>
            <div className=''>
                <JoditTextEditor title={'Description'} maxChar={2000}
                    error={inputErrStatus.description}
                    value={postPropertyFormData.newProjectInfo.description}
                    onBlur={(content) => {
                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, description: content } }))
                    }}
                />
                <JoditTextEditor title={'About Builder'} maxChar={2000}
                    error={inputErrStatus.aboutBuilder}
                    value={postPropertyFormData.newProjectInfo.aboutBuilder}
                    onBlur={(content) => {
                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, aboutBuilder: content } }))
                    }}
                />
            </div>

            <div className='mt-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3'>
                <DropdownInput title={'Project Link'} placeholder={'Example:https//www.truehomes24.com'} required={true}
                    error={inputErrStatus.projectLink}
                    value={postPropertyFormData.newProjectInfo.projectLink}
                    onChange={(e) => {
                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, projectLink: e.target.value } }));
                        if (inputErrStatus.projectLink) { setInputErrStatus(pre => ({ ...pre, projectLink: '' })) };
                    }}
                />
                <div className='flex gap-1'>
                    <DropdownInput title={'Image'} required={true} type={'file'}
                        // value={postPropertyFormData.newProjectInfo.image}
                        error={inputErrStatus.image}
                        onChange={handleFileChange}
                    />
                    {postPropertyFormData.newProjectInfo.imageUrl && <img src={postPropertyFormData.newProjectInfo.imageUrl} className='w-[30%]' />}
                </div>
                <DropdownInput title={'Video'} type={'file'} acceptType={'video/*'}
                    value={postPropertyFormData.newProjectInfo.video}
                    onChange={(e) => {
                        console.log('e.target.value...', e.target.value)
                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectInfo: { ...postPropertyFormData.newProjectInfo, video: e.target.value } }));
                        if (inputErrStatus.video) { setInputErrStatus(pre => ({ ...pre, video: '' })) };
                    }}
                />
            </div>
            <div className='mt-5'>
                <button type='submit' className={styles.formBtn} onClick={onClickContinue}>Continue</button>
            </div>
        </div>
    );
}

export default ProjectInfo;
