
// useApi.js
import { useState } from 'react';

const useApi = () => {
  // const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async (endpoint, method, data = null) => {
    // setLoading(true);
    setError(null);
    let url = 'https://www.truehomes24.com/api/' + endpoint;
    try {
      var myHeaders = new Headers();
      const response = await fetch(url, {
        method: method,
        // headers: {
        //     'Content-Type': 'application/json',
        //     // Add any additional headers if needed
        //   },
        headers: myHeaders,
        body: data ? JSON.stringify(data) : null,
        // body: data ? data : null,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      // setLoading(false);
      return responseData;
    } catch (error) {

      console.log('err apiconfig.....', error);
      setError(error.message);
      return error;
      // setLoading(false);
    }
  };

  return { fetchData, error };
};

export default useApi;

export const UseApi = () => {
  const BASE_URL = 'https://www.backoffice.truehomes24.com/api/';

  const FetchData = async (endpoint, method, data = null) => {
    let url = BASE_URL + endpoint;
    const formdata = new FormData();
    if (data && method == 'POST') {
      for (const name in data) {
        formdata.append(name, data[name]);
      }
    }

    try {
      var myHeaders = new Headers();
      const response = await fetch(url, {
        method: method,
        headers: myHeaders,
        body: method == 'POST' ? formdata : data ? JSON.stringify(data) : null,
      });

      if (!response.ok) {
        throw new Error('Network response was  ok');
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      return error;
    }
  };

  const Request = async (endpoint, method = 'GET', params = null) => {

    var xmlRequest = new XMLHttpRequest();
    let url = BASE_URL + endpoint;

    return new Promise((resolve, reject) => {
      xmlRequest.open(method, url, true);
      xmlRequest.setRequestHeader('Accept', '*/*');

      if (method == 'GET') {
        xmlRequest.send();
      } else {
        let formdata = new FormData();
        let data = params;
        for (const name in data) {
          formdata.append(name, data[name]);
        }
        xmlRequest.send(formdata);
      }

      xmlRequest.onreadystatechange = function () { // Call a function when the state changes.   
        if (xmlRequest.readyState === XMLHttpRequest.DONE) {
          let resultData = JSON.parse(xmlRequest.response);
          if (xmlRequest.status === 200) {
            resolve(resultData);
          } else {
            try {
              let result = JSON.parse(xmlRequest.response);
              reject(result);
            } catch (err) {
              reject({ error: 'Server Error Please try again later !!!', actError: err });
              console.log('err......', err);
            }
          }
        }
      }
    })
  }

  return { FetchData, Request };
};

