import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import { styles } from '../../Styles/Styles';
import TopCItiesFilter from '../../components/TopCItiesFilter';
import Footer from '../../components/Footer';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { UseApi } from '../../ApiConf';
import { useDispatch, useSelector } from 'react-redux';
import loader from '../../assets/Icons/loader.gif';
import { setPackageDetails } from '../../Redux/reducer/User';
import Dropdown from './Dropdown';

let days = [];


let selected = []

const AdPackages = () => {
    const { packageDetails } = useSelector(state => state.User);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { Request } = UseApi();
    const currPath = useLocation();
    const [packageList, setPackageList] = useState([]);
    // const [daysAdRequired, setDaysAdRequired] = useState('10');
    // const [packageId, setPackageId] = useState('6');
    const dispatch = useDispatch();
    const [selectedMonth, setSelectedMonth] = useState(selected)
    const { postPropertyFormData } = useSelector(state => state.User);

    useEffect(() => {
        let urlPath = currPath.pathname;
        if (urlPath?.includes('/membership/adpackage')) {
            let pathArr = urlPath.split('/');
            if (pathArr.length > 4) {
                // setPackageId(pathArr[4]);
                dispatch(setPackageDetails({ propId: pathArr[3], packageId: pathArr[4], selectedDays: '10', projectId: '' }));
            } else if (!packageDetails.propId) {
                navigate('/dashboard/my-property/active');
            }
        } else if (urlPath?.includes('/membership/adpackagee')) {
            let pathArr = urlPath.split('/');
            if (pathArr.length > 4) {
                // setPackageId(pathArr[5]);
                dispatch(setPackageDetails({ propId: '', packageId: pathArr[3], selectedDays: '10', projectId: pathArr[3] }));
            }
        }
        getPackageList();
    }, []);

    const getPackageList = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        let inputdata = {
            type: '1',
            token: token,
            // package_id:'',
            // selected_days:'',
            // property_id:''
        }
        let data;
        try {
            data = await Request('ad-package', 'POST', inputdata);
        } catch (err) {
            console.log(err);
        }

        console.log('getpackage data...', data)
        if (data?.statusCode == 200 && data.success) {
            setPackageList(data.data);
            data?.data.map((item, i) => {
                selected.push({ id: item.id, value: 10, selected: false })
                for (let i = item.min_day; i <= item.max_day; i++) {
                    days[i] = i;
                }
            })
            // setSelectedMonth(selected)
            dispatch(setPackageDetails({ ...packageDetails, selectedDays: [...selected] }))
        }
        setLoading(false);
        // setInputErrorStatus(pre => ({ ...pre, Otp: '' }));
    }

    const handleChange = (e, id, index) => {
        selected[index] = { id, value: Number(e.target.value), selected: true }
        setSelectedMonth([...selected])
        dispatch(setPackageDetails({ ...packageDetails, selectedDays: [...selected] }))
    }


    return (
        <div>
            <Header />
            <div className='mt-[80px] mx-[5%] xl:mx-[10%]'>
                {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                    <img alt="Please wait.." title="Please wait.." src={loader} />
                </div>}
                <div className='p-1 border-b-[1px] border-b-gray-200'>
                    <p className={styles.title2}>Add Packages</p>
                    <span className='text-sm'><span>Home</span> / Ad Package</span>
                </div>
                <div className='mt-10 mb-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-6'>
                    {packageList?.map((item, index) => {
                        return (
                            <div key={index} className='mt-2 border-2 border-red-200 rounded py-2 text-base sm:text-lg'>
                                <div className='relative mt-2 bg-orange-500 text-white text-lg font-bold py-4 mx-2 flex items-center justify-center rounded'>
                                    {item.planName}
                                    {item.topSeller == 'Yes' && <span className='absolute right-0 top-1 bg-yellow-400 px-3 py-[2px] text-xs text-black font-semibold rounded-l-md'>TOP SELLER</span>}
                                </div>
                                <div className='mt-5 text-center'>Ad cost/day - <span className='font-bold'>
                                    {item.currency == 'fa-solid fa-indian-rupee-sign' ? 'Rs. ' : <i className={`${item.currency} text-base`}></i>}
                                    {item.costPerDay}</span></div>
                                <div className='mt-5 flex flex-wrap gap-2 p-2 px-4 justify-center'>
                                    <p>No. of Days Ad Required - </p>

                                    <select className=' border-[1px] outline-none border-gray-500 w-[70%]'
                                        value={selectedMonth.length > 0 && selectedMonth[index]?.id == item.id && selectedMonth[index]?.value}
                                        onChange={(e) => handleChange(e, item.id, index)}
                                    >
                                        {days.map((itm, index) => {
                                            return (
                                                <option key={index} value={itm} >{itm}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className='flex justify-center'>
                                    <button disabled={packageDetails.packageId && packageDetails.packageId != item.id} onClick={() => {
                                        dispatch(setPackageDetails({ ...packageDetails, packageId: item.id }));
                                        navigate('/membership/detail');
                                    }}
                                        className={((!packageDetails.packageId || packageDetails.packageId == item.id) ? 'bg-gray-700 ' : 'bg-gray-500 ') + 'mt-5 opacity-90 px-4 py-1 border-[1px] text-white rounded-md cursor-pointer hover:bg-white hover:text-black hover:border-[1px] hover:border-black duration-500'}>
                                        Pay {item.currency == 'fa-solid fa-indian-rupee-sign' ? 'Rs. ' : <i className={`${item.currency} text-base`}></i>}
                                        
                                        {parseFloat(packageDetails?.selectedDays[index]?.value) * parseFloat(item?.costPerDay)}
                                    </button>
                                </div>
                                <div className='p-4 mt-5 border-y-[1px] border-x-gray-300 flex items-center justify-center gap-2'>
                                    Visible to
                                    <span className='relative z-10 p-1 rounded-full border-2 border-green-500'>
                                        <span className='z-50 bg-green-200 rounded-full p-1 taext-lg font-bold h-10 w-10 flex justify-center items-center'>
                                            {item.visiblePercentage}%
                                        </span>
                                        <span className='absolute -z-10 top-0 bg-white h-4 w-4 rounded-full'></span>
                                    </span>
                                    buyer
                                </div>
                                <div className='px-2 pl-4 py-4'>
                                    <div className='flex mt-4'>
                                        <div>
                                            <span className='bg-gray-100 rounded-full p-1 flex justify-center items-center'>
                                                <i class="fa-solid fa-check opacity-70"></i>
                                            </span>
                                        </div>
                                        <span className='ml-5 tracking-wider'>No of Listing - <b>1</b></span>
                                    </div>
                                    <div className='flex mt-4'>
                                        <div>
                                            <span className='bg-gray-100 rounded-full p-1 flex justify-center items-center'>
                                                <i class="fa-solid fa-check opacity-70"></i>
                                            </span>
                                        </div>
                                        <span className='ml-5 tracking-wider'>Mobile number of all responser</span>
                                    </div>
                                    <div className='flex mt-4'>
                                        <div>
                                            <span className='bg-gray-100 rounded-full p-1 flex justify-center items-center'>
                                                <i class="fa-solid fa-check opacity-70"></i>
                                            </span>
                                        </div>
                                        <span className='ml-5 tracking-wider'><b>{item.photosOfProperty}</b> Photos of Property</span>
                                    </div>
                                    <div className='flex mt-4'>
                                        <div>
                                            <span className='bg-gray-100 rounded-full p-1 flex justify-center items-center'>
                                                <i class="fa-solid fa-check opacity-70"></i>
                                            </span>
                                        </div>
                                        <span className='ml-5 tracking-wider'><b>1</b> Video</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
            <TopCItiesFilter />
            <Footer />

        </div>
    );
}

export default AdPackages;
