import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/Header/Header';
import { styles } from '../../Styles/Styles';
import TopCItiesFilter from '../../components/TopCItiesFilter';
import Footer from '../../components/Footer';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UseApi } from '../../ApiConf';
import loader from '../../assets/Icons/loader.gif';
import Pagenation from '../../components/Pagenation';
import { toast } from 'react-toastify';
import { setPackageDetails } from '../../Redux/reducer/User';

const ManageProperty = () => {
    // const [showPopup, setShowPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState({ show: false, data: null });
    const [packageNum, setPackageNum] = useState('');
    const [packageError, setPackageError] = useState('');
    const popupContainer = useRef(null);
    const alertContainer = useRef(null);
    const [deactivateStatus, setDeactiveStatus] = useState({ show: false, id: '' });
    const { login_status, pageRefresh, userData } = useSelector(state => state.User);
    const [postPropertyList, setPostPropList] = useState({ totalProperty: '', totalPage: 1, properties: [], addPlan: [] });
    const [loading, setLoading] = useState(false);
    const [currPage, setCurrPage] = useState(1);
    const [buyerLookingInd, setBuyerLookingInd] = useState(0);
    const navigate = useNavigate();
    const { Request } = UseApi();
    const dispatch = useDispatch();

    useEffect(() => {
        // if (!login_status && !pageRefresh) {
        //     navigate('/');
        // }
        if (login_status) {
            // console.log('userData...', userData);
            getPostPropertyList();
        }
    }, [login_status, currPage]);

    // useEffect(() => {
    // }, []);



    const getPostPropertyList = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        let inputdata = {
            type: '1',
            token: token,
            page: currPage,
            limit: '10'
        }
        let data;
        try {
            data = await Request('property-dashboard', 'POST', inputdata);
        } catch (err) {
            console.log(err);
        }

        console.log('postpropertylist...', data)
        if (data?.statusCode == 200 && data?.success) {
            setPostPropList(data.data);
        }
        setLoading(false);
        // setInputErrorStatus(pre => ({ ...pre, Otp: '' }));
    }

    const closePopup = (event) => {
        if (event.target === popupContainer.current) {
            setPopupStatus(pre => ({ ...pre, show: false, data: null }));
        }
    }

    const deactivate = async (propId) => {
        setLoading(true);
        const token = localStorage.getItem('token');
        let inputdata = {
            type: '2',
            token: token,
            action: 'delete_property',
            property_id: propId
        }
        let data;
        try {
            data = await Request('property-dashboard', 'POST', inputdata);
        } catch (err) {
            console.log(err);
        }
        console.log('Manage pro---->', data);
        if (data?.statusCode == 200 && data.success) {
            toast(data.message, { type: 'success' });
            setDeactiveStatus({ show: false, id: '' });
            getPostPropertyList();
        }
        setLoading(false);
    }

    const onClickRenewListing = (item) => {
        console.log('item...', item);
        if (item.top_selling_project_id) {
            navigate(`/membership/adpackagee/${item.top_selling_project_id}/6`);
            // dispatch(setPackageDetails({propId:'',packageId:'6',selectedDays:'',projectId:item.top_selling_project_id}));
        } else {
            setPopupStatus(pre => ({ ...pre, show: true, data: item.renewModalData }));
            // dispatch(setPackageDetails({propId:item.renewModalData.id,packageId:'',selectedDays:''}));
        }
        // item.top_selling_project_id ? navigate(`/membership/adpackage/${item.top_selling_project_id}/6`)
        //     :
        //     setPopupStatus(pre => ({ ...pre, show: true, data: item.renewModalData }))
    }

    return (
        <div>
            <Header />
            <div className='mt-[80px] px-[10%] mb-10'>
                {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                    <img alt="Please wait.." title="Please wait.." src={loader} />
                </div>}
                <div className={styles.postpropTitle + 'text-start pl-5'}>Hey {userData?.Name}, Welcome to your dashboard</div>
                <p className={styles.title2 + ' mt-8'}>My Property Listings ({postPropertyList?.totalProperty ? postPropertyList?.totalProperty : '0'})</p>
                {postPropertyList.properties.map((item, index) => {
                    return (
                        <div className='mt-10 pb-5 border-b-4 border-gray-300'>
                            <div className='mt-8 flex flex-wrap gap-5'>
                                <div className='relative p-1 rounded border-[1px] border-gray-300'>
                                    <img
                                        alt=''
                                        src={item.image}
                                        className='h-[100px] lg:h-[150px] w-[200px]  lg:w-[120px]'
                                    />
                                    <span className='absolute top-1 right-1 px-1 text-sm text-white opacity-90 bg-orange-600'>For {item.listed_for}</span>
                                </div>
                                <div>
                                    <div className='flex flex-wrap gap-10'>
                                        <span className='sm:text-lg'>{item.propertyTitle}</span>
                                        <span className='flex flex-wrap gap-2'>
                                            <b className='text-gray-600'>{item.top_selling_project_id ? ('New Project Property ID: ' + item.top_selling_project_id) : ('Property ID: ' + item.id)}</b>
                                            <b>|</b>
                                            <b className='text-gray-600'>Post valid till: {item.postValidTill}</b>
                                        </span>

                                    </div>
                                    <div className='flex flex-wrap gap-5 text-gray-600'>
                                        <div className='flex gap-2 mt-2 w-[50%]'>
                                            <i class="fa-solid fa-location-dot mt-1 "></i>
                                            <p className='text-gray-600'> {item.project_society_name} , {item.CityName}, {item.StateName}, {item.CountryName}</p>
                                        </div>
                                        <div className='flex flex-wrap gap-5'>
                                            <b className='text-sm mt-2'>{item.planField}</b>
                                            <span className='mt-1'><b className={styles.textMedium}>{item.priceField}</b></span>
                                        </div>
                                    </div>
                                    <div className='mt-2 flex flex-wrap gap-2'>
                                        <span className='px-2 bg-sky-600 text-white rounded-md'>{item.PropertySubtypeName}</span>
                                        <span className='px-2 bg-orange-300 rounded'>
                                            <i class="fa-solid fa-calendar text-sm"></i>
                                            <span className=' ml-1'>{item.date}</span>
                                        </span>
                                    </div>
                                    <div className='mt-5 flex flex-wrap gap-5'>
                                        <div className='flex gap-2'>
                                            <NavLink to={`/${item.editListingUrl}`} className={styles.formBtn + styles.btnBlackHover}>Edit Listing</NavLink >
                                            <button className='border-black border-[1px] px-2 py-1 hover:text-white hover:bg-gray-600 duration-500 rounded'
                                                onClick={() => setDeactiveStatus(pre => ({ show: true, id: item.id }))}>Deactivate</button>
                                        </div>

                                        <div className='flex flex-wrap gap-5'>
                                            <span className='underline cursor-pointer'>
                                                <i class="fa-regular fa-eye mr-1"></i>
                                                Preview Listing
                                            </span>
                                            <span
                                                onClick={() => onClickRenewListing(item)}
                                                className='underline cursor-pointer'>
                                                <i class="fa-solid fa-rotate-right mr-1"></i>
                                                Renew Listing
                                            </span>
                                            <span className=''>
                                                Listing Status: {item.listingStatus}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )
                })}
                {postPropertyList.properties?.length > 0 && <Pagenation lastPage={postPropertyList.totalPage} changeCurrPage={(page) => setCurrPage(page)} />}
                {postPropertyList.properties?.length == 0 && !loading && <div className='bg-red-600 rounded text-white p-2 font-semibold mt-5'>
                    No Data Found
                </div>}
            </div>
            {deactivateStatus.show && <div ref={alertContainer}
                className='fixed top-0 z-[5000] flex justify-center items-center h-screen w-screen bg-opacity-50 bg-black'
                onClick={(event) => {
                    if (event.target === alertContainer.current) {
                        setDeactiveStatus({ show: false, id: '' });
                    }
                }}
            >
                <div className='bg-white rounded-md p-4 w-[90%] min-w-[320px] sm:w-[50%] lg:w-[40%]'>
                    <p className={styles.title4}>Are you sure you want to deactivate your listing?</p>
                    <p className='mt-2'>Note: You will lose all relevant data for this property. This action can not be undone.</p>
                    <div className='flex justify-center mt-5 gap-4'>
                        <button className='bg-gray-700 py-1 px-4 flex text-white rounded-md hover:opacity-80 text-sm' onClick={() => deactivate(deactivateStatus.id)}>Yes</button>
                        <button className='bg-gray-700 py-1 px-4 flex text-white rounded-md hover:opacity-80 text-sm' onClick={() => setDeactiveStatus({ show: false, id: '' })}>No</button>
                    </div>
                </div>
            </div>}

            {popupStatus.show && <div
                onClick={closePopup}
                ref={popupContainer}
                className='fixed top-0 z-[5000] flex justify-center items-center h-screen w-screen bg-opacity-40 bg-black'>
                <div className='bg-white rounded-md p-4 w-[90%] min-w-[320px] sm:w-[60%] lg:w-[40%]'>
                    <div className='flex justify-between border-b-[1px] border-gray-200 pb-2'>
                        <p className={styles.title4}>Refreshing Property For {popupStatus.data.listed_for}</p>
                        <button onClick={() => setPopupStatus(pre => ({ ...pre, show: false, data: null }))}>
                            <i class="fa-solid fa-xmark text-lg text-gray-700"></i>
                        </button>
                    </div>
                    <div className='mt-2 text-sm sm:text-base '>
                        <div className='flex gap-2 mt-1'><p className='opacity-90 font-semibold'>ID: </p><p>{popupStatus.data.id}</p></div>
                        <div className='flex gap-2 mt-2'><p className='opacity-90 font-semibold'>Posted: </p><p> {popupStatus.data.post_date}</p></div>
                        <div className='text-red-700 flex gap-2 mt-2'><p className='font-semibold'>Expiry:</p><p>{popupStatus.data.expiry}</p></div>
                        <p className='text-gray-500 mt-5'>{popupStatus.data.address}</p>
                        <div className='mt-5'>
                            <p className='text-sm font-semibold'>Select Listing Package</p>
                            <select className={styles.input + 'w-full mt-2'}
                                value={packageNum}
                                onChange={e => {
                                    setPackageNum(e.target.value);
                                    if (packageError) {
                                        setPackageError('');
                                    }
                                }}>
                                <option value={''}>Select</option>
                                {postPropertyList.addPlan.map((item, index) => {
                                    return (
                                        <>
                                            {index < 3 && <option value={item.ID}>{`${item.PlanName} - Rs.${item.PricePerDay}/Day (${item.NumberPhoto} Images)`}</option>}
                                        </>
                                    )
                                })}
                            </select>
                            {packageError && <p className='text-sm text-red-600'>{packageError}</p>}
                        </div>
                    </div>
                    <div className='flex items-center justify-center mt-5'>
                        <button className={styles.formBtn + styles.btnBlackHover}
                            onClick={() => {
                                if (packageNum) {
                                    // dispatch(setPackageDetails({propId:popupStatus.data.id,packageId:packageNum,selectedDays:'',projectId:''}));
                                    navigate(`/membership/adpackage/${popupStatus.data.id}/${packageNum}`);
                                } else {
                                    setPackageError('Please select a package !');
                                }
                            }}
                        >Continue to Refresh</button>
                    </div>
                </div>
            </div>}
            <TopCItiesFilter />
            <Footer />
        </div>
    );
}

export default ManageProperty;
