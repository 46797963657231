import React, { useEffect, useState } from 'react';
import PersonalInfo from './PersonalInfo';
import Auth from '../Auth';
import { ToastContainer } from 'react-toastify';


const GeneralInfo = ({ setCurrCategory }) => {
    const [animation, setAnimation] = useState(false);
    const [openLoginPopup, setOpenLoginPopup] = useState(false);
    // const [isOtpVerification,setIsOtpVerification] = useState(true);
    useEffect(() => {
        setAnimation(true);
    }, [])

    const showLoginPopup = () => {
        setOpenLoginPopup(true);
        // if(str == 'otpVerification'){
        //     setIsOtpVerification(true);
        // }
    }

    const onCloseLoginPopup = () => {
        setOpenLoginPopup(false);
    }


    return (
        <>
            <div className={'mt-16 ' + (animation ? 'transition-transform ease-in-out transform -translate-y-10 duration-1000' : '')}>
                <PersonalInfo setCurrCategory={setCurrCategory} category={'Property Info'} title={'General Information :'} showLoginPopup={showLoginPopup} />
            </div>
            {openLoginPopup && <Auth onClose={onCloseLoginPopup} />}
        </>
    );
}

export default GeneralInfo;
